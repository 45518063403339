export default {
  // Adult Social Care
  1: {
    text: "Partially mitigates against increase in demand. Services to those in need such as residential, home care, learning disability & mental health & transition support can continue with provision for investment into community and prevention services.",
    id: 1,
  },
  2: {
    text: "Negative impact on services including increased waiting times for assessments and care solutions,  people delayed in hospital and a lack of investment in community and prevention services.",
    id: 2,
  },
  3: {
    text: "Significant cuts in adult social care services with the possibility of some services being cut altogether.  Significant deterioration in our care provision to older people and vulnerable adults with a likely negative impact on their quality of life.",
    id: 3,
  },
  4: {
    text: "More significant cuts in Adult social care services with some services cut altogether. Unable to meet minimum levels of service provision required by law. Significant risk of harm to older people and vulnerable adults.",
    id: 4,
  },

  // Children & Young People
  5: {
    text: 'An increase in budget of £5m will allow for increased support to vulnerable children and young people and enhanced provision to those with more complex needs.',
    id: 5,
  },
  6: {
    text: 'Current levels of service could not be maintained. This could lead to a reduction in care provision for vulnerable children, with a risk of care home closures and a reduction in respite care provision.',
    id: 6,
  },
  7: {
    text: 'Further reduction in service provision with no funding available to support young people who have complex needs, leading to complications for them in later life. Preventative action scaled down significantly or stopped. Some Children\'s Centres are closed.',
    id: 7,
  },
  8: {
    text: 'More substantial cuts in service provision with delivery of those services required by law at significant risk. Outcomes worsen for vulnerable children.',
    id: 8,
  },

  // Waste Collection & Cleaner Street
  9: {
    text: 'Increased investment partially mitigates against recent increased demand caused by growth in the population.  Ensures continued investment in the service and the provision of cleaner, greener streets and neighbourhoods.',
    id: 9,
  },
  10: {
    text: 'Negative impact on service delivery.  Likely to see delays in dealing with fly tipping, less street cleaning and graffiti removal resulting in streets and neighbourhoods becoming less clean.',
    id: 10,
  },
  11: {
    text: 'Substantial cuts in service delivery with focus on most critical services eg waste collection. A significant deterioration in streets and neighbourhoods with more littering, graffitti and fly tipping.',
    id: 11,
  },
  12: {
    text: 'Significant cuts in waste management necessitating reduced frequency of refuse collection. Increased levels of uncleanness of the environment',
    id: 12,
  },

  // Culture, Leisure, Parks & Libraries
  13: {
    text: 'An improvement in both outdoor and indoor facilities which will allow greater opportunities to promote health and wellbeing and build on the Commonwealth Games legacy.',
    id: 13,
  },
  14: {
    text: 'Negative impact on services with a number of facilities and projects having to be redesigned or reduced.',
    id: 14,
  },
  15: {
    text: 'Significant service cuts across all areas - culture, leisure, parks and libraries - with some facilities having to close. Starting to see a deterioration in our green spaces and a negative impact upon health and wellbeing of residents and on Commonwealth Games Legacy.',
    id: 15,
  },
  16: {
    text: 'More substantial service cuts across all areas with many facilities having to close. A significant deterioration in our green spaces and a devastating impact upon the health and wellbeing of residents and Commonwealth Games Legacy.',
    id: 16,
  },

  // Housing & Homelessness
  17: {
    text: 'Will be able to invest more in Council owned housing with speedier repairs and maintenance and deliver more support to residents to prevent risks of homelessness.',
    id: 17,
  },
  18: {
    text: 'Capacity to invest in Council owned housing reduced. Reduced homelessness prevention work and less support delivered to homeless households.',
    id: 18,
  },
  19: {
    text: 'Further reduction in delivery capacity in all areas, shift towards more critical services. Increased homelessness in the City and increased costs to relieve homelessness as a result of end of prevention work.',
    id: 19,
  },
  20: {
    text: 'Only critical services delivered with some legal requirements unable to be met.',
    id: 20,
  },

  // Transport, Highways & Parking
  21: {
    text: 'Greater investment in developing transport options and  maintaining our transport infrastructure and services.   Leading to improvements in our roads, public transport and active travel alternatives.',
    id: 21,
  },
  22: {
    text: 'Will have to scale down our ambitions resulting in less investment and a slower pace of development of more sustainable transport.',
    id: 22,
  },
  23: {
    text: 'Further reduction in transport investment with some development projects put on hold.  Fees and charges increased. Service delivery in areas like road safety reduced with potential adverse impact.',
    id: 23,
  },
  24: {
    text: 'Investment in all but most critical projects stopped.  Service delivery in areas like road safety and road maintenance significantly reduced.  Will see congestion and road conditions worsen.',
    id: 24,
  },

  // Planning & Development
  25: {
    text: 'Increase in the number of development projects across the city. More investment in public realm.  Enhanced service delivery including increase in speed of planning applications.',
    id: 25,
  },
  26: {
    text: 'Fewer development projects supported, impacting on economic growth across the city.  Service delivery in planning and other functions impacted.',
    id: 26,
  },
  27: {
    text: 'More significant reduction in development and regeneration across the City with some projects put on hold.  Less jobs and training opportunities created.  Deterioration in other services with a significant delay in processing of planning applications.',
    id: 27,
  },
  28: {
    text: 'Substantial reduction in development and regeneration with many projects put on hold.  Creation of jobs and training opportunities significantly impacted.  Further deterioration in other service delivery. Sale of some council properties needed.',
    id: 28,
  },

  // Maintaining Neighbourhoods
  29: {
    text: 'Increase support to local communities and community and voluntary organisations to deliver community based services eg welfare advice.',
    id: 29,
  },
  30: {
    text: 'Reduced support to local communities and a decrease in provision of welfare and benefit advice.',
    id: 30,
  },
  31: {
    text: 'Adverse impact on support to communities and councillors with less funding provided for advice and other support services.',
    id: 31,
  },
  32: {
    text: 'Substantial reductions in support to local communities and the provision of welfare and benefit advice. More community centres adversely impacted.',
    id: 32,
  },

  // Enforcement & Regulation
  33: {
    text: 'Expand services so that we can better meet our legal   responsibilities and deliver improvements in our service areas.',
    id: 33,
  },
  34: {
    text: 'Some deterioration in service delivery with delays and reductions experienced in some areas.  Negative impact on ability to meet minimum standards required by law.',
    id: 34,
  },
  35: {
    text: 'Further deterioration in services with potential intervention by Government to ensure minimum standards are maintained. Increase in fees and charges on ceremonies, cemetery and pest control.',
    id: 35,
  },
  36: {
    text: 'Significant cuts in services and possibly stoppage of services such as Pest Control and Markets.  More potential for public health issues.',
    id: 36,
  },

  // Community Safety & Resilience
  37: {
    text: 'This will enable some additional capacity in each of the functions and greater investment in preventative actions.',
    id: 37,
  },
  38: {
    text: 'This will mean some scaling back of service delivery with some issues moving down the list of priorities.',
    id: 38,
  },
  39: {
    text: 'More significant reduction in service provision, focus narrowing to more critical issues with likely impact on preventative action in the areas of community safety and extremism.',
    id: 39,
  },
  40: {
    text: 'This will result in the Council being unable to meet its legal responsibilities for reducing crime and improving community safety. Other service areas having to narrow service provision to the most critical issues.',
    id: 40,
  },

  // Core Business Function
  41: {
    text: 'Increased investment in innovation and technology to improve our ways of working and make it easier for residents  to contact and communicate with the Council..   Recruitment to new and vacant posts where needed.',
    id: 41,
  },
  42: {
    text: 'Vacant posts remain unfilled, investment in new technology and other equipment reduces with a negative impact on services and poorer contact and communication between the Council and citizens.',
    id: 42,
  },
  43: {
    text: 'Performance in some areas starts to deteriorate with significant cuts to some services resulting in job losses.  Poorer contact and communication between the Council and citizens.',
    id: 43,
  },
  44: {
    text: 'More significant cuts to services resulting in failure to meet statutory requirements.',
    id: 44,
  },

  // Education & Skills
  45: {
    text: 'There are particular challenges in the post-Covid period to providing support to young people to access education and secure employment.  Budget growth would allow the council to develop and improve its existing support.',
    id: 45,
  },
  46: {
    text: 'Reduced capacity and resources for service delivery to young people. Limited capacity and resource to attract and secure funding that supports education and employment.',
    id: 46,
  },
  47: {
    text: 'More significant reduction in service provision, further reduced capacity and resource to attract and secure funding that supports education and employment.',
    id: 47,
  },
  48: {
    text: 'Focus narrowing to the most vulnerable children and young people.  Starting to see a negative impact on school performance and other outcomes.',
    id: 48,
  },
}