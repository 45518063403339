import * as types from "./types";
import data from "../../data";
import {
  push
} from "connected-react-router";

import {
  showNewsAction,
  removeNewsAction
} from "../news/actions";
import {
  _GET_STATS_SUCCESS
} from "../stats/actions";

import config from "../../config";
import {
  calculateRisk
} from "../../helpers/risk";

const lsTest = () => {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};
window.data = data;

const loadInitialDataAction = () => {
  return function (dispatch, getState) {
    dispatch(_LOAD_INITIAL_DATA());
    let frData = JSON.parse(JSON.stringify(data));
    if (frData) {
      if (lsTest() === true) {
        let d = JSON.parse(localStorage.getItem("budget"));
        if (d !== undefined && d !== null) {
          if (typeof d.council === "object" && typeof d.council !== null) {
            // eslint-disable-line valid-typeof
            frData.council.value_tax = Number(d.council.tax);
            frData.council.value_total =
              Number(d.council.tax) +
              (d.council.council_2 ? frData.council.council_2_val : 0) +
              (d.council.council_4 ? frData.council.council_4_val : 0) +
              (d.council.council_1 ? frData.council.council_1_val : 0) +
              (d.council.council_3 ? frData.council.council_3_val : 0) +
              (d.council.council_5 ? frData.council.council_5_val : 0);
            frData.council.council_1 = d.council.council_1 || false;
            frData.council.council_2 = d.council.council_2 || false;
            frData.council.council_3 = d.council.council_3 || false;
            frData.council.council_4 = d.council.council_4 || false;
            frData.council.council_5 = d.council.council_5 || false;
          }
          if (typeof d.spending_areas === "object" && typeof d.spending_areas !== null) {
            // eslint-disable-line valid-typeof
            frData.spending_areas.map((item, key) => {
              // eslint-disable-line array-callback-return
              if (d.spending_areas[item.slug] !== undefined) {
                frData.spending_areas[key].value = Number(d.spending_areas[item.slug]);
              }
            });
          }
          frData.reason = d.reason;
        }

        dispatch(_LOAD_INITIAL_DATA_SUCCESS(frData));
      } else {
        dispatch(_LOAD_INITIAL_DATA_FAILURE("Data Not Found"));
      }
    }
  };
};

const changeSpendingAreaAction = (key, value, slug) => {

  return function (dispatch, getState) {
    if (lsTest() === true) {
      let d = JSON.parse(localStorage.getItem("budget")) || {};
      if (
        d !== undefined &&
        d !== null &&
        typeof d.spending_areas === "object" &&
        typeof d.spending_areas !== null
      ) {
        // eslint-disable-line valid-typeof
        d.spending_areas[slug] = Number(value);
        localStorage.setItem("budget", JSON.stringify(d));
      } else {
        d.spending_areas = {};
        d.spending_areas[slug] = Number(value);
        localStorage.setItem("budget", JSON.stringify(d));
      }
    }
    let d2 = JSON.parse(JSON.stringify(data));

    let newsCheck = d2.spending_areas[key].triggers.filter(item => {
      return item.to > item.from ?
        Number(value) <= item.to && Number(value) >= item.from :
        Number(value) >= item.to && Number(value) <= item.from;
    });

    let newsToRemove = d2.spending_areas[key].triggers.filter(item => {
      if (item.to > item.from) {
        return Number(value) < item.from;
      }

      return (
        // getState().budget.spending_areas[key].value <= data.spending_areas[key].max - item.from &&
        // getState().budget.spending_areas[key].value >= data.spending_areas[key].max - item.to
        getState().budget.spending_areas[key].value > item.from
      );
    });

    // if (Number(value) > getState().budget.spending_areas[key].value) {
    const item = newsToRemove;
    item.map((entry, i) => {
      dispatch(removeNewsAction(item[i].news.id));
    })
    // }

    if (newsCheck.length > 0) {
      dispatch(showNewsAction("Breaking News", new Date().toLocaleString(), newsCheck[0].news));
    }

    dispatch(_CHANGE_SPENDING_AREA_SUCCESS({
      key: key,
      value: value
    }));
  };
};

const changeCouncilCategoryAction = (category, value, deduction = false) => {
  return function (dispatch, getState) {
    if (lsTest() === true) {
      let d = JSON.parse(localStorage.getItem("budget"));

      if (d) {
        if (d.council) {
          d.council[category] = value;
        } else {
          d.council = {
            [category]: value
          };
        }
        localStorage.setItem("budget", JSON.stringify(d));
      }
    }

    dispatch(
      _CHANGE_COUNCIL_CATEGORY_SUCCESS({
        category: category,
        value: value,
        deduction
      })
    );
  };
};

const changeCouncilBudgetAction = amount => {
  return function (dispatch, getState) {
    if (lsTest() === true) {
      let d = JSON.parse(localStorage.getItem("budget"));
      if (
        d !== undefined &&
        d !== null &&
        typeof d.council === "object" &&
        typeof d.council !== null
      ) {
        // eslint-disable-line valid-typeof
        d["council"]["tax"] = amount;
      } else {
        d = {
          council: {
            tax: amount
          }
        };
      }
      localStorage.setItem("budget", JSON.stringify(d));
    }

    if (!getState().tooltipActive) {
      dispatch(toggleRangeTooltip());
    }

    dispatch(_CHANGE_COUNCIL_BUDGET_SUCCESS(amount));

    const percentage = (
      (getState().budget.council.value_tax / getState().budget.council.max_tax) *
      100
    ).toFixed(1);

    // Note change on 10.08.2018
    // This code used to reference a property in local storage to detect whether the modal
    // has already been presented to the user or not. There was a change request to remove this.
    if (parseInt(percentage, 10) > 78.86 && parseInt(percentage, 10) < 80.92) {
      // console.log("percentage=" + percentage);
      dispatch(toggleReferendumModal());
    }
  };
};

const startAgainAction = () => {
  return function (dispatch, getState) {
    if (lsTest() === true) {
      localStorage.removeItem("budget");
    }
    dispatch(_START_AGAIN());
    if (data) {
      dispatch(_LOAD_INITIAL_DATA_SUCCESS(data));
      dispatch(push("/spending-areas"));
    } else {
      dispatch(_START_AGAIN_FAILURE("Data Not Found"));
    }
  };
};

const triggerSubmitModal = () => {
  return function (dispatch, getState) {
    // We are checking that the budget has hit the limit.
    // If they have hit the limit then we are to show the congratulations and carry on
    // otherwise we need to create a new action to show the error modal.
    const target = 812000000;

    if (getState().budget.current_total === target) {
      dispatch(showBudgetSuccessModal());
    } else {
      dispatch(showBudgetFailureModal());
    }
  };
};

const toggleReferendumModal = () => {
  return function (dispatch, getState) {
    dispatch(_toggleReferendumModal());
  };
};

const setThankYouModal = (isVisible = true) => {
  return {
    type: types.SET_THANK_YOU_MODAL,
    shouldShowThankYouModal: isVisible
  };
};

const saveAction = payload => {
  return function (dispatch, getState) {
    const d = getState();
    const council = d.budget.council;
    const spending_areas = d.budget.spending_areas;
    const spending_data = {};

    spending_areas.map(item => {
      return (spending_data[item.slug] = item.value);
    });

    let risk = 0;

    data.spending_areas.map((item, k) => {
      if (item.value !== spending_areas[k].value) {
        risk = risk + 1;
      }
      return true;
    });

    /**
     * Loop through stored news alerts and return a new array
     * that contains news alert id's.
     */
    const news_alerts = getState().news.news_alerts.map(alert => alert.id);

    dispatch(_SAVE());
    dispatch(hideBudgetSuccessModal());
    dispatch(setThankYouModal());

    /**
     * @NOTE
     * Spending Areas has hardcoded data to account for test data
     * against the different endpoint. When the endpoint has been updated this
     * static data can be removed.
     */
    fetch(`${config.api.prefix}api/save.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify({
          first_name: payload.name,
          sexual_orientation: payload.sexual_orientation,
          disability: payload.disability,
          risk,
          age: payload.ageRange,
          ethnicity: payload.ethnicity,
          customethnicity: payload.customethnicity,
          religion: payload.religion,
          gender: payload.gender,
          postcode: payload.postcode,
          reason: payload.reason,
          started_at: payload.started_at,
          news_alerts,
          optin_for_updates: payload.receive_updates,
          spending_areas: {
            ...spending_data
          },
          council: {
            tax: council.value_tax,
            council_1: council.council_1,
            council_2: council.council_2,
            council_3: council.council_3,
            council_4: council.council_4,
            council_5: council.council_5
          }
        })
      })
      .then(res => res.json())
      .then(res => {
        if (res["status"] === 200) {
          if (lsTest() === true) {
            localStorage.setItem(
              "budget",
              JSON.stringify({
                id: res.message,
                first_name: payload.firstName,
                sexual_orientation: payload.sexual_orientation,
                disability: payload.disability,
                risk: risk,
                age: payload.age,
                ethnicity: payload.ethnicity,
                customethnicity: payload.customethnicity,
                religion: payload.religion,
                gender: payload.gender,
                postcode: payload.postcode,
                reason: payload.reason,
                spending_areas: {
                  ...spending_data
                },
                council: {
                  tax: council.value_tax,
                  council_1: council.council_1,
                  council_2: council.council_2,
                  council_3: council.council_3,
                  council_4: council.council_4,
                  council_5: council.council_5
                }
              })
            );
            if (localStorage.getItem("budgets") === null) {
              localStorage.setItem("budgets", res.message);
            } else {
              localStorage.setItem("budgets", localStorage.getItem("budgets") + "," + res.message);
            }
          }
          dispatch(
            _SAVE_SUCCESS({
              data: res,
              reason: payload.reason
            })
          );

          dispatch(loadPeopleAction());
          dispatch(push(`/peoples-budget/${res.message}`));

          // Request to clear local storage upon save.
          if (lsTest()) {
            localStorage.clear("budget");
            localStorage.clear("news_alerts");
          }

          dispatch(_START_AGAIN());

          if (data) {
            dispatch(_LOAD_INITIAL_DATA_SUCCESS(data));
          }
        } else {
          dispatch(_SAVE_FAILURE(res));
        }
      })
      .catch(err => {
        dispatch(_SAVE_FAILURE(err));
      });
  };
};

const clearIndividualAction = () => {
  return {
    type: types.CLEAR_INDIVIDUAL
  };
};

const loadPeopleAction = (after = null) => {
  return function (dispatch, getState) {
    dispatch(_LOAD_PEOPLE());
    fetch(`${config.api.prefix}api/getPeople.php` + (after !== null ? "?after=" + after : ""), {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
      .then(res => res.json())
      .then(d => {
        if (after === null) {
          let stats = {
            budgets_submitted: 0,
            service_areas_protected: 0,
            service_areas_at_risk: 0
          };

          // Acquire the averages
          const averages = {
            health_social_care_partnership: d.averages.health_social_care_partnership,
            secondary_school_education: d.averages.secondary_school_education,
            primary_school_education: d.averages.primary_school_education,
            nursery_early_years: d.averages.nursery_early_years,
            additional_support_needs: d.averages.additional_support_needs,
            children_social_work: d.averages.children_social_work,
            councillor_pay_expenses: d.averages.councillor_pay_expenses,
            community_safety: d.averages.community_safety,
            landscape_services: d.averages.landscape_services,
            live_life_aberdeenshire: d.averages.live_life_aberdeenshire,
            office_accommodation: d.averages.office_accommodation,
            planning_and_development: d.averages.planning_and_development,
            public_transport: d.averages.public_transport,
            roads_bridges_harbours: d.averages.roads_bridges_harbours,
            recycling_waste_collection: d.averages.recycling_waste_collection,
            admin_business_support: d.averages.admin_business_support,
            capital_repayments_and_costs: d.averages.capital_repayments_and_costs
          };

          data.spending_areas.forEach((area, index) => {
            const risk = calculateRisk(data.spending_areas[index], averages[area.slug]);

            if (risk === "high") {
              stats.service_areas_at_risk = stats.service_areas_at_risk + 1;
            } else {
              stats.service_areas_protected = stats.service_areas_protected + 1;
            }
          });

          stats.budgets_submitted = Number(d.averages.total_submitted);

          fetch(`${config.api.prefix}api/getEvents.php`, {
              method: "GET",
              headers: {
                Accept: "application/json"
              }
            })
            .then(res => res.json())
            .then(data => {
              stats = {
                ...stats,
                give_up_count: data.message.total_gave_up,
                share_count: data.message.total_shared,
                started_count: data.message.total_started,
                visited_count: data.message.total_visited
              };
              dispatch(_GET_STATS_SUCCESS(stats));
            });
        }

        if (d.budgets.length === 0) {
          dispatch(_PREVENT_LOAD_MORE_BUDGETS());
        }

        dispatch(_LOAD_PEOPLE_SUCCESS({
          budgets: d.budgets,
          averages: d.averages
        }));
      })
      .catch(err => {
        dispatch(_LOAD_PEOPLE_FAILURE(err));
      });
  };
};

const loadIndividualAction = id => {
  return function (dispatch, getState) {
    dispatch(_LOAD_INDIVIDUAL());
    fetch(`${config.api.prefix}api/getIndividual.php?id=${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          dispatch(_LOAD_INDIVIDUAL_SUCCESS(data));
        } else {
          dispatch(_LOAD_INDIVIDUAL_FAILURE("No Budget By That ID"));
          dispatch(push("/peoples-budget"));
        }
      })
      .catch(err => {
        dispatch(_LOAD_INDIVIDUAL_FAILURE(err));
      });
  };
};

// Action to show the sucess modal when the budget is in target
const showBudgetSuccessModal = (showButton = true) => {
  return {
    type: types.SHOW_BUDGET_SUCCESS_MODAL,
    showButton
  };
};

const showBudgetFailureModal = () => {
  return {
    type: types.SHOW_BUDGET_FAILURE_MODAL
  };
};

const _toggleReferendumModal = () => {
  return {
    type: types.TOGGLE_REFERENDUM_MODAL
  };
};

const hideBudgetSuccessModal = () => {
  return {
    type: types.HIDE_BUDGET_SUCCESS_MODAL
  };
};

const hideBudgetFailureModal = () => {
  return {
    type: types.HIDE_BUDGET_FAILURE_MODAL
  };
};

const toggleRangeTooltip = () => {
  return {
    type: types.TOGGLE_RANGE_TOOLTIP
  };
};

const _setUserStartedFlag = () => {
  return {
    type: types.LOG_USER_STARTING_ACTION,
    payload: true
  };
};

const logUserStartingAction = () => {
  return (dispatch, getState) => {
    if (lsTest() === true) {
      const hasUserAlreadyStarted = JSON.parse(localStorage.getItem("user_started"));

      if (!hasUserAlreadyStarted) {
        dispatch(_setUserStartedFlag());
        dispatch(() => {
          fetch(`${config.api.prefix}api/saveEvent.php`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            body: JSON.stringify({
              event_type: "STARTED"
            })
          }).then(() => localStorage.setItem("user_started", "true"));
        });
      }
    }
  };
};

const logUserVisitedAction = () => {
  return (dispatch, getState) => {
    dispatch(() => {
      fetch(`${config.api.prefix}api/saveEvent.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify({
          event_type: "VISITED"
        })
      });
    });
  };
};

const postGiveUpReason = (reason, cb) => {
  return (dispatch, getState) => {
    dispatch(() => {
      fetch(`${config.api.prefix}api/saveEvent.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify({
          event_type: "GAVEUP",
          reason
        })
      }).then(() => cb());
    });
  };
};

const postShareAction = (cb = null) => {
  return (dispatch, getState) => {
    dispatch(() => {
      fetch(`${config.api.prefix}api/saveEvent.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify({
          event_type: "SHARED"
        })
      }).then(() => {
        if (cb) cb();
      });
    });
  };
};

const _LOAD_INITIAL_DATA = () => {
  return {
    type: types.LOAD_INITIAL_DATA
  };
};

const _CHANGE_SPENDING_AREA = () => {
  return {
    type: types.CHANGE_SPENDING_AREA
  };
};

const _LOAD_INDIVIDUAL = () => {
  return {
    type: types.LOAD_INDIVIDUAL
  };
};

const _CHANGE_COUNCIL_CATEGORY = () => {
  return {
    type: types.CHANGE_COUNCIL_CATEGORY
  };
};

const _CHANGE_COUNCIL_BUDGET = () => {
  return {
    type: types.CHANGE_COUNCIL_BUDGET
  };
};

const _PREVENT_LOAD_MORE_BUDGETS = () => {
  return {
    type: types.PREVENT_LOAD_MORE_BUDGETS,
    payload: true
  };
};

const _SAVE = () => {
  return {
    type: types.SAVE
  };
};

const _START_AGAIN = () => {
  return {
    type: types.START_AGAIN
  };
};

const _LOAD_PEOPLE = () => {
  return {
    type: types.LOAD_PEOPLE
  };
};

const _LOAD_INITIAL_DATA_SUCCESS = data => {
  return {
    type: types.LOAD_INITIAL_DATA_SUCCESS,
    payload: data
  };
};

const _CHANGE_SPENDING_AREA_SUCCESS = data => {
  return {
    type: types.CHANGE_SPENDING_AREA_SUCCESS,
    payload: data
  };
};

const _LOAD_INDIVIDUAL_SUCCESS = data => {
  return {
    type: types.LOAD_INDIVIDUAL_SUCCESS,
    payload: data
  };
};

const _SAVE_SUCCESS = data => {
  return {
    type: types.SAVE_SUCCESS,
    payload: data
  };
};

const _CHANGE_COUNCIL_CATEGORY_SUCCESS = data => {
  return {
    type: types.CHANGE_COUNCIL_CATEGORY_SUCCESS,
    payload: data
  };
};

const _CHANGE_COUNCIL_BUDGET_SUCCESS = data => {
  return {
    type: types.CHANGE_COUNCIL_BUDGET_SUCCESS,
    payload: data
  };
};

const _START_AGAIN_SUCCESS = data => {
  return {
    type: types.START_AGAIN_SUCCESS,
    payload: data
  };
};

const _LOAD_PEOPLE_SUCCESS = data => {
  return {
    type: types.LOAD_PEOPLE_SUCCESS,
    payload: data
  };
};

const _LOAD_INITIAL_DATA_FAILURE = data => {
  return {
    type: types.LOAD_INITIAL_DATA_FAILURE,
    payload: data
  };
};

const _CHANGE_SPENDING_AREA_FAILURE = data => {
  return {
    type: types.CHANGE_SPENDING_AREA_FAILURE,
    payload: data
  };
};

const _LOAD_INDIVIDUAL_FAILURE = data => {
  return {
    type: types.LOAD_INDIVIDUAL_FAILURE,
    payload: data
  };
};

const _CHANGE_COUNCIL_CATEGORY_FAILURE = data => {
  return {
    type: types.CHANGE_COUNCIL_CATEGORY_FAILURE,
    payload: data
  };
};

const _CHANGE_COUNCIL_BUDGET_FAILURE = data => {
  return {
    type: types.CHANGE_COUNCIL_BUDGET_FAILURE,
    payload: data
  };
};

const _SAVE_FAILURE = data => {
  return {
    type: types.SAVE_FAILURE,
    payload: data
  };
};

const _START_AGAIN_FAILURE = data => {
  return {
    type: types.START_AGAIN_FAILURE,
    payload: data
  };
};

const _LOAD_PEOPLE_FAILURE = data => {
  return {
    type: types.LOAD_PEOPLE_FAILURE,
    payload: data
  };
};

export {
  showBudgetSuccessModal,
  showBudgetFailureModal,
  hideBudgetSuccessModal,
  hideBudgetFailureModal,
  triggerSubmitModal,
  toggleReferendumModal,
  postShareAction,
  loadInitialDataAction,
  changeSpendingAreaAction,
  changeCouncilCategoryAction,
  changeCouncilBudgetAction,
  saveAction,
  clearIndividualAction,
  startAgainAction,
  loadPeopleAction,
  loadIndividualAction,
  logUserStartingAction,
  logUserVisitedAction,
  postGiveUpReason,
  setThankYouModal,
  _LOAD_INITIAL_DATA,
  _CHANGE_SPENDING_AREA,
  _SAVE,
  _CHANGE_COUNCIL_CATEGORY,
  _CHANGE_COUNCIL_BUDGET,
  _START_AGAIN,
  _LOAD_PEOPLE,
  _LOAD_INITIAL_DATA_SUCCESS,
  _CHANGE_SPENDING_AREA_SUCCESS,
  _SAVE_SUCCESS,
  _CHANGE_COUNCIL_CATEGORY_SUCCESS,
  _CHANGE_COUNCIL_BUDGET_SUCCESS,
  _START_AGAIN_SUCCESS,
  _LOAD_PEOPLE_SUCCESS,
  _LOAD_INITIAL_DATA_FAILURE,
  _CHANGE_SPENDING_AREA_FAILURE,
  _SAVE_FAILURE,
  _CHANGE_COUNCIL_CATEGORY_FAILURE,
  _CHANGE_COUNCIL_BUDGET_FAILURE,
  _START_AGAIN_FAILURE,
  _LOAD_PEOPLE_FAILURE,
  _LOAD_INDIVIDUAL,
  _LOAD_INDIVIDUAL_SUCCESS,
  _LOAD_INDIVIDUAL_FAILURE
};