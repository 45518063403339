import React from 'react';
import classnames from 'classnames';
import { Cell, Grid } from '../../layout';

const Notification = ({
  body,
  heading = null,
  isActive,
  handleClose,
  inFixedPostion,
}) => (
    <div className={classnames('c-notification', {
      'c-notification--active': isActive,
      'c-notification--in-fixed-position': inFixedPostion,
    })
    }>
      <div className="c-notification__inner">
        <Grid>
          <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
            <h2 className="c-notification__heading">Budget News Alert</h2>
          </Cell>

          <Cell size={{ desktop: '10', tablet: '11', phone: '5' }}>
            <p className="c-notification__body">{body}</p>
          </Cell>
        </Grid>
        <span className="c-notification__close" onClick={() => handleClose()}>
          <i className="fa fa-times" />
        </span>
      </div>
    </div>
  );

export default Notification;
