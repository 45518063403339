import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";

import Button from "../../components/common/button/Button";
import Divide from "../../components/common/divide/Divide";
import BudgetTotalBar from "../../components/budget-total-bar/BudgetTotalBar";
import SpendingAreaCard from "../../components/common/cards/spending-area-card/SpendingAreaCard";
import PeoplesBudgetStats from "../../components/sections/peoples-budget-stats/PeoplesBudgetStats";

import { Cell, Grid } from "../../components/layout";

import Secondary from "../../layouts/Secondary";
import Generic from "../../layouts/Generic";

import config from "../../config";

import { budgetActions, reportsActions } from "../../reducks";

import data from "../../data";

import { smallNumber, calculateTotalByKey } from "../../helpers/number";
import { calculateRisk } from "../../helpers/risk";
import IncomeAreaSectionSubmitted from "../../components/sections/income-areas-section/IncomeAreasSectionSubmitted";
import Modal from "../../components/common/modal/Modal";

class BudgetReports extends Component {
  state = {
    isCouncilInfoModalActive: false,
    spendingAreaInfoBlocks: [],
    showAllBudgets: false,
    currentBudgetEntryPage: 1,
    email: "",
    pass: ""
  };

  get spendingAreas() {
    return this.props._budget.spending_areas;
  }

  componentDidMount() {
    /**
     * If the user navigates to this page from the homepage via the
     * view all budgets link then we anchor them to the relevant section.
     */
    const { hash } = this.props.location;
    const selector = hash.split("#").join("");

    if (hash === "") {
      window.scroll(0, 0);
    } else if (document.getElementById(selector)) {
      window.scroll(0, document.getElementById(selector).offsetTop);
    }

    let spendingAreasInfoContent = this.spendingAreas.map(item => {
      return {
        ...item,
        isInfoCardActive: false,
        isImpactsCardActive: false,
        active: false
      };
    });

    this.setState({
      spendingAreaInfoBlocks: spendingAreasInfoContent
    });

    // Get Report Data
    this.props.getData();
    setTimeout(() => {
      this.setState({ loaded: true });
      this.props.changeFilter();
    }, 1500);
  }

  getReportCSV() {
    this.updateData(null, () => {
      fetch(`${config.api.prefix}api/csv.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify({
          data: this.state.data
        })
      })
        .then(res => res.json())
        .then(data => {
          this.exportToCsv("reports.csv", data);
        })
        .catch(err => { });
    });
  }

  exportToCsv(filename, rows) {
    let processRow = function (row) {
      let finalVal = "";
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }
        if (j > 0) {
          finalVal += ",";
        }
        finalVal += result;
      }
      return finalVal + "\n";
    };

    let csvFile = "";
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  updateData = (nextProps = null, cb = null) => {
    let budget = nextProps ? nextProps._budget : this.props._budget;
    let reports = nextProps ? nextProps._reports : this.props._reports;

    // INITIAL
    let data = [
      {
        initial_total_spend: smallNumber(budget.initial_total),
        total_spend: smallNumber(reports.displayed.total_spend),
        number_of_budgets_submitted: reports.displayed.budgets_submitted
      }
    ];

    data.push({ "---------------": "Spending Areas" });

    // SPENDING_AREAS
    // eslint-disable-next-line
    reports.displayed.spending_areas != null &&
      budget.spending_areas.map((item, index) => {
        // let risk = calculateRisk(budget.spending_areas[index], this.props._reports.displayed.spending_areas[item.slug]);
        let risk = "low";

        if (budget.spending_areas.length > 0) {
          const riskData = budget.spending_areas.filter(area => area.slug === item.slug)[0];
          risk = calculateRisk(riskData, this.props._reports.displayed.spending_areas[item.slug]);
        }

        if (reports.filter.spending_areas[index]) {
          if (
            reports.displayed.spending_areas[item.slug] !== undefined &&
            reports.filter.spending_areas[index].selected &&
            ((reports.filter.types[0].selected && risk === "low") ||
              (reports.filter.types[1].selected && risk === "medium") ||
              (reports.filter.types[2].selected && risk === "high") ||
              (!reports.filter.types[0].selected &&
                !reports.filter.types[1].selected &&
                !reports.filter.types[2].selected))
          ) {
            data.push({
              spending_area: item.name,
              spending_area_initial: smallNumber(Number(item.max)),
              spending_area_actual: smallNumber(Number(reports.displayed.spending_areas[item.slug])),
              spending_area_risk: risk
            });
          }
        }
      });

    data.push({ "----------------": "Bring Money Into the Council" });

    // COUNCIL
    data.push({
      bring_money_to_council_item: budget.council.council_1_name,
      bring_money_to_council_percentage: Number(reports.displayed.council_1 * 100).toFixed(2) + "%",
      bring_money_to_council_value: smallNumber(Number(budget.council.council_1_val))
    });

    data.push({
      bring_money_to_council_item: budget.council.council_2_name,
      bring_money_to_council_percentage: Number(reports.displayed.council_2 * 100).toFixed(2) + "%",
      bring_money_to_council_value: smallNumber(Number(budget.council.council_2_val))
    });

    data.push({
      bring_money_to_council_item: budget.council.council_3_name,
      bring_money_to_council_percentage: Number(reports.displayed.council_3 * 100).toFixed(2) + "%",
      bring_money_to_council_value: smallNumber(Number(budget.council.council_3_val))
    });

    data.push({
      bring_money_to_council_item: budget.council.council_4_name,
      bring_money_to_council_percentage: Number(reports.displayed.council_4 * 100).toFixed(2) + "%",
      bring_money_to_council_value: smallNumber(Number(budget.council.council_4_val))
    });

    data.push({
      bring_money_to_council_item: budget.council.council_5_name,
      bring_money_to_council_percentage: Number(reports.displayed.council_5 * 100).toFixed(2) + "%",
      bring_money_to_council_value: smallNumber(Number(budget.council.council_5_val))
    });

    data.push({ "-----------------": "Filters" });

    // FILTERS
    data.push({
      filter_key: "age",
      filter_value:
        reports.filter.age
          .filter(item => {
            return item.selected;
          })
          .map(item => {
            return item.name;
          })
          .join(", ") || "None"
    });
    data.push({
      filter_key: "gender",
      filter_value:
        reports.filter.gender
          .filter(item => {
            return item.selected;
          })
          .map(item => {
            return item.name;
          })
          .join(", ") || "None"
    });
    data.push({
      filter_key: "risk",
      filter_value:
        reports.filter.types
          .filter(item => {
            return item.selected;
          })
          .map(item => {
            return item.name;
          })
          .join(", ") || "None"
    });
    data.push({
      filter_key: "postal",
      filter_value:
        reports.filter.postal
          .filter(item => {
            return item.selected;
          })
          .map(item => {
            return item.name;
          })
          .join(", ") || "None"
    });
    data.push({
      filter_key: "locality",
      filter_value:
        reports.filter.locality
          .filter(item => {
            return item.selected;
          })
          .map(item => {
            return item.name;
          })
          .join(", ") || "None"
    });

    this.setState(
      {
        data: this.flatten(data)
      },
      () => {
        // if a callback has been provided then call it.
        if (cb) cb();
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps._reports.displayed !== this.props._reports.displayed) {
      this.updateData(nextProps);
    }
  }

  flatten = arr => {
    let nar = [];
    // eslint-disable-next-line
    arr.map(item => {
      // eslint-disable-next-line
      Object.keys(item).map(item2 => {
        let ci = this.checkIndex(nar, item2);
        // eslint-disable-next-line
        nar[ci][item2] === undefined ? (nar[ci][item2] = item[item2]) : null;
      });
    });
    return nar;
  };

  checkIndex = (nar, key) => {
    let i;
    for (i = 0; i < 900; i++) {
      // eslint-disable-next-line
      nar[i] === undefined ? (nar[i] = {}) : null;
      if (nar[i][key] === undefined) {
        return i;
      }
    }
  };

  toggleCouncilModal = () => {
    this.setState({
      isCouncilInfoModalActive: !this.state.isCouncilInfoModalActive
    });
  };

  clickFilter = val => {
    if (this.state.filter_key === null) {
      this.setState({ filter_key: val, filter: "reports_filters-block show" });
    } else {
      if (this.state.filter_key === val) {
        this.setState({ filter: "reports_filters-block", filter_key: null });
      } else {
        this.setState({ filter: "reports_filters-block" });
        setTimeout(() => {
          this.setState({ filter_key: val });
          setTimeout(() => {
            this.setState({ filter: "reports_filters-block show" });
          }, 250);
        }, 250);
      }
    }
  };

  render() {
    const { _reports, _budget } = this.props;

    const totalMaxValue = calculateTotalByKey(_budget.spending_areas, "max");
    const totalMinValue = calculateTotalByKey(_budget.spending_areas, "min");
    const currentTargetPercentage =
      ((812000000 - _budget.initial_total) / (totalMaxValue - totalMinValue)) * 100;
    const spendingTargetWidthValue = currentTargetPercentage < 100 ? currentTargetPercentage : 100;

    if (this.props._reports.auth.auth_status !== true) {
      return (
        <Generic>
          <div className="l-section l-section--pt-0">
            <Grid>
              <Cell size={{ desktop: "9", tablet: "9", phone: "6" }}>
                <h1>Budget Reports</h1>
              </Cell>

              <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
                <form
                  noValidate
                  id="reports-login-form"
                  style={{ maxWidth: "650px", margin: "50px auto 25px" }}
                  onSubmit={e => {
                    e.preventDefault();
                    this.props.login(this.state.email, this.state.pass);
                  }}
                >
                  <h2>Login</h2>
                  <div className="c-form-control">
                    <label className="c-form-control__label">Email Address</label>
                    <input
                      type="Email"
                      value={this.state.email}
                      placeholder="Your Email Address"
                      id="login-name"
                      onChange={e => this.setState({ email: e.target.value })}
                      style={{
                        border: this.props._reports.auth.auth_error ? "solid 1px red" : "none"
                      }}
                    />
                  </div>
                  <div className="c-form-control">
                    <label className="c-form-control__label">Password</label>
                    <input
                      type="password"
                      autoComplete="rep-password"
                      value={this.state.pass}
                      placeholder="Password"
                      id="login-pass"
                      onChange={e => this.setState({ pass: e.target.value })}
                      style={{
                        border: this.props._reports.auth.auth_error ? "solid 1px red" : "none"
                      }}
                    />
                  </div>
                  <button className="c-button">Log in</button>
                </form>
              </Cell>
            </Grid>
          </div>
        </Generic>
      );
    }

    return (
      <Secondary>
        {
          this.state.isCouncilInfoModalActive &&
          <Modal handleClose={() => this.toggleCouncilModal()}>
            <i className="c-modal__budget-icon fas fa-info" />

            <ul className="c-modal__list">
              <li>1% added to Council Tax would mean a band D property going up £12.41 per year.</li>
              <li>2% added to Council Tax would mean a band D property going up £24.82 per year.</li>
              <li>3% added to Council Tax would mean a band D property going up £37.22 per year.</li>
              <li>4% added to Council Tax would mean a band D property going up £49.63 per year.</li>
              <li>4.79% added to Council Tax would mean a band D property going up £59.43 per year.</li>
            </ul>
          </Modal>
        }
        <div className="l-section">
          <div className="c-tabbed-menu">
            <div
              className={classnames("c-tabbed-menu__tab", {
                "c-tabbed-menu__tab--active": this.state.filter_key === "spending_areas"
              })}
              onClick={() => this.clickFilter("spending_areas")}
            >
              Service Areas
            </div>

            <div
              className={classnames("c-tabbed-menu__tab", {
                "c-tabbed-menu__tab--active": this.state.filter_key === "gender"
              })}
              onClick={() => this.clickFilter("gender")}
            >
              Gender Identity
            </div>

            <div
              className={classnames("c-tabbed-menu__tab", {
                "c-tabbed-menu__tab--active": this.state.filter_key === "age_range"
              })}
              onClick={() => this.clickFilter("age_range")}
            >
              Age Range
            </div>

            <div
              className={classnames("c-tabbed-menu__tab", {
                "c-tabbed-menu__tab--active": this.state.filter_key === "risk"
              })}
              onClick={() => this.clickFilter("risk")}
            >
              Risk
            </div>

            <div
              className={classnames("c-tabbed-menu__tab", {
                "c-tabbed-menu__tab--active": this.state.filter_key === "locality"
              })}
              onClick={() => this.clickFilter("locality")}
            >
              Wards
            </div>

            <div
              className={classnames("c-tabbed-menu__tab", {
                "c-tabbed-menu__tab--active": this.state.filter_key === "postal"
              })}
              onClick={() => this.clickFilter("postal")}
            >
              Postal
            </div>
          </div>

          {this.state.filter_key === "gender" && (
            <div className="c-tabbed-menu-panel">
              <Grid>
                {this.props._reports.filter.gender.map((item, k) => (
                  <Cell key={k} size={{ desktop: '3', tablet: '4', phone: '3' }}>
                    <div className="c-form-control">
                      <label className="c-form-control__label c-form-control__label--inline">
                        <input
                          type="checkbox"
                          className="c-form-control__faux-check-input"
                          name="gender"
                          checked={item.selected}
                          onChange={() => this.props.changeFilter("gender", k, !item.selected)}
                        />
                        <span className="c-form-control__faux-check">{item.name}</span>
                      </label>
                    </div>
                  </Cell>
                ))}
              </Grid>
            </div>
          )}

          {this.state.filter_key === "age_range" && (
            <div className="c-tabbed-menu-panel">
              <Grid>
                {this.props._reports.filter.age.map((item, k) => (
                  <Cell key={k} size={{ desktop: '3', tablet: '4', phone: '3' }}>
                    <div className="c-form-control">
                      <label className="c-form-control__label c-form-control__label--inline">
                        <input
                          type="checkbox"
                          className="c-form-control__faux-check-input"
                          name="gender"
                          checked={item.selected}
                          onChange={() => this.props.changeFilter("age", k, !item.selected)}
                        />
                        <span className="c-form-control__faux-check">{item.name}</span>
                      </label>
                    </div>
                  </Cell>
                ))}
              </Grid>
            </div>
          )}

          {this.state.filter_key === "risk" && (
            <div className="c-tabbed-menu-panel">
              <Grid>
                {this.props._reports.filter.types.map((item, k) => (
                  <Cell key={k} size={{ desktop: '3', tablet: '4', phone: '2' }}>
                    <div className="c-form-control">
                      <label className="c-form-control__label c-form-control__label--inline">
                        <input
                          type="checkbox"
                          className="c-form-control__faux-check-input"
                          name="gender"
                          checked={item.selected}
                          onChange={() => this.props.changeFilter("types", k, !item.selected)}
                        />
                        <span className="c-form-control__faux-check">{item.name}</span>
                      </label>
                    </div>
                  </Cell>
                ))}
              </Grid>
            </div>
          )}

          {this.state.filter_key === "spending_areas" && (
            <div className="c-tabbed-menu-panel">
              <Grid>
                {this.props._reports.filter.spending_areas.map((item, k) => (
                  <Cell key={k} size={{ desktop: '3', tablet: '4', phone: '6' }}>
                    <div className="c-form-control">
                      <label className="c-form-control__label">
                        <input
                          type="checkbox"
                          className="c-form-control__faux-check-input"
                          name="spending_areas"
                          checked={item.selected}
                          onChange={() =>
                            this.props.changeFilter("spending_areas", k, !item.selected)
                          }
                        />
                        <span className="c-form-control__faux-check">{item.name}</span>
                      </label>
                    </div>
                  </Cell>
                ))}
              </Grid>
            </div>
          )}
          {this.state.filter_key === "locality" && (
            <div className="c-tabbed-menu-panel">
              <Grid>
                {this.props._reports.filter.locality.map((item, k) => (
                  <Cell key={k} size={{ desktop: '3', tablet: '4', phone: '6' }}>
                    <div className="c-form-control">
                      <label className="c-form-control__label c-form-control__label--inline">
                        <input
                          type="checkbox"
                          className="c-form-control__faux-check-input"
                          name="locality"
                          checked={item.selected}
                          onChange={() => this.props.changeFilter("locality", k, !item.selected)}
                        />
                        <span className="c-form-control__faux-check">{item.name}</span>
                      </label>
                    </div>
                  </Cell>
                ))}
              </Grid>
            </div>
          )}
          {this.state.filter_key === "postal" && (
            <div className="c-tabbed-menu-panel">
              <Grid>
                {this.props._reports.filter.postal.map((item, k) => (
                  <Cell key={k} size={{ desktop: '3', tablet: '4', phone: '3' }}>
                    <div className="c-form-control">
                      <label className="c-form-control__label c-form-control__label--inline">
                        <input
                          type="checkbox"
                          className="c-form-control__faux-check-input"
                          name="postal"
                          checked={item.selected}
                          onChange={() => this.props.changeFilter("postal", k, !item.selected)}
                        />
                        <span className="c-form-control__faux-check">{item.name}</span>
                      </label>
                    </div>
                  </Cell>
                ))}
              </Grid>
            </div>
          )}

          <Grid>
            <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
              <h2>
                Showing{" "}
                <span className="u-text-blue">{_reports.displayed.budgets_submitted} Budgets</span>
              </h2>
            </Cell>
            <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
              <h1>
                Budget <span className="light">Reports</span>
              </h1>
            </Cell>
            <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
              <div className="c-button-group c-button-group--reports">
                <Button
                  onClick={() => {
                    this.props.clear();
                    this.setState({
                      filter: "reports_filters-block",
                      filter_key: null
                    });
                    this.props.changeFilter();
                  }}
                >
                  Clear Filters
                </Button>
                <div className="c-button-wrap">
                  <a className="c-button" href="/api/masterCSV.php" style={{ color: "#fff" }}>
                    Export Master CSV
                  </a>
                </div>
                <Button onClick={() => this.getReportCSV()}>Export CSV</Button>
                <Button onClick={() => this.props.logout()}>Logout</Button>
              </div>
            </Cell>
          </Grid>

          <Grid>
            <Cell size={{ desktop: "6", tablet: "6", phone: "6" }}>
              <BudgetTotalBar
                isRed={true}
                value={`£${smallNumber(_budget.current_total)}`}
                widthValue={spendingTargetWidthValue}
                minLabel={`£${smallNumber(totalMinValue)}`}
                maxLabel={`£${smallNumber(totalMaxValue)}`}
              >
                <h2 className="u-text-dark-grey c-total-bar-header__title">
                  <span className="c-total-bar-header__sub-title">Annual Spending</span>
                  <br />£{smallNumber(812000000)}
                </h2>
              </BudgetTotalBar>
            </Cell>
            <Cell size={{ desktop: "6", tablet: "6", phone: "6" }} alignment="bottom">
              <BudgetTotalBar
                toggleInfoModal={this.toggleCouncilModal}
                isTax
                minLabel="0%"
                maxLabel="4.79%"
                value={`${(_budget.peoples_averages.council_tax === _budget.council.max_tax
                  ? 4.79
                  : _budget.peoples_averages.council_tax / (_budget.council.max_tax / 5)
                ).toFixed(1)}%`}
                widthValue={`${(_budget.peoples_averages.council_tax / _budget.council.max_tax) *
                  100}`}
              >
                <h2 className="u-text-dark-grey c-total-bar-header__title">
                  <span className="c-total-bar-header__sub-title">Council Tax Increase</span>
                  <br />£ {smallNumber(Number(_budget.peoples_averages.council_tax))}
                </h2>
              </BudgetTotalBar>
            </Cell>
          </Grid>
        </div>

        <section className="l-section l-section--savings" id="service-areas-section">
          <div className="l-section__header">
            <Grid>
              <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
                <h2 className="l-section__title">Service Areas</h2>
                <p className="l-section__sub-title">
                  Help meet your target by creating savings in key areas
                </p>
              </Cell>
            </Grid>
          </div>

          <div style={{ position: "relative" }}>
            <Grid>
              {_reports &&
                _reports.filter.spending_areas &&
                _reports.filter.spending_areas.map((item, k) => {
                  let risk = calculateRisk(
                    data.spending_areas[k],
                    this.props._reports.displayed.spending_areas[item.key]
                  );

                  if (
                    item.selected &&
                    this.props._reports.displayed.total_spend > 0 &&
                    ((this.props._reports.filter.types[0].selected && risk === "low") ||
                      (this.props._reports.filter.types[1].selected && risk === "medium") ||
                      (this.props._reports.filter.types[2].selected && risk === "high") ||
                      (!this.props._reports.filter.types[0].selected &&
                        !this.props._reports.filter.types[1].selected &&
                        !this.props._reports.filter.types[2].selected))
                  ) {
                    return (
                      <Cell size={{ desktop: "4", tablet: "6", phone: "6" }} key={k}>
                        {console.log('data >>>', data)}
                        <SpendingAreaCard
                          className="c-card--summary"
                          key={k}
                          cons={(data.spending_areas[k]) ? data.spending_areas[k].triggers : ''}
                          color={(data.spending_areas[k]) ? data.spending_areas[k].color : ''}
                          name={item.name}
                          icon={(data.spending_areas[k]) ? data.spending_areas[k].icon : ''}
                          value={this.props._reports.displayed.spending_areas[item.key]}
                          max={(data.spending_areas[k]) ? data.spending_areas[k].max : ''}
                          info={(data.spending_areas[k]) ? data.spending_areas[k].info : ''}
                          locked={(data.spending_areas[k]) && (data.spending_areas[k].max === data.spending_areas[k].min)}
                          isSummary={(data.spending_areas[k]) && !(data.spending_areas[k].max === data.spending_areas[k].min) && true}
                          risk={(data.spending_areas[k]) && !(data.spending_areas[k].max === data.spending_areas[k].min) && risk}
                          budgetReports={true}
                          step={(data.spending_areas[k]) ? data.spending_areas[k].step : ''}
                        />
                      </Cell>
                    );
                  } else {
                    return null;
                  }
                })}
            </Grid>
          </div>
        </section>

        <IncomeAreaSectionSubmitted
          budget={_budget.peoples_averages}
          councilBudget={_budget.council}
          selectedBudget={_budget.selected_budget}
          isSummary={true}
        />

        <Divide isLarge={true} isLight={true} />
        <PeoplesBudgetStats location={this.props.location} />
      </Secondary>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      _budget: state.budget,
      _reports: state.reports
    };
  },
  (dispatch, ownProps) => {
    return {
      login: (email, password) => dispatch(reportsActions.login(email, password)),
      logout: () => dispatch(reportsActions.logout()),
      changeFilter: (category = null, key = null, value = null) =>
        dispatch(reportsActions.changeFilter(category, key, value)),
      getPeople: (after = null) => dispatch(budgetActions.loadPeopleAction(after)),
      getData: () => dispatch(reportsActions.getData()),
      changeCouncilCategoryAction: (category, value) =>
        dispatch(budgetActions.changeCouncilCategoryAction(category, value)),
      clear: () => dispatch(reportsActions.clear())
    };
  }
)(BudgetReports);
