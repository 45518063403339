import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Link } from 'react-router-dom';

const Button = ({ children, inverted = false, href = null, onClick, className }) => {
  const buttonClassnames = classnames('c-button', className, {
    'c-button--inverted': inverted,
  });

  return (
    <div className="c-button-wrap">
      {
        href &&
        <Link to={href} className={buttonClassnames}>
          {children}
        </Link>
      }
      {
        !href &&
        <button className={buttonClassnames} onClick={onClick}>
          {children}
        </button>
      }
    </div>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  inverted: PropTypes.bool,
  href: PropTypes.string,
};

export default Button;
