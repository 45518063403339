import React from 'react';
import { Link } from 'react-router-dom';

import { Cell, Container, Grid } from '../../layout';
import Divide from '../divide/Divide';

import logo from '../../../assets/images/footer.png';

const Footer = () => (
  <Container>
    <Divide isXLarge={true} isLight={true} />

    <footer className="c-footer">
      <Grid>
        <Cell size={{ desktop: '6', tablet: '6', phone: '3' }}>
          <h2>@BhamCityCouncil</h2>
          <p>Follow Birmingham City Council to get involved and have your say</p>
        </Cell>
        <Cell size={{ desktop: '6', tablet: '6', phone: '3' }}>
          <div className="c-footer__logo">
            <img src={logo} alt="Birmingham logo" height="100" />
          </div>
        </Cell>
        <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
          <ul className="c-footer__social-list">
            <li>
              <a href="https://twitter.com/BhamCityCouncil" rel="noopener noreferrer" target="_blank">
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/birminghamcitycouncil/" rel="noopener noreferrer" target="_blank">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
          </ul>
        </Cell>
        <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
          <ul className="c-footer__link-list u-text-right u-text-center--mobile">
            <li>
              <Link to="/" className="c-footer__link-list-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/cookies" className="c-footer__link-list-link">
                Cookies
              </Link>
            </li>
            <li>
              <Link to="/accessibility" className="c-footer__link-list-link">
                Accessibility
              </Link>
            </li>
          </ul>
        </Cell>
        <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
          <div className="u-text-left u-text-center--mobile">
            <p>&copy; Birmingham City Council</p>
          </div>
        </Cell>
        <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
          <div className="u-text-right u-text-center--mobile">
            Software by <a className="fc-white" href="https://indigomultimedia.com/" target="_blank">Indigo</a>
          </div>
        </Cell>
      </Grid>
    </footer>
  </Container >
);

export default Footer;
