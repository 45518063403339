import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png'

/**
 * Primary Layout
 * This layout is used on the homepage.
 */

import Header from '../components/common/header/Header';
import TopBar from '../components/common/top-bar/TopBar';
import Footer from '../components/common/footer/Footer';
import { Cell, Grid } from '../components/layout';


import {
  pageActions,
} from '../reducks';

import Sidebar from '../components/sidebar/Sidebar';
import Countdown from '../components/common/countdown/Countdown';

export const Primary = ({
  children,
  toggleMenuAction,
  page,
}) => (
    <div className={classnames('l-layout l-layout--primary', { 'l-layout--menu-active': page.menuActive })}>
      <TopBar className="c-top-bar--empty" />

      <Header className="c-header--no-border">
        <Grid>
          <Cell size={{ desktop: '3', tablet: '3', phone: '6' }}>
            <Link to="/" className="c-header__logo-wrap">
              <img className="c-header__logo" src={logo} alt="Birmingham City Council Logo" />
            </Link>
          </Cell>
          <Cell size={{ desktop: '7', tablet: '6', phone: '6' }}>
            <div className="u-text-center u-hide-mobile">
              <h2 className="c-header__budget-title light">
                <Countdown date="2021-12-31T23:59:59" />
              </h2>
            </div>
          </Cell>
        </Grid>

        <div className="c-header__menu-trigger" onClick={() => toggleMenuAction()}>
          <div className={classnames('c-header__hamburger', { 'is-active': page.menuActive })}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </Header>

      {children}

      <Sidebar />

      <Footer />
    </div>
  );

export default connect((state, ownProps) => {
  return {
    page: state.page
  }
}, (dispatch, ownProps) => {
  return {
    toggleMenuAction: () => dispatch(pageActions.toggleMenuAction())
  }
})(Primary)
