import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * <Cell /> Component
 * The cell component should sit within a <Grid /> component. It defines
 * the properties of a given cell.
 * More documentation will follow but for now, refer to the _grid.scss file
 * for more information about modifiers.
 *
 * Custom class names work in an interesting way. Especially those required for
 * setting sizes based on the device type, for example:
 * <Cell size="4" customClassNames="l-grid__cell-6-desktop l-grid__cell-4-tablet">
 * Notice how the largest device type must be declared first.
 *
 */

const Cell = ({
  children,
  alignment,
  order,
  offset,
  customClassNames,
  size,
}) => {
  const cellClasses = classNames({
    [`l-grid__cell-${size.desktop}-desktop`]: size && size.desktop,
    [`l-grid__cell-${size.phone}-phone`]: size && size.phone,
    [`l-grid__cell-${size.tablet}-tablet`]: size && size.tablet,
    [`l-grid__cell--${alignment}`]: alignment,
    [`l-grid__cell--order-${order && order.phone}-phone`]: order && order.phone,
    [`l-grid__cell--order-${order && order.tablet}-tablet`]: order && order.tablet,
    [`l-grid__cell--order-${order && order.desktop}-desktop`]: order && order.desktop,
    [`l-grid__cell--offset-${offset && offset.phone}-phone`]: offset && offset.phone,
    [`l-grid__cell--offset-${offset && offset.tablet}-tablet`]: offset && offset.tablet,
    [`l-grid__cell--offset-${offset && offset.desktop}-desktop`]: offset && offset.desktop,
  });

  return (
    <div className={[cellClasses, customClassNames].join(' ')}>
      { children }
    </div>
  );
};

Cell.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  alignment: PropTypes.string,
  order: PropTypes.string,
  offset: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  customClassNames: PropTypes.arrayOf(PropTypes.string),
};

Cell.defaultProps = {
  alignment: null,
  order: null,
  offset: null,
  customClassNames: null,
};

export default Cell;
