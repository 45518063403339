import React, { Component } from 'react';

import { Cell, Grid } from '../../components/layout';

import pc from '../../pc';

class SubmitBudgetForm extends Component {
  state = {
    payload: {
      name: '',
      disability: '',
      sexual_orientation: 'Sexual Orientation',
      ageRange: 'Age Range',
      ethnicity: 'Ethnicity',
      customethnicity: '',
      gender: 'Gender Identity',
      religion: 'Religion',
      postcode: '',
      reason: '',
      receive_updates: '0'
    },
    shouldShowErrorMessage: false,
    showcustomethnicity: 'none'
  }

  handleInputChange = (key, value) => {
    // console.log("key=" + key + "value=" + value);
    if (key == "ethnicity" && value == "Other") {
      console.log("Other selected");
      this.setState({ showcustomethnicity: "block" });
    } else if (key == "ethnicity" && value != "Other") {
      this.setState({ showcustomethnicity: "none" });
    }
    this.setState({
      payload: {
        ...this.state.payload,
        [key]: value,
      }
    });
  }

  /**
   * TODO Abstract the core of this validation code to a helper of some kind...
   */
  handleFormSubmit = (event) => {
    event.preventDefault();

    const { onSubmit } = this.props;
    const { payload } = this.state;

    // const postcodeCheck = pc.filter((item) => {
    //   return item.postcode_no_space.toLowerCase() === this.state.payload.postcode.toLowerCase() || item.postcode.toLowerCase() === this.state.payload.postcode.toLowerCase()
    // })

    let valid = true;

    Object.keys(payload).forEach(field => {
      const $input = this.budgetForm.querySelector(`[data-input-ref="${field}"]`);
      const $genderLabel = this.budgetForm.querySelector('.c-form-control__label--gender');

      // If user has selected receive_updates we need to make sure the email
      // address is valid.
      if (field === 'receive_updates' && payload['receive_updates'] === '1') {
        const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        if (!payload['email'] || payload['email'] !== '') {
          if (!pattern.test(payload['email'])) {
            valid = false;
            this.budgetForm.querySelector(`[data-input-ref="email"]`).classList.add('u-is-error');
            return;
          }
        }
      }

      // Ensure age range has been selected
      if (field === 'ageRange') {
        if (payload[field] === 'Age Range') {
          valid = false;
          $input.classList.add('u-is-error');
          return;
        }
      }

      // Ensure ethnicity has been selected
      if (field === 'ethnicity') {
        if (payload[field] === 'Ethnicity') {
          valid = false;
          $input.classList.add('u-is-error');
          return;
        }
      }

      // Ensure postcode has been entered
      if (field === 'postcode') {
        if (payload[field] === '') {
          valid = false;
          $input.classList.add('u-is-error');
          return;
        }
      }

      // Ensure sexual orientation has been selected
      if (field === 'sexual_orientation') {
        if (payload[field] === 'Sexual Orientation') {
          valid = false;
          $input.classList.add('u-is-error');
          return;
        }
      }

      // Ensure religion has been selected
      if (field === 'religion') {
        if (payload[field] === 'Religion') {
          valid = false;
          $input.classList.add('u-is-error');
          return;
        }
      }

      // Ensure gender has been selected
      if (field === 'gender') {
        if (payload[field] === 'Gender Identity') {
          valid = false;
          $input.classList.add('u-is-error');
          return;
        }
      }

      // Ensure email regex is matched
      if (field === 'email') {
        const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        if (payload[field] !== '') {
          if (!pattern.test(payload[field])) {
            valid = false;
            $input.classList.add('u-is-error');
            return;
          }
        }
      }

      // Ensure postcode is valid
      // if (field === 'postcode') {
      //   const pattern = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})/;

      //   if (!pattern.test(payload[field].replace(/\s+/g, ''))) {
      //     valid = false;
      //     $input.classList.add('u-is-error');
      //     return;
      //   }

      //   if (postcodeCheck.length <= 0) {
      //     valid = false;
      //     $input.classList.add('u-is-error');
      //     return;
      //   }
      // }

      // // Assume that there are no errors so we can hide the messages
      // if ($input) {
      //   if ($input.classList.contains('u-is-error')) {
      //     $input.classList.remove('u-is-error');
      //   }
      // }
    });

    if (!valid) {
      this.setState({ shouldShowErrorMessage: true });
    } else {
      this.setState({ shouldShowErrorMessage: false });

      // Finally if the user wants to remain annonymous then we set their display
      // name to 'Annonymous'.
      this.setState({
        payload: {
          ...this.state.payload,
          name: this.state.payload.name === '' ? 'Anonymous' : this.state.payload.name,
        }
      }, () => onSubmit(this.state.payload));
    }
  }

  render() {
    const {
      shouldShowErrorMessage,
      payload: {
        name,
        postcode,
        ageRange,
        ethnicity,
        customethnicity,
        sexual_orientation,
        religion,
        gender
      }
    } = this.state;

    return (
      <form
        noValidate
        id="send-budget-form"
        className="o-form"
        onSubmit={e => this.handleFormSubmit(e)}
        ref={budgetForm => (this.budgetForm = budgetForm)}
      >
        <Grid>
          {
            shouldShowErrorMessage &&
            <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
              <p className="u-text-error">
                Some of the information you have provided doesn't look right.<br />
                Please check your details and try again.
              </p>
            </Cell>
          }
          <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
            <div style={{ marginBottom: 32 }}>
              <div className="c-form-control c-form-control--reason">
                <label className="c-form-control__label">
                  Is there anything you would like to tell us about why you made these decisions?
                </label>
                <textarea
                  name="reason"
                  id="reason"
                  data-input-ref="reason"
                  placeholder="Enter Reason"
                  rows="5"
                  onChange={(e) => this.handleInputChange('reason', e.target.value)}
                />
              </div>
            </div>
          </Cell>



          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control">
              <label className="c-form-control__label">Display Name  (Initials Only)</label>
              <input
                type="text"
                placeholder="Enter Name"
                data-input-ref="name"
                defaultValue={name}
                maxLength='2'
                onChange={(e) => this.handleInputChange('name', e.target.value)}
              />
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control c-form-control--no-margin">
              <label className="c-form-control__label">
                Sexual Orientation <span className="u-text-blue">&#42;</span>
              </label>
              <select
                id="sexualorientation"
                name="sexual_orientation"
                data-input-ref="sexual_orientation"
                defaultValue={sexual_orientation}
                className="c-select"
                onChange={(e) => this.handleInputChange('sexual_orientation', e.target.value)}
              >
                <option disabled={true}>Sexual Orientation</option>
                <option>Asexual and/or Aromantic</option>
                <option>Pansexual</option>
                <option>Queer</option>
                <option>Gay</option>
                <option>Bisexual</option>
                <option>Heterosexual</option>
                <option>Lesbian</option>
                <option>Other</option>
                <option>Unknown</option>
                <option>Prefer not to answer</option>
              </select>
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control c-form-control--no-margin">
              <label className="c-form-control__label">
                Gender Identity <span className="u-text-blue">&#42;</span>
              </label>
              <select
                id="genderidentity"
                name="gender"
                data-input-ref="gender"
                defaultValue={gender}
                className="c-select"
                onChange={(e) => this.handleInputChange('gender', e.target.value)}
              >
                <option disabled={true}>Gender Identity</option>
                <option>Female</option>
                <option>Male</option>
                <option>Other</option>
                <option>Intersex</option>
                <option>Third Gender (Gender Queer, Gender Fluid and/or Non-Binary)</option>
                <option>Transgender Female</option>
                <option>Transgender Male</option>
                <option>Agender</option>
                <option>Prefer Not to answer</option>
              </select>
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control c-form-control--no-margin">
              <label className="c-form-control__label">
                Religion <span className="u-text-blue">&#42;</span>
              </label>
              <select
                id="religion"
                name="religion"
                data-input-ref="religion"
                defaultValue={religion}
                className="c-select"
                onChange={(e) => this.handleInputChange('religion', e.target.value)}
              >
                <option disabled={true}>Religion </option>
                <option>Buddhism</option>
                <option>Christianity</option>
                <option>Hinduism</option>
                <option>Islam</option>
                <option>Jainism</option>
                <option>Judaism</option>
                <option>None</option>
                <option>Not stated</option>
                <option>Other</option>
                <option>Bahai</option>
                <option>Prefer not to say</option>
                <option>Sikhism</option>
                <option>Unknown</option>
                <option>Zoroastrianism</option>
              </select>
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control">
              <label className="c-form-control__label c-form-control__label--disability">
                Disability <span className="u-text-blue">&#42;</span>
                <span className="u-error-message u-text-error" style={{ marginLeft: '8px' }}>Do you consider yourself to be a disabled person?</span>
              </label>

              <label className="c-form-control__label c-form-control__label--inline">
                <input
                  type="radio"
                  className="c-form-control__faux-check-input"
                  name="disability"
                  value="y"
                  onChange={(e) => this.handleInputChange('disability', e.target.value)}
                />
                <span className="c-form-control__faux-check">Yes</span>
              </label>

              <label className="c-form-control__label c-form-control__label--inline">
                <input
                  type="radio"
                  className="c-form-control__faux-check-input"
                  name="disability"
                  value="n"
                  onChange={(e) => this.handleInputChange('disability', e.target.value)}
                />
                <span className="c-form-control__faux-check">No</span>
              </label>

              <label className="c-form-control__label c-form-control__label--inline">
                <input
                  type="radio"
                  className="c-form-control__faux-check-input"
                  name="disability"
                  value="p"
                  onChange={(e) => this.handleInputChange('disability', e.target.value)}
                />
                <span className="c-form-control__faux-check">Prefer not to say</span>
              </label>
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control c-form-control--no-margin">
              <label className="c-form-control__label">
                Ethnicity <span className="u-text-blue">&#42;</span>
              </label>
              <select
                id="ethnicity"
                name="ethnicity"
                data-input-ref="ethnicity"
                defaultValue={ethnicity}
                className="c-select"
                onChange={(e) => this.handleInputChange('ethnicity', e.target.value)}
              >
                <option disabled={true}>Ethnicity</option>
                <option>Asian or Asian British - Bangladeshi</option>
                <option>Asian or Asian British - Chinese</option>
                <option>Asian or Asian British - Other</option>
                <option>Asian or Asian British - Pakistani</option>
                <option>Asian or Asian British - Indian</option>
                <option>Black or Black British - African</option>
                <option>Black or Black British - Caribbean</option>
                <option>Black or Black British - Other</option>
                <option>Mixed Ethnic - White and Asian</option>
                <option>Mixed Ethnic - White and Black African</option>
                <option>Mixed Ethnic - White and Black Caribbean</option>
                <option>Mixed Ethnic Group - Other</option>
                <option>Other Ethnic Group - Arab</option>
                <option>White Gypsy or Irish Traveller</option>
                <option>White - Irish</option>
                <option>White - Roma</option>
                <option>White - Other</option>
                <option>White – English/Welsh/Scottish/Northern Irish or British</option>
                <option>Prefer Not to Say</option>
                <option>Other</option>
              </select>
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control c-form-control--no-margin">
              <label className="c-form-control__label">
                Age Range <span className="u-text-blue">&#42;</span>
              </label>
              <select
                id="age-range"
                name="age-range"
                data-input-ref="ageRange"
                defaultValue={ageRange}
                className="c-select"
                onChange={(e) => this.handleInputChange('ageRange', e.target.value)}
              >
                <option disabled={true}>Age Range</option>
                <option>Under 16</option>
                <option>16-25</option>
                <option>26-40</option>
                <option>41-65</option>
                <option>Over 65</option>
              </select>
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <div className="c-form-control c-form-control--no-margin">
              <label className="c-form-control__label">
                Postcode <span className="u-text-blue">&#42;</span>
              </label>
              <input
                type="text"
                placeholder="Enter Postcode e.g. B99 1DX"
                data-input-ref="postcode"
                defaultValue={postcode}
                maxLength="7"
                onChange={(e) => this.handleInputChange('postcode', e.target.value)}
              />
            </div>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }} alignment="bottom">
            <div style={{ maxWidth: "275px", display: this.state.showcustomethnicity }} className="c-form-control">
              <label className="c-form-control__label">Enter Ethnicity</label>
              <input
                type="text"
                placeholder="Enter Ethnicity"
                data-input-ref="customethnicity"
                defaultValue=''
                onChange={(e) => this.handleInputChange('customethnicity', e.target.value)}
              />
            </div>
          </Cell>

          <Cell size={{ desktop: '12', tablet: '12', phone: '12' }} alignment="bottom">
            <div style={{ marginTop: "1.5rem" }} className="c-form-control c-form-control--no-margin">
              <button
                className="c-button c-button--submit"
              >
                Submit Budget
              </button>
            </div>
          </Cell>
        </Grid>
      </form>
    );
  }
}

export default SubmitBudgetForm;
