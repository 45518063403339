import * as types from "./types";

const lsTest = () => {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const showNewsAction = (headline, date, newsObject) => {
  return (dispatch, getState) => {
    let isDuplicate = false;

    if (getState().news.news_alerts.length > 0) {
      getState().news.news_alerts.forEach(alert => {
        if (alert.text === newsObject.message) {
          isDuplicate = true;
        }
      });
    }

    dispatch(_displayNewsAction(headline, date, newsObject));

    if (!isDuplicate) {
      dispatch(_storeNewsAction(headline, date, newsObject));
      dispatch(_cacheNewsAction());
    }
  };
};

const _displayNewsAction = (headline, date, newsObject) => {
  return {
    type: types.SHOW_NEWS,
    payload: {
      headline: headline,
      date: date,
      text: newsObject.message,
      id: newsObject.id
    }
  };
};

const _storeNewsAction = (headline, date, newsObject) => {
  return {
    type: types.STORE_NEWS,
    payload: {
      headline: headline,
      date: date,
      text: newsObject.message,
      id: newsObject.id
    }
  };
};

const _cacheNewsAction = () => {
  return (dispatch, getState) => {
    if (lsTest() === true) {
      localStorage.setItem("news_alerts", JSON.stringify(getState().news.news_alerts));
    }
  };
};

const loadInitialNewsData = () => {
  return (dispatch, getState) => {
    let data;

    if (lsTest() === true) {
      data = localStorage.getItem("news_alerts");

      const parsedData = JSON.parse(data);

      if (parsedData && Object.keys(parsedData).length > 0) {
        dispatch(_loadInitialNewsDataSuccess(parsedData));
      }
    }
  };
};

const _loadInitialNewsDataSuccess = data => {
  return {
    type: types.LOAD_INITIAL_NEWS_DATA_SUCCESS,
    data
  };
};

const showCongratulations = () => {
  return { type: types.SHOW_CONGRATS };
};

const hideCongratulations = () => {
  return { type: types.HIDE_CONGRATS };
};

const hideNewsAction = () => {
  return {
    type: types.HIDE_NEWS
  };
};

const removeNewsAction = id => {
  return {
    type: types.REMOVE_NEWS,
    id
  };
};

export {
  showNewsAction,
  hideNewsAction,
  showCongratulations,
  hideCongratulations,
  loadInitialNewsData,
  removeNewsAction
};
