import * as types from "./types";
import dataf from "../../data";
import config from "../../config";
import {
  postcodeToDistrict,
  postcodeToLocality
} from "../../dataUtils";

const login = (email, password) => {
  return function (dispatch, getState) {
    fetch(`${config.api.prefix}api/login.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.status !== 200) {
          dispatch(loginFailure(data.message));
        } else {
          localStorage.setItem(
            "logged",
            JSON.stringify({
              auth_time: data.data.last_login,
              auth_email: data.data.email,
              auth_token: data.data.access_token
            })
          );
          dispatch(loginSuccess(data.data));
          dispatch(getData());
          setTimeout(() => {
            dispatch(changeFilter());
          }, 1500);
        }
      })
      .catch(err => {
        dispatch(loginFailure(err));
      });
  };
};

const loginSuccess = data => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: data
  };
};

const loginFailure = data => {
  return {
    type: types.LOGIN_FAILURE,
    payload: data
  };
};

const logout = () => {
  localStorage.removeItem("logged");
  return {
    type: types.LOGOUT
  };
};

const clear = () => {
  return {
    type: types.CLEAR
  };
};

const changeFilter = (type = null, key = null, value = null) => {
  console.log(type, key, value);

  return function (dispatch, getState) {
    const d = getState().reports;
    let d2 = JSON.parse(JSON.stringify(d));
    if (type != null && key != null && value != null) {
      d2.filter[type][key].selected = value;
    }

    let raw = JSON.parse(JSON.stringify(d2.fetched));
    let filters = JSON.parse(JSON.stringify(d2.filter));
    let postcodeToLocalitys = postcodeToLocality();
    let postcodeToDistricts = postcodeToDistrict();
    let final = raw.filter(item => {
      console.log("this is item", item);
      // console.log("test", postcodeToDistricts[item.postal_code.toUpperCase()]);
      // console.log("postcode to districts", postcodeToDistricts);
      console.log("postcode.uppercase=", item.postal_code.toUpperCase());
      console.log("postcode to Locality=", postcodeToLocalitys[item.postal_code.toUpperCase()]);
      // console.log("filters key", filters.locality);

      if (
        // AGE
        ((filters.age[0].selected && item.age_range === filters.age[0].name) ||
          (filters.age[1].selected && item.age_range === filters.age[1].name) ||
          (filters.age[2].selected && item.age_range === filters.age[2].name) ||
          (filters.age[3].selected && item.age_range === filters.age[3].name) ||
          (filters.age[4].selected && item.age_range === filters.age[4].name) ||
          (!filters.age[0].selected &&
            !filters.age[1].selected &&
            !filters.age[2].selected &&
            !filters.age[3].selected &&
            !filters.age[4].selected)) &&
        // GENDER
        ((filters.gender[0].selected && item.gender === filters.gender[0].key) ||
          (filters.gender[1].selected && item.gender === filters.gender[1].key) ||
          (filters.gender[2].selected && item.gender === filters.gender[2].key) ||
          (filters.gender[3].selected && item.gender === filters.gender[3].key) ||
          (filters.gender[4].selected && item.gender === filters.gender[4].key) ||
          (filters.gender[5].selected && item.gender === filters.gender[5].key) ||
          (filters.gender[6].selected && item.gender === filters.gender[6].key) ||
          (filters.gender[7].selected && item.gender === filters.gender[7].key) ||
          (filters.gender[8].selected && item.gender === filters.gender[8].key) ||
          (!filters.gender[0].selected &&
            !filters.gender[1].selected &&
            !filters.gender[2].selected &&
            !filters.gender[3].selected &&
            !filters.gender[4].selected &&
            !filters.gender[5].selected &&
            !filters.gender[6].selected &&
            !filters.gender[7].selected &&
            !filters.gender[8].selected)) &&
        // LOCALITY

        ((filters.locality[0].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key === filters.locality[0].key)) ||
          (filters.locality[1].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[1].key)) ||
          (filters.locality[2].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[2].key)) ||
          (filters.locality[3].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[3].key)) ||
          (filters.locality[4].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[4].key)) ||
          (filters.locality[5].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[5].key)) ||
          (filters.locality[6].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[6].key)) ||
          (filters.locality[7].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[7].key)) ||
          (filters.locality[8].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[8].key)) ||
          (filters.locality[9].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[9].key)) ||
          (filters.locality[10].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[10].key)) ||
          (filters.locality[11].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[11].key)) ||
          (filters.locality[12].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[12].key)) ||
          (filters.locality[13].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[13].key)) ||
          (filters.locality[14].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[14].key)) ||
          (filters.locality[15].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[15].key)) ||
          (filters.locality[16].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[16].key)) ||
          (filters.locality[17].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[17].key)) ||
          (filters.locality[18].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[18].key)) ||
          (filters.locality[19].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[19].key)) ||
          (filters.locality[20].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[20].key)) ||
          (filters.locality[21].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[21].key)) ||
          (filters.locality[22].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[22].key)) ||
          (filters.locality[23].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[23].key)) ||
          (filters.locality[24].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[24].key)) ||
          (filters.locality[25].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[25].key)) ||
          (filters.locality[26].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[26].key)) ||
          (filters.locality[27].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[27].key)) ||
          (filters.locality[28].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[28].key)) ||
          (filters.locality[29].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[29].key)) ||
          (filters.locality[30].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[30].key)) ||
          (filters.locality[31].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[31].key)) ||
          (filters.locality[32].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[32].key)) ||
          (filters.locality[33].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[33].key)) ||
          (filters.locality[34].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[34].key)) ||
          (filters.locality[35].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[35].key)) ||
          (filters.locality[36].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[36].key)) ||
          (filters.locality[37].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[37].key)) ||
          (filters.locality[38].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[38].key)) ||
          (filters.locality[39].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[39].key)) ||
          (filters.locality[40].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[40].key)) ||
          (filters.locality[41].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[41].key)) ||
          (filters.locality[42].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[42].key)) ||
          (filters.locality[43].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[43].key)) ||
          (filters.locality[44].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[44].key)) ||
          (filters.locality[45].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[45].key)) ||
          (filters.locality[46].selected &&
            (postcodeToDistricts[item.postal_code.toUpperCase()] &&
              postcodeToLocalitys[item.postal_code.toUpperCase()].key ===
              filters.locality[46].key)) ||
          (!filters.locality[0].selected &&
            !filters.locality[1].selected &&
            !filters.locality[2].selected &&
            !filters.locality[3].selected &&
            !filters.locality[4].selected &&
            !filters.locality[5].selected &&
            !filters.locality[6].selected &&
            !filters.locality[7].selected &&
            !filters.locality[8].selected &&
            !filters.locality[9].selected &&
            !filters.locality[10].selected &&
            !filters.locality[11].selected &&
            !filters.locality[12].selected &&
            !filters.locality[13].selected &&
            !filters.locality[14].selected &&
            !filters.locality[15].selected &&
            !filters.locality[16].selected &&
            !filters.locality[17].selected &&
            !filters.locality[18].selected &&
            !filters.locality[19].selected &&
            !filters.locality[20].selected &&
            !filters.locality[21].selected &&
            !filters.locality[22].selected &&
            !filters.locality[23].selected &&
            !filters.locality[24].selected &&
            !filters.locality[25].selected &&
            !filters.locality[26].selected &&
            !filters.locality[27].selected &&
            !filters.locality[28].selected &&
            !filters.locality[29].selected &&
            !filters.locality[30].selected &&
            !filters.locality[31].selected &&
            !filters.locality[32].selected &&
            !filters.locality[33].selected &&
            !filters.locality[34].selected &&
            !filters.locality[35].selected &&
            !filters.locality[36].selected &&
            !filters.locality[37].selected &&
            !filters.locality[38].selected &&
            !filters.locality[39].selected &&
            !filters.locality[40].selected &&
            !filters.locality[41].selected &&
            !filters.locality[42].selected &&
            !filters.locality[43].selected &&
            !filters.locality[44].selected &&
            !filters.locality[45].selected &&
            !filters.locality[46].selected &&
            // POSTAL
            ((filters.postal[0].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[0].name) ||
              (filters.postal[1].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[1].name) ||
              (filters.postal[2].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[2].name) ||
              (filters.postal[3].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[3].name) ||
              (filters.postal[4].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[4].name) ||
              (filters.postal[5].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[5].name) ||
              (filters.postal[6].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[6].name) ||
              (filters.postal[7].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[7].name) ||
              (filters.postal[8].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[8].name) ||
              (filters.postal[9].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[9].name) ||
              (filters.postal[10].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[10].name) ||
              (filters.postal[11].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[11].name) ||
              (filters.postal[12].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[12].name) ||
              (filters.postal[13].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[13].name) ||
              (filters.postal[14].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[14].name) ||
              (filters.postal[15].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[15].name) ||
              (filters.postal[16].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[16].name) ||
              (filters.postal[17].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[17].name) ||
              (filters.postal[18].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[18].name) ||
              (filters.postal[19].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[19].name) ||
              (filters.postal[20].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[20].name) ||
              (filters.postal[21].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[21].name) ||
              (filters.postal[22].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[22].name) ||
              (filters.postal[23].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[23].name) ||
              (filters.postal[24].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[24].name) ||
              (filters.postal[25].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[25].name) ||
              (filters.postal[26].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[26].name) ||
              (filters.postal[27].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[27].name) ||
              (filters.postal[28].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[28].name) ||
              (filters.postal[29].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[29].name) ||
              (filters.postal[30].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[30].name) ||
              (filters.postal[31].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[31].name) ||
              (filters.postal[32].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[32].name) ||
              (filters.postal[33].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[33].name) ||
              (filters.postal[34].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[34].name) ||
              (filters.postal[35].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[35].name) ||
              (filters.postal[36].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[36].name) ||
              (filters.postal[37].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[37].name) ||
              (filters.postal[38].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[38].name) ||
              (filters.postal[39].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[39].name) ||
              (filters.postal[40].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[40].name) ||
              (filters.postal[41].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[41].name) ||
              (filters.postal[42].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[42].name) ||
              (filters.postal[43].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[43].name) ||
              (filters.postal[44].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[44].name) ||
              (filters.postal[45].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[45].name) ||
              (filters.postal[46].selected &&
                postcodeToDistricts[item.postal_code.toUpperCase()] === filters.postal[46].name) ||
              (!filters.postal[0].selected &&
                !filters.postal[1].selected &&
                !filters.postal[2].selected &&
                !filters.postal[3].selected &&
                !filters.postal[4].selected &&
                !filters.postal[5].selected &&
                !filters.postal[6].selected &&
                !filters.postal[7].selected &&
                !filters.postal[8].selected &&
                !filters.postal[9].selected &&
                !filters.postal[10].selected &&
                !filters.postal[11].selected &&
                !filters.postal[12].selected &&
                !filters.postal[13].selected &&
                !filters.postal[14].selected &&
                !filters.postal[15].selected &&
                !filters.postal[16].selected &&
                !filters.postal[17].selected &&
                !filters.postal[18].selected &&
                !filters.postal[19].selected &&
                !filters.postal[20].selected &&
                !filters.postal[21].selected &&
                !filters.postal[22].selected &&
                !filters.postal[23].selected &&
                !filters.postal[24].selected &&
                !filters.postal[25].selected &&
                !filters.postal[26].selected &&
                !filters.postal[27].selected &&
                !filters.postal[28].selected &&
                !filters.postal[29].selected &&
                !filters.postal[30].selected &&
                !filters.postal[31].selected &&
                !filters.postal[32].selected &&
                !filters.postal[33].selected &&
                !filters.postal[34].selected &&
                !filters.postal[35].selected &&
                !filters.postal[36].selected &&
                !filters.postal[37].selected &&
                !filters.postal[38].selected &&
                !filters.postal[39].selected &&
                !filters.postal[40].selected &&
                !filters.postal[41].selected &&
                !filters.postal[42].selected &&
                !filters.postal[43].selected &&
                !filters.postal[44].selected &&
                !filters.postal[45].selected &&
                !filters.postal[46].selected))))
      ) {
        return item;
      } else {
        return null;
      }
    });

    d2.displayed.spending_areas =
      final.length > 0 ? {
        health_social_care_partnership: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.health_social_care_partnership) ?
              0 :
              Number(elem.health_social_care_partnership))
          );
        }, 0) / final.length || 0,
        secondary_school_education: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.secondary_school_education) ?
              0 :
              Number(elem.secondary_school_education))
          );
        }, 0) / final.length || 0,
        primary_school_education: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.primary_school_education) ? 0 : Number(elem.primary_school_education))
          );
        }, 0) / final.length || 0,
        nursery_early_years: final.reduce((prev, elem) => {
          return (
            prev + (isNaN(elem.nursery_early_years) ? 0 : Number(elem.nursery_early_years))
          );
        }, 0) / final.length || 0,
        additional_support_needs: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.additional_support_needs) ? 0 : Number(elem.additional_support_needs))
          );
        }, 0) / final.length || 0,
        children_social_work: final.reduce((prev, elem) => {
          return (
            prev + (isNaN(elem.children_social_work) ? 0 : Number(elem.children_social_work))
          );
        }, 0) / final.length || 0,
        councillor_pay_expenses: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.councillor_pay_expenses) ? 0 : Number(elem.councillor_pay_expenses))
          );
        }, 0) / final.length || 0,
        community_safety: final.reduce((prev, elem) => {
          return prev + (isNaN(elem.community_safety) ? 0 : Number(elem.community_safety));
        }, 0) / final.length || 0,
        landscape_services: final.reduce((prev, elem) => {
          return (
            prev + (isNaN(elem.landscape_services) ? 0 : Number(elem.landscape_services))
          );
        }, 0) / final.length || 0,
        live_life_aberdeenshire: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.live_life_aberdeenshire) ? 0 : Number(elem.live_life_aberdeenshire))
          );
        }, 0) / final.length || 0,
        office_accommodation: final.reduce((prev, elem) => {
          return (
            prev + (isNaN(elem.office_accommodation) ? 0 : Number(elem.office_accommodation))
          );
        }, 0) / final.length || 0,
        planning_and_development: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.planning_and_development) ? 0 : Number(elem.planning_and_development))
          );
        }, 0) / final.length || 0,
        public_transport: final.reduce((prev, elem) => {
          return prev + (isNaN(elem.public_transport) ? 0 : Number(elem.public_transport));
        }, 0) / final.length || 0,
        roads_bridges_harbours: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.roads_bridges_harbours) ? 0 : Number(elem.roads_bridges_harbours))
          );
        }, 0) / final.length || 0,
        recycling_waste_collection: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.recycling_waste_collection) ?
              0 :
              Number(elem.recycling_waste_collection))
          );
        }, 0) / final.length || 0,
        admin_business_support: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.admin_business_support) ? 0 : Number(elem.admin_business_support))
          );
        }, 0) / final.length || 0,
        capital_repayments_and_costs: final.reduce((prev, elem) => {
          return (
            prev +
            (isNaN(elem.capital_repayments_and_costs) ?
              0 :
              Number(elem.capital_repayments_and_costs))
          );
        }, 0) / final.length || 0
      } : [];

    /**
     * The actual values returned by the calculation to get the mean spending area values
     * differs to what is returned by the API. This is because the calculation is slightly different.
     * Here we are matching the calculation supplied by the API to ensure values are consistent, as per the request
     * of the client.
     */
    const roundedSpendingAreas = Object.keys(d2.displayed.spending_areas).map(area => ({
      area,
      value: Math.ceil(d2.displayed.spending_areas[area] / 100000) * 100000
    }));
    const mappedSpendingAreas = roundedSpendingAreas.map(item => ({
      [item.area]: item.value
    }));
    const updatedSpendingAreas = Object.assign({}, ...mappedSpendingAreas);

    d2.displayed.spending_areas = updatedSpendingAreas;

    d2.displayed.council_1 =
      final.length > 0 ?
      final.reduce((prev, elem) => {
        return prev + (isNaN(elem.council_1) ? 0 : Number(elem.council_1));
      }, 0) / final.length || 0 :
      0;
    d2.displayed.council_2 =
      final.length > 0 ?
      final.reduce((prev, elem) => {
        return prev + (isNaN(elem.council_2) ? 0 : Number(elem.council_2));
      }, 0) / final.length || 0 :
      0;
    d2.displayed.council_3 =
      final.length > 0 ?
      final.reduce((prev, elem) => {
        return prev + (isNaN(elem.council_3) ? 0 : Number(elem.council_3));
      }, 0) / final.length || 0 :
      0;
    d2.displayed.council_4 =
      final.length > 0 ?
      final.reduce((prev, elem) => {
        return prev + (isNaN(elem.council_4) ? 0 : Number(elem.council_4));
      }, 0) / final.length || 0 :
      0;
    d2.displayed.council_5 =
      final.length > 0 ?
      final.reduce((prev, elem) => {
        return prev + (isNaN(elem.council_5) ? 0 : Number(elem.council_5));
      }, 0) / final.length || 0 :
      0;

    d2.displayed.total_spend = 0;
    d2.displayed.low_risk_budgets = 0;
    d2.displayed.high_risk_budgets = 0;

    final.length > 0 ?
      Object.keys(d2.displayed.spending_areas).map((index, elem) => {
        // eslint-disable-line no-unused-expressions
        d2.displayed.total_spend =
          d2.displayed.total_spend + Number(d2.displayed.spending_areas[index]);
        if (dataf.spending_areas[elem]) {
          if (d2.displayed.spending_areas[index] < dataf.spending_areas[elem].max * 0.9) {
            d2.displayed.high_risk_budgets = d2.displayed.high_risk_budgets + 1;
          }

          if (
            d2.displayed.spending_areas[index] < dataf.spending_areas[elem].max &&
            d2.displayed.spending_areas[index] > dataf.spending_areas[elem].max * 0.95
          ) {
            d2.displayed.low_risk_budgets = d2.displayed.low_risk_budgets + 1;
          }
        }
        return true;
      }) :
      null;

    d2.displayed.council_tax =
      final.length > 0 ?
      Math.round(
        final.reduce((prev, elem) => {
          return prev + (isNaN(elem.council_tax) ? 0 : Number(elem.council_tax));
        }, 0) / final.length
      ) :
      0;

    d2.displayed.council_tax_percent =
      final.length > 0 ?
      Math.round(
        (final.reduce((prev, elem) => {
          return prev + (isNaN(elem.council_tax) ? 0 : Number(elem.council_tax));
        }, 0) / final.length || 0) / 580000
      ) :
      0;
    d2.displayed.budgets_submitted = final.length > 0 ? final.length : 0;
    d2.comments = [];

    final.map(item => {
      d2.comments.push(item.budget_reason);
      return true;
    });

    dispatch(changeFilterSuccess(d2));
  };
};

const changeFilterSuccess = data => {
  return {
    type: types.CHANGE_FILTER,
    payload: data
  };
};

const getData = () => {
  return function (dispatch, getState) {
    let email = getState().reports.auth.auth_email;
    let token = getState().reports.auth.auth_token;
    fetch(`${config.api.prefix}api/query.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: JSON.stringify({
          email: email,
          token: token
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.status === 200) {
          dispatch(_GETDATA(data.data));
        } else {
          dispatch(logout());
        }
      })
      .catch(err => {
        // dispatch(_LOGIN_FAILURE(err))
      });
  };
};

const _GETDATA = data => {
  return {
    type: types.QUERY,
    payload: data
  };
};

export {
  login,
  loginSuccess,
  loginFailure,
  logout,
  clear,
  changeFilter,
  changeFilterSuccess,
  getData,
  _GETDATA
};