import pc from "./pc";
const d = JSON.parse(JSON.stringify(pc));

function postcodeList() {
  let list = [];
  d.map(item => {
    // eslint-disable-line array-callback-return
    // list.push(item.postcode)
    list.push(item.postcode_no_space);
  });
  let uniq = [...new Set(list)];
  return uniq;
}

function districtList() {
  let list = [];
  d.map(item => {
    // eslint-disable-line array-callback-return
    list.push(item.district);
  });
  let uniq = [...new Set(list)];
  return uniq;
}

function sectorList() {
  let list = [];
  d.map(item => {
    // eslint-disable-line array-callback-return
    list.push(item.sector);
  });
  let uniq = [...new Set(list)];
  return uniq;
}

function districtToPostcodes() {
  let list = {};
  d.map(item => {
    // eslint-disable-line array-callback-return
    list[item.district] = [];
  });
  let uniq = {};
  d.map(item => {
    // eslint-disable-line array-callback-return
    list[item.district].push(item.postcode);
    list[item.district].push(item.postcode_no_space);
    uniq[item.district] = [...new Set(list[item.district])];
  });
  return uniq;
}

function postcodeToDistrict() {
  let list = {};
  d.map(item => {
    // eslint-disable-line array-callback-return
    list[item.postcode] = item.district;
    list[item.postcode_no_space] = item.district;
  });
  return list;
}

function localityList() {
  let list = {};
  d.map(item => {
    // eslint-disable-line array-callback-return
    list[item.locality_name.replace(/\s/g, "_").toLowerCase()] = item.locality_name;
  });
  return list;
}

function localityToPostcode() {
  let list = {};
  d.map(item => {
    // eslint-disable-line array-callback-return
    list[item.locality_name.replace(/\s/g, "_").toLowerCase()] = {
      space: item.postcode,
      no_space: item.postcode_no_space
    };
  });
  return list;
}

function postcodeToLocality() {
  let list = {};
  d.map(item => {
    // eslint-disable-line array-callback-return
    (list[item.postcode_no_space] = {
      // eslint-disable-line no-unused-expressions
      key: item.locality_name.replace(/\s/g, "_").toLowerCase(),
      value: item.locality_name
    }), // eslint-disable-line no-sequences
    (list[item.postcode] = {
      key: item.locality_name.replace(/\s/g, "_").toLowerCase(),
      value: item.locality_name
    });
  });

  return list;
}

export {
  postcodeList,
  districtList,
  sectorList,
  districtToPostcodes,
  postcodeToDistrict,
  localityList,
  localityToPostcode,
  postcodeToLocality
};