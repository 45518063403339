import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

import { Grid, Cell } from '../../../components/layout';
import { Link } from 'react-router-dom';

const PeoplesBudgetEntries = ({ budgets, rowCount = 3, className, limit = null }) => {

  const getSubmissionTime = (started_at, time_spent) => {
    return moment(started_at).add(time_spent, 'seconds').fromNow();
  }

  return (
    <div className="c-budget-entries">
      <Grid>
        {
          budgets.map((person, index) => {
            if (limit) {
              if (index <= (limit - 1)) {
                return (
                  <Cell size={{ desktop: `${12 / rowCount}`, tablet: `${12 / rowCount}`, phone: '6' }} key={person.id}>
                    <Link to={`/peoples-budget/${person.id}`}>
                      <div className={classnames('c-budget-entry', className)}>
                        <Grid>
                          <Cell size={{ desktop: '2', tablet: '2', phone: '1' }}>
                            <div className="c-avatar c-avatar--generic">
                              <i className="fas fa-user" />
                            </div>
                          </Cell>
                          <Cell size={{ desktop: '10', tablet: '10', phone: '5' }}>
                            <p className="c-budget-entry__name">{person.first_name}</p>
                            <p className="c-budget-entry__time">Submitted a budget {getSubmissionTime(person.started_at, person.time_spent)}</p>
                          </Cell>
                        </Grid>
                      </div>
                    </Link>
                    {
                      (index % 2 === 0 && index < limit - 2) && <hr className="c-budget-entry-divider" />
                    }
                  </Cell>
                )
              }
            } else {
              return (
                <Cell size={{ desktop: `${12 / rowCount}`, tablet: `${12 / rowCount}`, phone: '6' }} key={person.id}>
                  <Link to={`/peoples-budget/${person.id}`}>
                    <div className={classnames('c-budget-entry', className)}>
                      <Grid>
                        <Cell size={{ desktop: '3', tablet: '3', phone: '1' }}>
                          <div className="c-avatar c-avatar--generic">
                            <i className="fas fa-user" />
                          </div>
                        </Cell>
                        <Cell size={{ desktop: '9', tablet: '9', phone: '5' }}>
                          <p className="c-budget-entry__name">{person.first_name}</p>
                          <p className="c-budget-entry__time">Submitted a budget {getSubmissionTime(person.started_at, person.time_spent)}</p>
                        </Cell>
                      </Grid>
                    </div>
                  </Link>
                </Cell>
              )
            }
          })
        }
      </Grid>
    </div>
  )
}

export default PeoplesBudgetEntries;
