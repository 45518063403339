import React from 'react';
import { connect } from 'react-redux';

import { Cell, Grid } from '../../layout';

const NewsItemSummary = ({
  _news,
}) => {
  return (
    <Grid>
      {
        _news.news_alerts.map((alert, index) =>
          <Cell size={{ desktop: '4', tablet: '6', phone: '6' }} key={index}>
            <Grid>
              <Cell size={{ desktop: '10', tablet: '8', phone: '5' }}>
                <p>{alert.text}</p>
              </Cell>
            </Grid>
          </Cell>
        )
      }
    </Grid>
  )
}

export default connect((state, ownProps) => {
  return {
    _news: state.news
  }
})(NewsItemSummary)

