import React, { Component } from "react";
import classnames from 'classnames';

import Modal from '../../modal/Modal';

import { smallNumber } from '../../../../helpers/number';

class ServiceAreaCard extends Component {
  state = {
    barColor: 1,
    flipped: false,
    selected: false,
    isModalOpen: false,
  }

  componentDidMount() {
    this.setState({ selected: this.props.status });
  }

  handleSelect = (event) => {
    this.setState({
      selected: !this.state.selected,
    });

    this.props.onChange(event.target.checked);
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  render() {
    const label = this.props.label || 'Income';

    return (
      <div
        id={this.props.id}
        key={this.props.k}
        className={
          classnames('c-card c-card--service-area', {
            'c-card--investment': this.props.isInvestment,
          })
        }>
        <div>
          {
            !this.props.hideInfo &&
            <span className="c-card__icon-wrap" onClick={() => this.toggleModal()}>
              <i className="fas fa-info-circle" />
            </span>
          }

          <h6 className="c-card__title">{this.props.name}</h6>
          <p className="c-card__value-title">{label}</p>
          <p className="c-card__value">£{smallNumber(this.props.value)}</p>

          {
            !this.props.isSummary &&
            <div className="c-card__toggle">
              <label className="c-card__toggle-label">
                {this.state.selected && <i className={classnames("fas fa-check c-card__toggle-tick", {
                  'c-card__toggle-tick--white': this.props.isInvestment
                })} />}

                <span
                  className={
                    classnames('c-card__toggle-selector', {
                      'c-card__toggle-selector--inactive': !this.state.selected,
                      'c-card__toggle-selector--active': this.state.selected,
                    })
                  }>
                  {this.state.selected ? `Remove ${label}` : `Add ${label}`}
                </span>

                <input
                  type="checkbox"
                  className="c-card__toggle-input"
                  checked={this.state.selected}
                  onChange={(event) => this.handleSelect(event)}
                />
              </label>
            </div>
          }

          {
            this.props.isSummaryAverage &&
            <p style={{
              position: 'absolute',
              right: 10,
              bottom: 5
            }}>
              {Number(Number(this.props.averageStatus).toFixed(2) * 100).toFixed(0)}% increased this
              </p>
          }

          {
            !this.props.isSummaryAverage && (this.props.isSummary && this.props.status) &&
            <div className="c-card__toggle">
              <label className="c-card__toggle-label">
                {this.state.selected && <i className={classnames("fas fa-check c-card__toggle-tick c-card__toggle-tick--summary", { 'c-card__toggle-tick--white': this.props.isInvestment })} />}
              </label>
            </div>
          }
        </div>

        {
          this.state.isModalOpen &&
          <Modal handleClose={() => this.toggleModal()}>
            <i className="c-modal__budget-icon fas fa-info" />
            <h2 className="c-modal__title">{this.props.name}</h2>
            <p dangerouslySetInnerHTML={{ __html: this.props.info }} />
          </Modal>
        }
      </div>
    )
  }
}

export default ServiceAreaCard;
