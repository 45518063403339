import React from 'react';

import Button from '../button/Button';
import Modal from './Modal';
import budget_logo from '../../../assets/images/birmingham_mobile_logo.jpg';

class BudgetSuccessModal extends React.Component {
  focusSubmissionForm = () => {
    window.scroll(0, document.getElementById('send-budget-form').offsetTop);
    this.props.handleClose();
  };

  render() {
    const { handleClose, handleSubmit, showActions } = this.props;

    return (
      <Modal>
        {
          showActions &&
          <div>
            <i className="c-modal__budget-icon fas fa-check" />
            <h2 className="c-modal__title">Thank You</h2>
            <p>
              Thank you for submitting your budget to help us create a balanced
              budget for the city. Share your budget with friends
              and family on social media
              </p>
            <div className="c-button-group">
              <Button onClick={handleSubmit}>
                <i className="fas fa-share-alt" /> Share
                </Button>
            </div>
          </div>
        }

        {
          !showActions &&
          <div>
            <img src={budget_logo} alt="Budget Challenge Icon" width="75" className="u-margin-bottom@1" />
            <h2 className="c-modal__title">Thank you for taking the time to share your views</h2>
            <p className="c-modal__sub-title">You have balanced the budget</p>

            <p>
              Please click submit and tell us 'why did you set this budget'.
              Or click continue balancing your budget.
              </p>

            <div className="c-button-group c-button-group--success-modal">

              <Button onClick={this.focusSubmissionForm}>Submit Your Budget</Button>
              <Button onClick={handleClose}>Continue Balancing Your Budget</Button>
            </div>
          </div>
        }
      </Modal>
    )
  }
};

export default BudgetSuccessModal;
