import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Container = ({ children, isOverflow, className }) => (
  <div className={classnames('l-container', className, { 'l-container--overflow': isOverflow })}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default Container;
