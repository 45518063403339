import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import phone from "../../assets/images/phone.png";
import budget_logo from "../../assets/images/birmingham_mobile_logo.jpg";
import Button from "../../components/common/button/Button";
import Modal from "../../components/common/modal/Modal";

class WelcomeIntro extends Component {
  state = {
    showVideoModal: false
  };

  toggleVideoModal() {
    this.setState({
      showVideoModal: !this.state.showVideoModal
    });
  }

  render() {
    return (
      <div>
        <div className="welcome-intro">
          <div className="welcome-intro-wrapper">
            <div className="big-blue-circle">
              <h2>
                Budget
                <br />
                <span className="light">Challenge</span>
              </h2>
              <p>How will you balance Birmingham City Council's budget?</p>
            </div>
            <div
              className="circle-link video-link-circle"
              onClick={() => {
                this.toggleVideoModal();
              }}
            >
              <span>
                Watch the video
                <br />
                <i className="fab fa-youtube video-link-circle__icon" />
              </span>
            </div>
            {moment().isAfter("2021-12-31") && (
              <div
                className="circle-link take-challenge-circle"
                onClick={() => this.props.toggleDeadlineModal()}
              >
                <span>Take the Budget Challenge</span>
                <i className="fas fa-chevron-right take-challenge-circle__icon" />
              </div>
            )}

            {!moment().isAfter("2021-12-31") && (
              <Link to="/set-your-budget" className="circle-link take-challenge-circle">
                <span>Take the Budget Challenge</span>
                <i className="fas fa-chevron-right take-challenge-circle__icon" />
              </Link>
            )}

            <a className="more-info-btn" href="#more-info-wrap">
              How to use
            </a>
            <div className="phone-image">
              <img src={phone} alt="Phone" />
            </div>
          </div>
        </div>

        <div className="welcome-intro--small">
          <div className="u-text-center">
            <img
              src={budget_logo}
              alt="Budget Challenge"
              style={{ marginBottom: "16px", width: "80px" }}
            />
            <h2 className="c-header__budget-title">
              Budget <span className="u-text-light-grey">Challenge</span>
            </h2>
            <p>
              How would you allocate £812 million across all of our services? You can also generate income from services that we provide, and you may choose to invest more in areas that are important to you.
            </p>
            <div className="c-button-group">
              {moment().isAfter("2021-12-31") && (
                <Button onClick={() => this.props.toggleDeadlineModal()}>
                  Take the Budget Challenge
                </Button>
              )}
              {!moment().isAfter("2021-12-31") && (
                <Button onClick={this.handleClick}>
                  <Link to="/set-your-budget" className="mobile-link">Take the Budget Challenge</Link>
                </Button>
              )}
              <Button
                onClick={() => {
                  this.toggleVideoModal();
                }}
                inverted={true}
              >
                <i className="fab fa-youtube u-margin-right" />
                Budget Dilemma Video
              </Button>
            </div>
          </div>
        </div>

        {
          this.state.showVideoModal && (
            <Modal handleClose={() => this.toggleVideoModal()}>
              <div className="c-modal__video-wrap">
                <iframe
                  width="560"
                  height="315"
                  src="https://player.vimeo.com/video/642670384?h=bb8eda37c3"
                  frameborder="0"
                  title="About the Budget Challenge"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Modal>
          )
        }
      </div >
    );
  }
}

export default WelcomeIntro;
