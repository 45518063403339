import { push } from 'connected-react-router';

import * as types from './types'

export const toggleMenuAction = (path = null) => {
  return dispatch => {
    dispatch(_toggleMenuState())

    if (path) {
      dispatch(push(path))
    }
  }
}

const _toggleMenuState = () => {
  return {
    type: types.TOGGLE_MENU,
  }
}

export const anchorToAllBudgets = () => {
  return dispatch => {
    dispatch(push('/peoples-budget#peoples-entries'));
  }
}
