import React from 'react';

import Generic from '../../layouts/Generic';
import { Grid, Cell } from '../../components/layout';

const Accessibility = () => {
  return (
    <Generic>
      <div className="l-section l-section--pt-0">
        <Grid>
          <Cell size={{ desktop: '9', tablet: '9', phone: '6' }}>
            <h1>Accessibility</h1>
            <p>Birmingham City Council is committed to making its budget tool accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</p>
            <p>The budget tool is designed to be used by all new browsers and devices. This offers a variety of ways that the budget tool can be accessed.</p>
            <p>Accessibility is a general term used to describe the degree to which a product, device, service, or environment is available to as many people as possible. Accessibility can be viewed as the "ability to access" and benefit from some system or entity. Accessibility is often used to focus on people with disabilities or special needs and their right of access to entities, often through use of assistive technology.</p>
            <p>Accessibility is not to be confused with usability which is used to describe the extent to which a product (e.g., device, service, environment) can be used by specified users to achieve specified goals with effectiveness, efficiency and satisfaction in a specified context of use.</p>
            <p>Accessibility is strongly related to universal design when the approach involves "direct access". This is about making things accessible to all people (whether they have a disability or not). An alternative is to provide "indirect access" by having the entity support the use of a person's assistive technology to achieve access (e.g., screen readers).</p>
            <p><strong>This site is currently in beta (test phase)</strong> and through the beta phase we will be enhancing accessibility to level WCAG 2.1 AA</p>
          </Cell>
        </Grid>
      </div>
    </Generic>
  )
}

export default Accessibility;
