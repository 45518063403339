import * as types from './types'

const reducer = (state = { menuActive: false }, action) => {
  switch (action.type) {
    case types.TOGGLE_MENU:
      return {
        ...state,
        menuActive: !state.menuActive,
      }
    default:
      return state
  }
}
export default reducer
