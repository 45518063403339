import React from 'react';
import PropTypes from 'prop-types';

const Jumbo = ({ children }) => (
  <div className="c-jumbo">
    <div className="c-jumbo__inner">
      { children }
    </div>
  </div>
);

Jumbo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default Jumbo;
