import React from 'react';
import classnames from 'classnames';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * Secondary Layout
 * This layout is used on the budget calculator page.
 */

import Header from '../components/common/header/Header';
import TopBar from '../components/common/top-bar/TopBar';
import Countdown from '../components/common/countdown/Countdown';
import Footer from '../components/common/footer/Footer';

import Sidebar from '../components/sidebar/Sidebar';
import { Cell, Container, Grid } from '../components/layout';
import {
  pageActions,
} from '../reducks';

import logo from '../assets/images/logo.png';

export const Secondary = ({
  children,
  toggleMenuAction,
  page,
}) => (
    <div className={classnames('l-layout l-layout--primary', { 'l-layout--menu-active': page.menuActive })}>
      <TopBar className="c-top-bar--secondary">
        <Container>
          <Countdown className="c-countdown--secondary" date="2021-12-31T23:59:59" />
        </Container>
      </TopBar>

      <Header>
        <Grid>
          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <Link to="/" className="c-header__logo-wrap">
              <img className="c-header__logo" src={logo} alt="Birmingham City Council Logo" />
            </Link>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '5', phone: '6' }}>
            <Link to="/">
              <div className="u-text-center c-header__budget-challenge-wrap">
                <h2 className="c-header__budget-title c-header__budget-title--large">Budget <span className='light'>Challenge</span></h2>
              </div>
            </Link>
          </Cell>
        </Grid>

        <div className="c-header__menu-trigger" onClick={() => toggleMenuAction()}>
          <div className={classnames('c-header__hamburger', { 'is-active': page.menuActive })}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </Header>

      <Container className="l-container--bordered" isOverflow={true}>
        {children}
      </Container>

      <Sidebar />

      <Footer />
    </div>
  );

export default connect((state, ownProps) => {
  return {
    page: state.page
  }
}, (dispatch, ownProps) => {
  return {
    toggleMenuAction: () => dispatch(pageActions.toggleMenuAction())
  }
})(Secondary)
