import React, { Component } from 'react';
import { connect } from 'react-redux';

import SpendingAreaCard from '../../components/common/cards/spending-area-card/SpendingAreaCard';
import IncomeAreaSectionSubmitted from '../../components/sections/income-areas-section/IncomeAreasSectionSubmitted';
import Divide from '../../components/common/divide/Divide';
import Button from '../../components/common/button/Button';

import { Cell, Grid } from '../../components/layout';

import Secondary from '../../layouts/Secondary';

class CouncilBudget extends Component {
  state = {
    isCouncilInfoModalActive: false,
    spendingAreaInfoBlocks: [],
  }

  get peoplesTotalAmountNow() {
    const { _budget } = this.props;

    return _budget.spending_areas.reduce((prev, elem) => {
      return prev + Number(_budget.peoples_averages[elem.slug])
    }, 0) || 0;
  }

  get councilTaxFigure() {
    const { _budget } = this.props;

    return Number(_budget.peoples_averages.council_tax);
  }

  get peopleTotal() {
    const {
      _budget: {
        initial_total,
        council,
        peoples_averages,
      }
    } = this.props;

    const keys = [1, 2, 3, 4];
    const averages = keys.map(key => peoples_averages[`council_${key}`]);
    const values = keys.map(key => Number(council[`council_${key}_val`]));

    let councilTotal = 0;

    averages.forEach((average, index) => {
      const value = average >= 0.5 ? values[index] : 0;
      councilTotal += value;
    });

    const sum = (initial_total - this.peoplesTotalAmountNow) + (this.councilTaxFigure + councilTotal);

    return sum;
  }

  get spendingAreas() {
    return this.props._budget.spending_areas;
  }

  componentDidMount() {
    /**
     * If the user navigates to this page from the homepage via the
     * view all budgets link then we anchor them to the relevant section.
     */
    const { hash } = this.props.location;
    const selector = hash.split('#').join("");

    if (hash === '') {
      window.scroll(0, 0);
    } else if (document.getElementById(selector)) {
      window.scroll(0, document.getElementById(selector).offsetTop);
    }

    let spendingAreasInfoContent = this.spendingAreas.map(item => {
      return {
        ...item,
        isInfoCardActive: false,
        isImpactsCardActive: false,
        active: false,
      }
    });

    this.setState({
      spendingAreaInfoBlocks: spendingAreasInfoContent,
    });
  }

  getPotentialImpacts = (cons, value, max) => {
    if (cons && cons.length > 0) {
      cons = cons.filter((item) => {
        const total = max - value;
        return item.to <= total || total >= item.from;
      });
    }
    return cons;
  }

  calculatePeopleTotal = () => {
    const {
      _budget: {
        peoples_averages: {
          council_tax,
        }
      }
    } = this.props;

    return council_tax ? this.peopleTotal : 0
  }

  toggleCouncilModal = () => {
    this.setState({
      isCouncilInfoModalActive: !this.state.isCouncilInfoModalActive,
    });
  }

  toggleInfoCard = (slug, cardType) => {
    const index = this.state.spendingAreaInfoBlocks.findIndex(item => item.slug === slug);
    const blocks = [...this.state.spendingAreaInfoBlocks];

    if (cardType === 'info') {
      blocks[index].isInfoCardActive = !blocks[index].isInfoCardActive;
    } else if (cardType === 'impacts') {
      blocks[index].isImpactsCardActive = !blocks[index].isImpactsCardActive;
    }

    if (blocks[index].isInfoCardActive || blocks[index].isImpactsCardActive) {
      window.scroll(0, document.getElementById('service-areas-section').offsetTop - 50);
    }

    this.setState({
      spendingAreaInfoBlocks: blocks,
    });
  }

  render() {
    const {
      _budget
    } = this.props;

    const {
      spendingAreaInfoBlocks
    } = this.state;

    return (
      <Secondary>
        <div className="l-section l-section--pt-0">
          <Grid>
            <Cell size={{ desktop: '9', tablet: '9', phone: '6' }}>
              <h1>The Council's Budget</h1>
              <p>
                Culpa eu aute nostrud qui. Duis Lorem reprehenderit do do velit elit incididunt
                nostrud magna ad nulla culpa aliquip eu. Culpa eu aute nostrud qui. Duis Lorem reprehenderit do do velit elit incididunt
                nostrud magna ad nulla culpa aliquip eu.
              </p>
            </Cell>
          </Grid>
        </div>

        <div>
          <IncomeAreaSectionSubmitted
            budget={_budget.peoples_averages}
            councilBudget={_budget.council}
            isCouncilBudget={true}
            selectedBudget={_budget.selected_budget}
            isSummary={true}
          />
        </div>

        <section className="l-section l-section--bordered-x-y" id="service-areas-section">
          <div className="l-section__header">
            <Grid>
              <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
                <h2 className="l-section__title">Service Areas</h2>
                <p className="l-section__sub-title">
                  Help meet your target by creating savings in key areas
                </p>
              </Cell>
            </Grid>
          </div>

          <div style={{ position: 'relative' }}>
            <Grid>
              {
                _budget && _budget.spending_areas.map((area, key) => {
                  return (
                    <Cell size={{ desktop: '4', tablet: '4', phone: '6' }} key={key}>
                      <SpendingAreaCard
                        key={key}
                        color={area.color}
                        name={area.name}
                        value={area.max}
                        max={area.max}
                        info={area.info}
                        icon={area.icon}
                        isSummary={true}
                        step={area.step}
                        slug={area.slug}
                        className="c-card--council"
                        toggleInfo={this.toggleInfoCard}
                      />
                      {
                        (spendingAreaInfoBlocks[key] && spendingAreaInfoBlocks[key].isInfoCardActive) &&
                        <div className="c-info-block">
                          <div className="c-info-block__header">
                            <i className="c-info-block__close fa fa-times" onClick={() => this.toggleInfoCard(area.slug, 'info')} />
                            <h2 className="c-info-block__title">{area.name} - <i className='fa fa-info-circle' /> Information</h2>
                          </div>

                          <div className="c-info-block__content">
                            <p>{area.info}</p>
                          </div>
                        </div>
                      }
                    </Cell>
                  )
                })
              }
            </Grid>
          </div>

          <Grid>
            <Cell size={{ desktop: '12', tablet: '12', phone: '6' }} alignment="middle">
              <Divide isLight={true} isLarge={true} />

              <div className="c-button-group c-button-group--spaced-top c-button-group--center">
                <Button href="/set-your-budget">Take The Budget Challenge</Button>
                <Button href="/peoples-budget">View People's Budget</Button>
              </div>
            </Cell>
          </Grid>
        </section>
      </Secondary>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    _budget: state.budget
  }
})(CouncilBudget)
