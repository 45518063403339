import React from 'react';

import Generic from '../../layouts/Generic';
import { Grid, Cell } from '../../components/layout';

const PrivacyPolicy = () => {
  return (
    <Generic>
      <div className="l-section l-section--pt-0">
        <Grid>
          <Cell size={{ desktop: '9', tablet: '9', phone: '6' }}>
            <h1>More Information</h1>
            <p><b>Consequat minim id voluptate ad irure velit eu aute.</b></p>
            <p>Ut commodo elit tempor consequat sunt aliquip adipisicing. Officia ut consectetur amet ullamco excepteur eiusmod fugiat laboris irure eiusmod exercitation magna amet. Dolore eiusmod enim occaecat ea dolor aliqua reprehenderit non magna laboris tempor. Officia ullamco reprehenderit duis sunt ex et. Ullamco dolor excepteur non officia ad aute. Esse occaecat aliqua deserunt deserunt ullamco fugiat in culpa qui ea eu officia ut occaecat. Laboris esse consequat excepteur eiusmod culpa esse ipsum minim quis dolor.</p>
            <p>In anim ex laborum deserunt proident sit reprehenderit anim exercitation ut aute laboris anim. Duis fugiat ipsum Lorem nulla qui incididunt cillum nostrud. Ullamco nostrud consectetur reprehenderit aliqua amet non laboris mollit aute. Est nisi fugiat non pariatur. Laborum cupidatat Lorem aliquip ullamco. Pariatur cillum consectetur ex et officia anim.</p>
            <p>Do eu aliquip fugiat laboris sint voluptate tempor duis laboris magna culpa labore laborum. Ipsum qui dolore ex ex commodo. Consectetur laborum anim dolor aliquip do fugiat do exercitation. Laborum adipisicing cillum duis irure sit non occaecat anim id. Tempor officia magna dolor enim eu Lorem adipisicing ea incididunt adipisicing aute. Commodo aute qui sit adipisicing ullamco nulla labore aute ut est.</p>
            <p>Officia pariatur aliqua nostrud qui quis ea. Cupidatat nostrud voluptate ex commodo exercitation commodo deserunt fugiat irure laborum. In eu deserunt anim voluptate esse est exercitation dolor occaecat quis. Amet elit consectetur in magna sint in ullamco enim non mollit.</p>
          </Cell>
        </Grid>
      </div>
    </Generic>
  )
}

export default PrivacyPolicy;
