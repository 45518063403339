import * as types from './types'

let obj = {}
const reducer = (state = {
  initial_total: 0,
  current_total: 0,
  selected_budget: null,
  spending_areas: [],
  council: {},
  peoples_budgets: [],
  peoples_averages: {},
  peoples_total: 0,
  showBudgetSuccessModal: false,
  showBudgetFailureModal: false,
  showReferendumModal: false,
  tooltipActive: false,
  preventLoadMoreBudgets: false,
  shouldShowThankYouModal: false,
}, action) => {
  switch (action.type) {
    case types.SHOW_BUDGET_SUCCESS_MODAL:
      return Object.assign({}, state, {
        showBudgetSuccessModal: true,
        showButton: action.showButton,
      });

    case types.HIDE_BUDGET_SUCCESS_MODAL:
      return Object.assign({}, state, { showBudgetSuccessModal: false });

    case types.SHOW_BUDGET_FAILURE_MODAL:
      return Object.assign({}, state, { showBudgetFailureModal: true });

    case types.HIDE_BUDGET_FAILURE_MODAL:
      return Object.assign({}, state, { showBudgetFailureModal: false });

    case types.TOGGLE_REFERENDUM_MODAL:
      return Object.assign({}, state, { showReferendumModal: !state.showReferendumModal });

    case types.TOGGLE_RANGE_TOOLTIP:
      return Object.assign({}, state, { tooltipActive: true });

    case types.SET_THANK_YOU_MODAL:
      return {
        ...state,
        shouldShowThankYouModal: action.shouldShowThankYouModal,
      }

    case types.LOAD_INITIAL_DATA:
      return state
    case types.CHANGE_SPENDING_AREA:
      return state
    case types.SAVE:
      return state
    case types.CHANGE_COUNCIL_CATEGORY:
      return state
    case types.CHANGE_COUNCIL_BUDGET:
      return state
    case types.START_AGAIN:
      return state
    case types.LOAD_PEOPLE:
      return state
    case types.LOAD_INITIAL_DATA_SUCCESS:
      obj = Object.assign({}, state, action.payload)
      obj.current_total = action.payload.spending_areas.reduce((prev, elem) => { return prev + (isNaN(elem.value) ? 0 : elem.value) }, 0)
      obj.initial_total = action.payload.spending_areas.reduce((prev, elem) => { return prev + Number(elem.min) }, 0)

      obj.current_total = obj.current_total - obj.council.value_tax;

      return obj

    case types.CHANGE_SPENDING_AREA_SUCCESS:
      obj = JSON.parse(JSON.stringify(state))
      obj.spending_areas[action.payload.key].value = Number(action.payload.value)
      obj.current_total = obj.spending_areas.reduce((prev, elem) => { return prev + elem.value }, 0)

      //
      obj.current_total = obj.current_total - obj.council.value_total;

      return obj

    case types.CHANGE_COUNCIL_CATEGORY_SUCCESS:
      obj = JSON.parse(JSON.stringify(state))
      obj.council[action.payload.category] = action.payload.value

      // There is probably a much neater way of doing this but due to time constraints I've
      // just mashed together what we originally had. This code basically reduces the added value
      // from the savings target if the deduction flag is true. The deduction flag would be true for
      // an investment card. Otherwise we resort to the default behaviour.

      if (action.payload.deduction) {
        if (state.council[action.payload.category] && !action.payload.value) {
          obj.council.value_total = obj.council.value_total + state.council[action.payload.category + '_val']
          obj.current_total = obj.current_total - state.council[action.payload.category + '_val'];
        } else {
          obj.council.value_total = obj.council.value_total - state.council[action.payload.category + '_val']
          obj.current_total = obj.current_total + state.council[action.payload.category + '_val'];
        }
      } else {
        if (!state.council[action.payload.category] && action.payload.value) {
          obj.council.value_total = obj.council.value_total + state.council[action.payload.category + '_val']
          obj.current_total = obj.current_total - state.council[action.payload.category + '_val'];
        }

        if (state.council[action.payload.category] && !action.payload.value) {
          obj.council.value_total = obj.council.value_total - state.council[action.payload.category + '_val']
          obj.current_total = obj.current_total + state.council[action.payload.category + '_val'];
        }
      }

      return obj;

    case types.CHANGE_COUNCIL_BUDGET_SUCCESS:
      obj = JSON.parse(JSON.stringify(state))
      obj.council.value_tax = Number(action.payload)
      obj.council.value_total = ((Number(state.council.value_total) - Number(state.council.value_tax)) + Number(action.payload))

      obj.current_total = ((obj.current_total - action.payload) + state.council.value_tax);

      return obj

    case types.SAVE_SUCCESS:
      return Object.assign({}, state, { saved_id: action.payload.data.message, reason: action.payload.reason, peoples_averages: {}, peoples_budgets: [] })
    case types.START_AGAIN_SUCCESS:
      obj = Object.assign({}, state, action.payload)
      obj.current_total = action.payload.spending_areas.reduce((prev, elem) => { return prev + elem.max }, 0)
      obj.initial_total = action.payload.spending_areas.reduce((prev, elem) => { return prev + elem.max }, 0)
      return obj

    case types.LOAD_PEOPLE_SUCCESS:
      obj = JSON.parse(JSON.stringify(state))
      obj.peoples_budgets = obj.peoples_budgets.concat(action.payload.budgets)
      if (action.payload.averages !== undefined) { obj.peoples_averages = action.payload.averages }
      if (action.payload.averages !== undefined) { obj.peoples_total = Object.keys(obj.peoples_averages).reduce((prev, key) => { return prev + Number(obj.peoples_averages[key]) }, 0) }
      return obj


    case types.LOAD_INITIAL_DATA_FAILURE:
      return state

    case types.CHANGE_SPENDING_AREA_FAILURE:
      return state

    case types.SAVE_FAILURE:
      return state

    case types.CHANGE_COUNCIL_CATEGORY_FAILURE:
      return state

    case types.CHANGE_COUNCIL_BUDGET_FAILURE:
      return state

    case types.LOAD_INDIVIDUAL_SUCCESS:
      return Object.assign({}, state, { selected_budget: action.payload.data })

    case types.CLEAR_INDIVIDUAL:
      return Object.assign({}, state, { selected_budget: null })

    case types.START_AGAIN_FAILURE:
      return state

    case types.LOAD_PEOPLE_FAILURE:
      return state

    case types.PREVENT_LOAD_MORE_BUDGETS:
      return {
        ...state,
        preventLoadMoreBudgets: action.payload,
      }

    case types.LOG_USER_STARTING_ACTION:
      return {
        ...state,
        hasUserStarted: action.payload,
      }

    default:
      return state
  }
}
export default reducer
