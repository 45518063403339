import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import Secondary from "../../layouts/Secondary";

import Divide from "../../components/common/divide/Divide";
import SpendingAreaCard from "../../components/common/cards/spending-area-card/SpendingAreaCard";
import Notification from "../../components/common/notification/Notification";
import IncomeAreasSection from "../../components/sections/income-areas-section/IncomeAreasSection";
import BudgetSuccessModal from "../../components/common/modal/BudgetSuccessModal";
import BudgetFailureModal from "../../components/common/modal/BudgetFailureModal";
import BudgetTotalBar from "../../components/budget-total-bar/BudgetTotalBar";
import SubmitBudgetForm from "../../components/forms/SubmitBudgetForm";
import RangeInput from "../../components/common/range/RangeInput";
import NewsItemSummary from "../../components/sections/news-item-summary/NewsItemSummary";

import { Cell, Grid } from "../../components/layout";

import { budgetActions, newsActions } from "../../reducks";

import { smallNumber, calculateTotalByKey, smallBudgetNumber } from "../../helpers/number";
import Modal from "../../components/common/modal/Modal";
import { getConsequences } from "../../helpers/impacts";

class SetYourBudget extends Component {
  state = {
    barColor: this.props._budget.council.value_tax / this.props._budget.council.max_tax,
    fixed: false,
    spendingAreaInfoBlocks: [],
    capitalTriggered: false,
    isCouncilInfoModalActive: false,
    giveUpReason: "",
    startTime: 0
  };

  get spendingAreas() {
    return this.props._budget.spending_areas;
  }

  getPotentialImpacts = (cons, value, max) => {
    if (cons && cons.length > 0) {
      cons = cons.filter(item => {
        return item.to <= item.from ? value <= item.to : item.to >= item.from && value >= item.from;
      });
    }

    return cons;
  };

  componentDidMount() {
    window.scroll(0, 0);

    window.addEventListener("scroll", () => {
      const scrollAmount = document.documentElement.scrollTop || document.body.scrollTop;

      // Set to 7072 is equal to document.getElementById('submit-form-section').offsetTop, hard coded as the offset changes when the bar disappears.
      if (this.fixedSection) {
        if (scrollAmount > this.fixedSection.offsetTop && scrollAmount <= 7072) {
          if (!this.state.fixed) {
            this.setState({ fixed: true });
          }
        } else {
          if (this.state.fixed) {
            this.setState({ fixed: false });
          }
        }
      }
    });

    // Call the started action
    this.props.logUserStartingAction();

    let spendingAreasInfoContent = this.spendingAreas.map(item => {
      return {
        ...item,
        isInfoCardActive: false,
        isImpactsCardActive: false,
        active: false
      };
    });

    const timeWithMilliseconds = new Date().getTime();
    const timeWithoutMilliseconds = Math.floor(timeWithMilliseconds / 1000);

    this.setState({
      spendingAreaInfoBlocks: spendingAreasInfoContent,
      startTime: timeWithoutMilliseconds
    });
  }

  componentDidUpdate(nextProps, prevProps) {
    const totalReduced = this.props._budget.current_total;
    if (nextProps._budget.current_total !== this.props._budget.current_total) {
      // console.log("totalReduced=" + totalReduced);
      // must include .0 in the value in this condition
      if (totalReduced == "812000000") {
        if (navigator.userAgent.indexOf("Edge") >= 0) {
          return;
        } else {
          const showButton = false;
          this.props.showBudgetSuccessModalAction(showButton);
        }
      }
    }
  }

  toggleInfoCard = (slug, cardType) => {
    const index = this.state.spendingAreaInfoBlocks.findIndex(item => item.slug === slug);
    const blocks = [...this.state.spendingAreaInfoBlocks];

    if (cardType === "info") {
      blocks[index].isInfoCardActive = !blocks[index].isInfoCardActive;
    } else if (cardType === "impacts") {
      blocks[index].isImpactsCardActive = !blocks[index].isImpactsCardActive;
    }

    if (blocks[index].isInfoCardActive || blocks[index].isImpactsCardActive) {
      window.scroll(0, document.getElementById("service-areas-section").offsetTop - 50);
    }

    this.setState({
      spendingAreaInfoBlocks: blocks
    });
  };

  toggleCapitalInfo = () => {
    this.setState({
      capitalTriggered: !this.state.capitalTriggered
    });
  };

  toggleCouncilModal = () => {
    this.setState({
      isCouncilInfoModalActive: !this.state.isCouncilInfoModalActive
    });
  };

  // Assign the payload to our local state
  assignPayload = payload => {
    const { _budget } = this.props;
    if (_budget.current_total > 812000000 || _budget.current_total < 812000000) {
      this.props.showBudgetFailureModalAction();
    } else {
      this.setState(
        {
          formPayload: payload
        },
        () => {
          this.stopTimerAndSubmitPayload();
        }
      );
    }
  };

  stopTimerAndSubmitPayload = () => {
    const data = {
      ...this.state.formPayload,
      started_at: this.state.startTime
    };

    this.props.saveAction(data);
  };

  setGiveUpReason = reason => {
    this.setState({
      giveUpReason: reason
    });
  };

  setGiveUpStatus = () => {
    this.setState({
      giveUpStatus: true
    });
  };

  render() {
    const { fixed, spendingAreaInfoBlocks } = this.state;
    const { _budget, _news, changeCouncilBudgetAction, changeCouncilCategoryAction } = this.props;

    /**
     * Set the width of the current target bar.
     * The width should cap at 100% when the current total is 20 million or greater.
     */

    const totalDefaultValue = calculateTotalByKey(_budget.spending_areas, "defaultValue");
    const totalMaxValue = calculateTotalByKey(_budget.spending_areas, "max");
    const totalMinValue = calculateTotalByKey(_budget.spending_areas, "min");

    const currentTargetPercentage =
      ((_budget.current_total - _budget.initial_total) / (totalMaxValue - totalMinValue)) * 100;
    const spendingTargetWidthValue = currentTargetPercentage < 100 ? currentTargetPercentage : 100;

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    return (
      <Secondary>
        {this.props._budget.showBudgetSuccessModal && (
          <BudgetSuccessModal
            showActions={this.props._budget.showButton}
            handleSubmit={() => this.stopTimerAndSubmitPayload()}
            handleClose={() => {
              this.props.hideBudgetSuccessModal();
              this.props.hideNewsAction();
            }}
          />
        )}

        {this.props._budget.showBudgetFailureModal && (
          <BudgetFailureModal handleClose={this.props.hideBudgetFailureModal} />
        )}

        {this.props._budget.showReferendumModal && (
          <Modal handleClose={() => this.props.toggleReferendumModal()}>
            <i className="c-modal__budget-icon fas fa-info" />

            <ul className="c-modal__list">
              <li>
                1% added to Council Tax would mean a band D property going up £15.08 per year.
              </li>
              <li>
                2% added to Council Tax would mean a band D property going up £30.15 per year.
              </li>
              <li>
                3% added to Council Tax would mean a band D property going up £45.23 per year.
              </li>
              <li>
                4% added to Council Tax would mean a band D property going up £60.30 per year. A referendum will be needed as Council Tax increase exceeds Government’s threshold.
              </li>
              <li>
                4.99% added to Council Tax would mean a band D property going up £75.23 per year. A referendum will be needed as Council Tax increase exceeds Government’s threshold.
              </li>
            </ul>
          </Modal>
        )}

        <div className="l-container  l-container--padded">
          <Grid>
            <Cell size={{ desktop: "7", tablet: "7", phone: "6" }}>
              <div className="budget-intro">
                <h4>
                  Budget <span className="light">Challenge</span>
                </h4>
                <h1>
                  Set Your <span className="light">Budget</span>
                </h1>
                <p>
                  <b>How would you allocate £812 million across all of our services below? </b>
                </p>
                <p>
                  Remember you can also generate income from services that we provide.
                </p>
              </div>
            </Cell>
          </Grid>
        </div>
        <Divide isLarge />

        <div
          className={classnames({ "l-section-fixed-wrap": fixed })}
          ref={fixedSection => (this.fixedSection = fixedSection)}
        >
          <div className="l-container l-container--padded">
            <Notification
              heading={_news.headline}
              body={_news.text}
              isActive={_news && _news.show}
              handleClose={this.props.hideNewsAction}
              inFixedPostion={this.state.fixed}
            />
            <div className="l-section l-section--main">
              <div
                className={classnames({
                  "l-section-fixed-wrap-mobile": fixed
                  // 'c-total-bar--fixed': fixed
                })}
              >
                <Grid>
                  <Cell size={{ desktop: "6", tablet: "6", phone: "6" }}>
                    <BudgetTotalBar
                      minLabel={`£${smallNumber(totalMinValue)}`}
                      maxLabel={`£${smallNumber(totalMaxValue)}`}
                      value={`£${smallNumber(_budget.current_total)}`}
                      widthValue={spendingTargetWidthValue}
                      isError={_budget.current_total > 812000000 || _budget.current_total < 812000000}
                    >
                      <h2 className="c-total-bar-header__title">
                        <span className="c-total-bar-header__sub-title">Annual Budget</span>
                        <br />
                        £812m
                      </h2>
                    </BudgetTotalBar>
                  </Cell>

                  <Cell size={{ desktop: "6", tablet: "6", phone: "6" }}>
                    <div className="c-total-bar-wrap">
                      <div className="c-total-bar-header">
                        <h2 className="c-total-bar-header__title">
                          <span className="c-total-bar-header__sub-title">
                            Council Tax Increase
                          </span>
                          <br />£{smallNumber(_budget.council.value_tax)}
                        </h2>
                        <span
                          className="c-total-bar__info-trigger"
                          onClick={() => this.props.toggleReferendumModal()}
                        >
                          <i className="fa fa-info-circle u-text-blue" />
                        </span>
                      </div>

                      <RangeInput
                        step={100000}
                        budget={_budget}
                        disabled={
                          this.props._budget.showBudgetSuccessModal ||
                          this.props._budget.showReferendumModal
                        }
                        tooltipValue={`${_budget.council.value_tax /
                          (_budget.council.max_tax / 10)}%`}
                        changeHandler={value => {
                          this.setState({
                            barColor: value / _budget.council.max_tax
                          });
                          changeCouncilBudgetAction(value);
                        }}
                      />
                    </div>
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
        </div>

        <section className="l-section l-section--savings" id="service-areas-section">
          <div className="l-section__header">
            <Grid>
              <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
                <h2 className="l-section__title">Service Areas</h2>
                <p className="l-section__sub-title">
                  Allocate funds to each of the service areas to set your budget.
                </p>
              </Cell>
            </Grid>
          </div>

          <div style={{ position: "relative" }}>
            <Grid>
              {_budget &&
                _budget.peoples_averages &&
                _budget.spending_areas.map((area, key) => {
                  const potentialImpacts = this.getPotentialImpacts(
                    area.triggers,
                    area.value,
                    area.max
                  );

                  return (
                    <Cell size={{ desktop: "4", tablet: "6", phone: "6" }} key={key}>
                      <SpendingAreaCard
                        cons={potentialImpacts}
                        key={key}
                        name={area.name}
                        defaultValue={area.defaultValue}
                        value={area.value}
                        max={area.max}
                        min={area.min}
                        locked={area.locked}
                        step={area.step}
                        info={area.info}
                        icon={area.icon}
                        slug={area.slug}
                        negativeValueForDisplay={area.negativeValueForDisplay}
                        disabled={!isIE11 && this.props._budget.showBudgetSuccessModal}
                        onChange={value => {
                          if (!this.props._budget.showBudgetSuccessModal) {
                            this.props.changeSpendingAreaAction(key, value, area.slug);
                          } else {
                            return;
                          }
                        }}
                        toggleInfo={this.toggleInfoCard}
                      />
                      {spendingAreaInfoBlocks[key] && spendingAreaInfoBlocks[key].isInfoCardActive && (
                        <div className="c-info-block">
                          <div className="c-info-block__header">
                            <i
                              className="c-info-block__close fa fa-times"
                              onClick={() => this.toggleInfoCard(area.slug, "info")}
                            />
                            <h2 className="c-info-block__title">
                              {area.name} - <i className="fa fa-info-circle" /> Information
                            </h2>
                          </div>

                          <div className="c-info-block__content">
                            {/* <p>{area.info}</p> */}
                            <ul>
                              {area.info.map((infoEntry, k) => (
                                <li key={k}>{infoEntry}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}

                      {spendingAreaInfoBlocks[key] &&
                        spendingAreaInfoBlocks[key].isImpactsCardActive && (
                          <div className="c-info-block">
                            <div className="c-info-block__header">
                              <i
                                className="c-info-block__close fa fa-times"
                                onClick={() => this.toggleInfoCard(area.slug, "impacts")}
                              />
                              <h2 className="c-info-block__title">
                                {area.name} - Budget Impacts
                              </h2>
                            </div>

                            <div className="c-info-block__content">
                              {potentialImpacts.length > 0 &&
                                potentialImpacts.map((item, k1) => {
                                  return (
                                    <div key={k1} className="cons-list-item">
                                      {getConsequences(item, area)}
                                      <ul>
                                        {item.consequences.map((neCons, k) => {
                                          return <li key={k}>{neCons}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                    </Cell>
                  );
                })}
            </Grid>
          </div>

          <Divide isLarge={true} isLight={true} />
        </section>

        <IncomeAreasSection
          budget={_budget}
          changeCouncilCategoryAction={changeCouncilCategoryAction}
        />

        {_news.news_alerts.length > 0 && (
          <div>
            <section className="l-section">
              <h2>Budget Impacts Summary</h2>
              <NewsItemSummary />
            </section>
            <Divide />
          </div>
        )}

        <section id="submit-form-section" className="l-section">
          <Grid>
            <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
              <h2>Why did you set this budget?</h2>
              <p>
                <span className="u-text-blue">&#42;</span> Indicates a required field
              </p>
            </Cell>
          </Grid>

          <SubmitBudgetForm onSubmit={this.assignPayload} />
        </section>
      </Secondary>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      _budget: state.budget,
      _news: state.news
    };
  },
  (dispatch, ownProps) => {
    return {
      changeSpendingAreaAction: (key, value, slug) =>
        dispatch(budgetActions.changeSpendingAreaAction(key, value, slug)),
      changeCouncilCategoryAction: (category, value, deduction = false) =>
        dispatch(budgetActions.changeCouncilCategoryAction(category, value, deduction)),
      nav: id => dispatch(push("/bring-money-to-council")),
      hideNewsAction: () => dispatch(newsActions.hideNewsAction()),
      hideCongratulations: () => dispatch(newsActions.hideCongratulations()),
      hideBudgetSuccessModal: () => dispatch(budgetActions.hideBudgetSuccessModal()),
      showBudgetSuccessModalAction: showButton =>
        dispatch(budgetActions.showBudgetSuccessModal(showButton)),
      showBudgetFailureModalAction: showButton => dispatch(budgetActions.showBudgetFailureModal()),
      hideBudgetFailureModal: () => dispatch(budgetActions.hideBudgetFailureModal()),
      saveAction: payload => dispatch(budgetActions.saveAction(payload)),
      triggerSubmitModal: () => dispatch(budgetActions.triggerSubmitModal()),
      toggleReferendumModal: () => dispatch(budgetActions.toggleReferendumModal()),
      logUserStartingAction: () => dispatch(budgetActions.logUserStartingAction()),
      postGiveUpReason: (reason, cb) => dispatch(budgetActions.postGiveUpReason(reason, cb)),
      changeCouncilBudgetAction: amount => dispatch(budgetActions.changeCouncilBudgetAction(amount))
    };
  }
)(SetYourBudget);
