export const calculateRisk = (area, value) => {
  if (area) {
    const risks = area.risk;

    // Filter the appropriate risk level based on the set value.
    // Since there is a possibility there might be more than three consequence triggers, we
    // are forced to map out risk levels inside of the data.js
    const arr = Object.keys(risks).filter(risk => {
      return (value >= risks[risk].from && value < risks[risk].to)
    });

    return arr.length > 0 ? arr[0] : 'low';
  }
}