import * as types from './types'

const _GET_STATS_SUCCESS = (data) => {
  return {type: types.GET_STATS_SUCCESS, payload: data}
}

const _GET_STATS_FAILURE = (data) => {
  return {type: types.GET_STATS_FAILURE, payload: data}
}

export { _GET_STATS_SUCCESS, _GET_STATS_FAILURE}
