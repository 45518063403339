const LOGIN = 'LOGIN'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'
const LOGOUT = 'LOGOUT'
const CHANGE_FILTER = 'CHANGE_FILTER'
const LOAD_DATA = 'LOAD_DATA'
const CLEAR = 'CLEAR'
const QUERY = 'QUERY'

export {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  CHANGE_FILTER,
  LOAD_DATA,
  CLEAR,
  QUERY
}
