import React from 'react';

import Generic from '../../layouts/Generic';
import { Grid, Cell } from '../../components/layout';

const Cookies = () => {
  return (
    <Generic>
      <div className="l-section l-section--pt-0">
        <Grid>
          <Cell size={{ desktop: '9', tablet: '9', phone: '6' }}>
            <h1>Cookies currently being used</h1>
            <p>
              Cookies are small text files that are placed on your computer, smartphone, tablet or smart TV’s when you access a website. They are widely used in order to make websites work, or work more efficiently, by allowing the website to recognise your device and store information about past actions or preferences. An example could be internet banking, where your device may recognise and populate certain previously entered login details previously entered.
            </p>
            <p>
              The Council website uses cookies in order to provide a better service and experience to the citizens of Birmingham and other website users.
            </p>
            <p>
              There are two kinds of cookies
              <ul>
                <li>
                  <b>session cookies</b> which are short-term and auto-delete after a few minutes or when you close your browser; and
                </li>
                <li>
                  <b>persistent cookies</b> set by the website and stored for a longer period of time, usually used to store commonly entered information on forms (such as your name, address, and telephone number). They also store information about your browsing habits across multiple sites, usually used to allow advertisers and social network site operators to target advertising at you.
                </li>
              </ul>
            </p>
            <p>
              The council uses Google Analytics to analyse the use of our website and help us create a more useful and easy to use site. The data collected is completely anonymous and does not store any personal details. The information is used to analyse how visitors make use of our website and allows us to gather statistical information such as website activity, visitor numbers, popular pages and customer journey through the website.
            </p>
            <p>
              If you do not wish to allow use of cookies for our website, you can block them using your browser preferences (for example by amending your cookie settings on google settings).
            </p>
            <p>
              Find out more <a href="https://www.aboutcookies.org.uk/" target="_blank">about cookies</a>
            </p>
            <p>
              Cookies currently being set
            </p>
            <p>
              On <a href="http://www.birmingham.gov.uk/" target="_blank">www.birmingham.gov.uk</a>
              <ul>
                <li>
                  __utma, __utmb, __utmz. Used by Google Analytics as part of our statistical information gathering
                </li>
                <li>
                  5ybiiJ37HUoeNLdw. Used to maintain the front end user's session, facilitating accessibility settings, form progress, etc
                </li>
                <li>
                  TestCookie Used to test whether the browser can accept cookies
                </li>
                <li>
                  Session cookie. This used to store the session id of a user. This is required to determine whether a user is logged in to the web site. Session data is also stored when a user is filling in a form however the data is stored on the server and not in the cookie.These cookies are set to expire as soon as a user quits their browser.
                </li>
              </ul>
            </p>
          </Cell>
        </Grid>
      </div>
    </Generic>
  )
}

export default Cookies