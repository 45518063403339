import * as types from './types'
import * as types2 from '../budget/types'

const reducer = (state = {
  show: false,
  headline: '',
  date: null,
  shown_congratulations: 1,
  news_alerts: [],
  text: null
}, action) => {
  switch (action.type) {
    case types.SHOW_NEWS:
      return {
        ...state,
        show: true,
        headline: action.payload.headline,
        date: action.payload.date,
        text: action.payload.text,
      }

    case types.STORE_NEWS:
      return {
        ...state,
        news_alerts: [
          ...state.news_alerts,
          {
            headline: action.payload.headline,
            date: action.payload.date,
            text: action.payload.text,
            id: action.payload.id,
          }
        ]
      }

    case types.LOAD_INITIAL_NEWS_DATA_SUCCESS:
      return {
        ...state,
        news_alerts: [...action.data],
      }

    case types.REMOVE_NEWS:
      return {
        ...state,
        news_alerts: state.news_alerts.filter(news => news.id !== action.id),
      }

    case types.HIDE_NEWS:
      return Object.assign({}, state, { show: false })

    case types2.START_AGAIN:
      return Object.assign({}, state, { show: false })

    case types.SHOW_CONGRATS:
      return Object.assign({}, state, { shown_congratulations: 2 })

    case types.HIDE_CONGRATS:
      return Object.assign({}, state, { shown_congratulations: 3 })

    default:
      return state
  }
}
export default reducer
