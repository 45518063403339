import React, { Component } from "react";

import ServiceAreaCard from "../../common/cards/service-area-card/ServiceAreaCard";
import { Cell, Grid } from "../../layout";

/**
 * Submitted Income Area
 * This component has the same look and feel as the regular Income Area Section
 * but the data used references the pre-selected values. This component is
 * to be used if you wish to present a user's submission or the people's submission.
 */

class IncomeAreaSectionSubmitted extends Component {
  get isCouncilBudget() {
    return this.props.isCouncilBudget;
  }

  render() {
    const {
      budget, // Selected budget
      councilBudget, // Council info
      changeCouncilCategoryAction,
      isSummary = false
    } = this.props;

    const incomeKeys = [1, 2, 3, 4, 5];
    const investmentKeys = [3, 4, 5];

    const getCardStatus = key => {
      const { isSummary, isCouncilBudget, councilBudget, budget } = this.props;

      let cardStatus = false;

      if (budget[`council_${key}`]) {
        cardStatus =
          isSummary && isCouncilBudget
            ? councilBudget[`council_${key}`]
            : budget[`council_${key}`].toString() !== "0";
      }

      return cardStatus;
    };

    return (
      <div>
        <section className="l-section l-section--income">
          <Grid>
            <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
              <div className="l-section__header">
                <h2 className="l-section__title">Income</h2>
                <p className="l-section__sub-title">Add income to our total savings target</p>
              </div>
            </Cell>
          </Grid>

          <Grid>
            {Object.keys(budget).length > 0 &&
              incomeKeys.map(key => {
                return (
                  <Cell size={{ desktop: "4", tablet: "6", phone: "6" }} key={key}>
                    <ServiceAreaCard
                      k={1}
                      name={councilBudget[`council_${key}_name`]}
                      value={councilBudget[`council_${key}_val`]}
                      info={councilBudget[`council_${key}_info`]}
                      step={councilBudget[`council_${key}_val`] / 10}
                      isSummary={isSummary}
                      isSummaryAverage={!this.props.isIndividual}
                      averageStatus={budget[`council_${key}`]}
                      status={getCardStatus(key)}
                      onChange={v => changeCouncilCategoryAction(`council_${key}`, v)}
                    />
                  </Cell>
                );
              })}
          </Grid>
        </section>

        {/* <section className="l-section l-section--investment">
          <Grid>
            <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
              <div className="l-section__header">
                <h2 className="l-section__title">Investment</h2>
                <p className="l-section__sub-title">Add investment to our total savings target</p>
              </div>
            </Cell>
          </Grid>

           <Grid>
            {Object.keys(budget).length > 0 &&
              investmentKeys.map(key => {
                return (
                  <Cell size={{ desktop: "4", tablet: "6", phone: "6" }} key={key}>
                    <ServiceAreaCard
                      k={1}
                      name={councilBudget[`council_${key}_name`]}
                      value={councilBudget[`council_${key}_val`]}
                      info={councilBudget[`council_${key}_info`]}
                      step={councilBudget[`council_${key}_val`] / 10}
                      isSummary={isSummary}
                      isSummaryAverage={!this.props.isIndividual}
                      averageStatus={budget[`council_${key}`]}
                      status={getCardStatus(key)}
                      isInvestment={true}
                      label="Investment Added"
                      onChange={v => changeCouncilCategoryAction(`council_${key}`, v)}
                    />
                  </Cell>
                );
              })}
          </Grid> 
        </section>*/}
      </div>
    );
  }
}

export default IncomeAreaSectionSubmitted;
