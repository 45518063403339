import React from 'react'
import ReactDOM from 'react-dom'
import thunk from 'redux-thunk'
import promise from 'redux-promise'

import 'jspolyfill-array.prototype.findIndex';

import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { Route, Switch } from 'react-router'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'

import {
  budgetReducer,
  newsReducer,
  statsReducer,
  budgetActions,
  reportsReducer,
  pageReducer,
} from './reducks';

import {
  Homepage,
  SetYourBudget,
  PeoplesBudget,
  Accessibility,
  Cookies,
  BudgetDilemma,
  PrivacyPolicy,
  PeoplesBudgetIndividual,
  BudgetReports,
  MoreInformationPage
} from './pages';

/**
 * Import Base Styles
 */
import './assets/styles/main.css';

const reducers = {
  budget: budgetReducer,
  news: newsReducer,
  reports: reportsReducer,
  stats: statsReducer,
  page: pageReducer,
};

const history = createBrowserHistory();
const logger = createLogger({ collapsed: true, diff: true });
const rootReducer = combineReducers(reducers);

let middleware = [
  thunk,
  promise,
  routerMiddleware(history)
];

const composeEnhancers = process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

if (process.env.NODE_ENV === 'development') {
  middleware = [
    ...middleware,
    logger
  ];
}

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(
    applyMiddleware(
      ...middleware
    )
  )
);

store.dispatch(budgetActions.loadInitialDataAction());
store.dispatch(budgetActions.loadPeopleAction());
store.dispatch(budgetActions.logUserVisitedAction());

ReactDOM.render(
  <div>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/set-your-budget" component={(props) => (
            <SetYourBudget timestamp={new Date().toString()} {...props} />
          )}
          />
          <Route exact path="/peoples-budget" component={PeoplesBudget} />
          <Route exact path="/peoples-budget/:id" component={PeoplesBudgetIndividual} />
          <Route exact path="/cookies" component={Cookies} />
          <Route exact path="/budget-dilemma" component={BudgetDilemma} />
          <Route exact path="/accessibility" component={Accessibility} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/reports" component={BudgetReports} />
          <Route exact path="/more-information" component={MoreInformationPage} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </div>,
  document.getElementById('root')
);
