import React from 'react';
import classnames from 'classnames';

const BudgetTotalBar = ({
  children,
  isRed = false,
  isError = false,
  isTax = false,
  label = null,
  hideTooltip = false,
  value,
  widthValue,
  toggleInfoModal,
  minLabel,
  maxLabel,
}) => (
    <div className="c-total-bar-wrap">
      <div className="c-total-bar-header">
        {children}

        {
          toggleInfoModal &&
          <span className="c-total-bar__info-trigger" onClick={() => toggleInfoModal()}>
            <i className="fa fa-info-circle u-text-purple" />
          </span>
        }
      </div>
      <div className={classnames('c-total-bar', { 'c-total-bar--red': isRed })}>
        {
          (widthValue > 0 && !hideTooltip) &&
          <div className={
            classnames('c-total-bar__tip', {
              'c-total-bar__tip--small': !label,
              'c-total-bar__tip--tax': isTax
            }
            )}
            style={{ left: `${widthValue}%` }}
          >
            <p className="c-total-bar__tip-title">
              <small style={{ marginBottom: 0 }}>{!isTax ? 'Current Spending' : 'Council Tax'}</small>
              <span className="c-total-bar__tip-value">
                {isRed ? '£812m' : value}
              </span>
            </p>
          </div>
        }

        <div className="c-total-bar__min-max-wrap">
          {minLabel && <span className="c-total-bar__min-value">{minLabel}</span>}
          {maxLabel && <span className="c-total-bar__max-value">{maxLabel}</span>}
        </div>

        <div
          className={classnames('c-total-bar__value', {
            'c-total-bar__value--error': isError,
          })}
          style={{ width: `${widthValue}%` }}
        />
      </div>
    </div>
  );

export default BudgetTotalBar;
