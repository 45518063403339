import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Button from '../../components/common/button/Button';
import Divide from '../../components/common/divide/Divide';
import Jumbo from '../../components/common/jumbo/Jumbo';
import Modal from '../../components/common/modal/Modal';
import PeoplesBudgetEntries from '../../components/sections/peoples-budget-entries/PeoplesBudgetEntries';
import TwitterFeed from '../../components/common/twitter-feed/TwitterFeed';
import PeoplesBudgetStats from '../../components/sections/peoples-budget-stats/PeoplesBudgetStats';
import { Container, Grid, Cell } from '../../components/layout';
import Primary from '../../layouts/Primary';

import budget_logo from '../../assets/images/birmingham_mobile_logo.jpg';
import annualBudgetGraphic from '../../assets/images/annual-budget-graphic.jpg'

import HomepageIntro from './HomepageIntro';

import {
  pageActions
} from '../../reducks';

class Homepage extends Component {
  state = {
    modalActive: false,
    deadlineModalActive: false,
  }

  toggleYouTubeModal = () => {
    this.setState({
      modalActive: !this.state.modalActive,
    });
  }

  toggleDeadlineModal = () => {
    this.setState({
      deadlineModalActive: !this.state.deadlineModalActive,
    });
  }

  render() {
    const { _budget } = this.props;

    return (
      <div>
        <Primary>
          <Container>
            <Jumbo>
              <HomepageIntro toggleDeadlineModal={this.toggleDeadlineModal} />
            </Jumbo>

            <Divide />

            {
              this.state.deadlineModalActive &&
              <Modal handleClose={() => this.toggleDeadlineModal()}>
                <img src={budget_logo} alt="Budget Challenge Icon" width="75" className="u-margin-bottom@1" />
                <h2 className="c-modal__title">The Budget Challenge is now closed</h2>
                <p>
                  View the People's Budget to see what<br />the people of Birmingham thought.<br />
                  <Link to="/peoples-budget">View the People's Budget</Link>
                </p>
              </Modal>
            }

            <div className="l-section l-section--mb l-section--grey l-section--grey--home l-section--border-blue-t" id="more-info-wrap">
              <h1 className="u-text-center">How the challenge works</h1>
              <h3 className="u-text-center u-margin-bottom@2 u-text-normal">
                You will have the option of making savings and adding income.
              </h3>

              <div className="c-how-it-works">
                <div className="c-how-it-works__spend">
                  <h2 className="c-how-it-works__spend-title">
                    Step 1: Decide spending
                  </h2>
                  <p>
                    Decide how much to spend on each Service Area to prioritise what we should do.
                  </p>
                  <div className="c-how-it-works__spend-card" />
                </div>


                <div className="c-how-it-works__income">
                  <div className="c-how-it-works__income-copy">
                    <h3 className="c-how-it-works__title">
                      Step 2: Add income
                    </h3>
                    <p>
                      You will be able to create additional income by raising council tax, increasing fees and charges.
                    </p>
                  </div>
                  <div className="c-income-tooltip" />

                  <div className="c-income-tiles">
                    <Grid>
                      <Cell size={{ phone: '6' }}>
                        <div className="c-card c-card--service-area u-margin-top-0"><div><span className="c-card__icon-wrap"><i className="fas fa-info-circle"></i></span><h6 className="c-card__title">Increase General Fees and Charges</h6><p className="c-card__value-title">Income</p><p className="c-card__value">£250k</p><div className="c-card__toggle"><label className="c-card__toggle-label"><i className="fas fa-check c-card__toggle-tick"></i><span className="c-card__toggle-selector c-card__toggle-selector--active">Remove Income</span><input type="checkbox" className="c-card__toggle-input" /></label></div></div></div>
                      </Cell>
                    </Grid>
                  </div>
                </div>

                <div className="c-how-it-works__slider">
                  <img src={annualBudgetGraphic} alt="Slider" />
                  <div className="c-how-it-works__slider-content">
                    <h3 className="c-how-it-works__slider-title">
                      Step 3: Submit your final budget
                    </h3>
                    <p>
                      Your budget will be shared with Councillors and used to help set the medium term financial strategy for 2022/23 and beyond.
                    </p>
                  </div>
                </div>

                <div className="c-how-it-works__btn-wrap">
                  <a href="/budget-dilemma" className="c-button" target="_self">More Info on Our Budget</a>
                </div>
              </div>
            </div>

            <div className="u-text-center">
              {moment().isAfter('2021-12-31') && <Button onClick={() => this.toggleDeadlineModal()}>Take the Budget Challenge</Button>}
              {!moment().isAfter('2021-12-31') && <Button href="/set-your-budget">Take the Budget Challenge</Button>}
            </div>

            <Divide isXLarge={true} isLight={true} />

            <div className="l-section l-section--pt-0">
              <Grid>
                <Cell size={{ desktop: '7', tablet: '6', phone: '6' }}>
                  <h2>Latest Budgets Submitted</h2>

                  <PeoplesBudgetEntries
                    rowCount={2}
                    limit={8}
                    budgets={_budget.peoples_budgets}
                  />

                  <div className="u-text-center u-margin-top@2">
                    <Button onClick={() => this.props.anchorToAllBudgets()}>
                      <i className="fas fa-chevron-right u-margin-right" />
                      View All Budgets
                    </Button>
                  </div>
                </Cell>
                <Cell size={{ desktop: '5', tablet: '6', phone: '6' }}>
                  <div className="u-margin-top@2-mobile">
                    <h2>@BhamCityCouncil</h2>
                    <TwitterFeed />
                  </div>
                </Cell>
              </Grid>
            </div>

            <Divide isXLarge={true} isLight={true} />

            <PeoplesBudgetStats />
          </Container>
        </Primary>
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  return {
    _budget: state.budget
  }
}, (dispatch, ownProps) => {
  return {
    anchorToAllBudgets: () => dispatch(pageActions.anchorToAllBudgets()),
  }
})(Homepage)
