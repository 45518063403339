import * as types from './types'

const reducer = (state = {
  budgets_submitted: 0,
  service_areas_protected: 0,
  service_areas_at_risk: 0
}, action) => {
  switch (action.type) {
    case types.GET_STATS:
      return state
    case types.GET_STATS_SUCCESS:
      return Object.assign({}, state, action.payload)
    case types.GET_STATS_FAILURE:
      return state
    default:
      return state
  }
}
export default reducer
