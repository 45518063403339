import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const TopBar = ({
  children,
  className
}) => (
  <div className={classnames('c-top-bar', className)}>
    <div className="beta-link-header-container"><p className="c-countdown__copy">Beta Version - <Link className="beta-link-header" to="/accessibility">More Info</Link></p></div> {children}
  </div>
);

TopBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default TopBar;
