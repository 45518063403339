import React from 'react';

const Modal = ({
  handleClose,
  children
}) => {
  return (
    <div className="c-modal-overlay">
      <div className="c-modal">
        {
          handleClose &&
            <i className="c-modal__close-icon fa fa-times" onClick={handleClose} />
        }
        <div className="c-modal__inner">{children}</div>
      </div>
    </div>
  )
};

export default Modal;