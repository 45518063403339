import React from 'react';

import Generic from '../../layouts/Generic';
import { Grid, Cell } from '../../components/layout';

const PrivacyPolicy = () => {
  return (
    <Generic>
      <div className="l-section l-section--pt-0">
        <Grid>
          <Cell size={{ desktop: '9', tablet: '9', phone: '6' }}>
            <h1>Privacy Statement</h1>
            <p>
              Birmingham City Council is committed to protecting and respecting your privacy. The Budget Calculator collects no personal information from respondents. All data collected and evaluated is anonymous.
            </p>
          </Cell>
        </Grid>
      </div>
    </Generic>
  )
}

export default PrivacyPolicy;
