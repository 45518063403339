import React, { Component } from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames';

class Countdown extends Component {
  state = {
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;
    const { className } = this.props;

    return (
      <div className={classnames('c-countdown', className)}>
        <p className="c-countdown__copy">
          Time remaining to set budget <i className="far fa-clock"></i>
          <span className="c-countdown__items">
            <span className="c-countdown__item" data-test-id="countdown-days">
              {this.addLeadingZeros(countDown.days)}&nbsp;
              <span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
            </span>

            <span className="c-countdown__item" data-test-id="countdown-hours">
              {this.addLeadingZeros(countDown.hours)}&nbsp;
              <span>{countDown.hours === 1 ? 'Hour' : 'Hours'}</span>
            </span>


            <span className="c-countdown__item" data-test-id="countdown-mins">
              {this.addLeadingZeros(countDown.min)}&nbsp;
              <span>{countDown.min === 1 ? 'Min' : 'Mins'}</span>
            </span>
          </span>
        </p>
      </div>
    );
  }
}

Countdown.propTypes = {
  date: PropTypes.string.isRequired
};

export default Countdown;
