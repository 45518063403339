import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';


import { Grid, Cell } from '../../../components/layout';

const PeoplesBudgetStats = ({
  stats,
  location = {
    pathname: ''
  },
  budget,
}) => {
  const getAverageTime = (time_spent) => moment.duration(parseInt(time_spent, 10), "seconds").humanize();

  return (
    <div className="c-budget-stats">
      <h2 className="c-budget-stats__title">Budget Challenge Stats</h2>

      <Grid>
        <Cell size={{ desktop: '2', tablet: '4', phone: '3' }} alignment="bottom">
          <div className="c-budget-stat c-budget-stat--no-border">
            <img className="c-budget-stat__icon" src="/images/icons/exclamation-icon.png" alt="Areas At Risk Stat Icon" />
            <span className="c-budget-stat__count">{stats.service_areas_at_risk}</span><br />
            service areas at risk
          </div>
        </Cell>
        <Cell size={{ desktop: '2', tablet: '4', phone: '3' }} alignment="bottom">
          <div className="c-budget-stat">
            <img className="c-budget-stat__icon" src="/images/icons/people-icon.png" alt="Started The Challenge Stat Icon" />
            <span className="c-budget-stat__count">{stats.started_count}</span><br />
            started the challenge
          </div>
        </Cell>
        <Cell size={{ desktop: '2', tablet: '4', phone: '3' }} alignment="bottom">
          <div className="c-budget-stat">
            <img className="c-budget-stat__icon" src="/images/icons/budgets-icon.png" alt="Budgets Submitted Stat Icon" />
            <span className="c-budget-stat__count">{stats.budgets_submitted}</span><br />
            budgets submitted
          </div>
        </Cell>
        <Cell size={{ desktop: '2', tablet: '4', phone: '3' }} alignment="bottom">
          <div className="c-budget-stat">
            <img className="c-budget-stat__icon" src="/images/icons/visited_icon.png" alt="People Gave Up Stat Icon" />
            <span className="c-budget-stat__count">{stats.visited_count}</span><br />
            people visited the site
          </div>
        </Cell>
        <Cell size={{ desktop: '2', tablet: '4', phone: '3' }} alignment="bottom">
          <div className="c-budget-stat">
            <img className="c-budget-stat__icon" src="/images/icons/share-icon.png" alt="Shared Stat Icon" />
            <span className="c-budget-stat__count">{stats.share_count}</span><br />
            shared budgets
          </div>
        </Cell>
        <Cell size={{ desktop: '2', tablet: '4', phone: '3' }} alignment="bottom">
          <div className="c-budget-stat">
            <img className="c-budget-stat__icon" src="/images/icons/timer-icon.png" alt="Time Spent Stat Icon" />
            <span className="c-budget-stat__count">{getAverageTime(budget.peoples_averages.time_spent)} (avg.)</span><br />
            spent completing budget
          </div>
        </Cell>
      </Grid>
    </div>
  )
}

export default connect((state, ownProps) => {
  return {
    stats: state.stats,
    budget: state.budget,
  }
})(PeoplesBudgetStats)
