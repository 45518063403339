import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux'

import Divide from '../../components/common/divide/Divide';
import BudgetSuccessModal from '../../components/common/modal/BudgetSuccessModal';
import BudgetTotalBar from '../../components/budget-total-bar/BudgetTotalBar';
import SpendingAreaCard from '../../components/common/cards/spending-area-card/SpendingAreaCard';
import Modal from '../../components/common/modal/Modal';
import Button from '../../components/common/button/Button';
import IncomeAreaSectionSubmitted from '../../components/sections/income-areas-section/IncomeAreasSectionSubmitted';
import { Cell, Grid } from '../../components/layout';

import Secondary from '../../layouts/Secondary';

import {
  budgetActions,
} from '../../reducks'

import data from '../../data';

import { smallNumber, calculateTotalByKey } from '../../helpers/number';
import { calculateRisk } from '../../helpers/risk';
import { getConsequences } from '../../helpers/impacts';

class PeoplesBudgetIndividual extends Component {
  state = {
    showExtraShareContent: true,
    isCouncilInfoModalActive: false,
    spendingAreaInfoBlocks: [],
    isShareModalActive: false,
    breakingNewsLimit: true,
  };

  get peoplesTotalAmountNow() {
    const { _budget } = this.props;

    return _budget.spending_areas.reduce((prev, elem) => {
      return prev + Number(_budget.selected_budget[elem.slug])
    }, 0) || 0;
  }

  get councilTaxFigure() {
    const { _budget } = this.props;

    return Number(_budget.selected_budget.council_tax);
  }

  get peopleTotal() {
    const {
      _budget: {
        initial_total,
        council,
        selected_budget,
      }
    } = this.props;

    const keys = [1, 2, 3, 4];
    const averages = keys.map(key => selected_budget[`council_${key}`]);
    const values = keys.map(key => Number(council[`council_${key}_val`]));

    let councilTotal = 0;

    averages.forEach((average, index) => {
      const value = average >= 0.5 ? values[index] : 0;
      councilTotal += value;
    });

    const sum = (initial_total - this.peoplesTotalAmountNow) + (this.councilTaxFigure + councilTotal);

    return sum;
  }

  get selectedBudget() {
    return this.props._budget.selected_budget;
  }

  get spendingAreas() {
    return this.props._budget.spending_areas;
  }

  componentDidMount() {
    window.scroll(0, 0);

    this.props.loadIndividualAction(this.props.match.params.id);

    let spendingAreasInfoContent = this.spendingAreas.map(item => {
      return {
        ...item,
        isInfoCardActive: false,
        isImpactsCardActive: false,
        active: false,
      }
    });

    this.setState({
      spendingAreaInfoBlocks: spendingAreasInfoContent,
    });
  }

  componentWillUnmount() {
    this.props.clearIndividualAction();
  }

  getPotentialImpacts = (cons, value, max) => {
    if (cons && cons.length > 0) {
      cons = cons.filter((item) => {
        const total = max - value;
        return item.to <= total || total >= item.from;
      });
    }
    return cons;
  }

  calculatePeopleTotal = () => {
    const {
      _budget: {
        selected_budget: {
          council_tax,
        }
      }
    } = this.props;

    return council_tax ? this.peopleTotal : 0
  }

  toggleCouncilModal = () => {
    this.setState({
      isCouncilInfoModalActive: !this.state.isCouncilInfoModalActive,
    });
  }

  toggleInfoCard = (slug, cardType) => {
    const index = this.state.spendingAreaInfoBlocks.findIndex(item => item.slug === slug);
    const blocks = [...this.state.spendingAreaInfoBlocks];

    if (cardType === 'info') {
      blocks[index].isInfoCardActive = !blocks[index].isInfoCardActive;
    } else if (cardType === 'impacts') {
      blocks[index].isImpactsCardActive = !blocks[index].isImpactsCardActive;
    }

    if (blocks[index].isInfoCardActive || blocks[index].isImpactsCardActive) {
      window.scroll(0, document.getElementById('service-areas-section').offsetTop - 50);
    }

    this.setState({
      spendingAreaInfoBlocks: blocks,
    });
  }

  render() {
    const {
      _budget
    } = this.props;

    const {
      spendingAreaInfoBlocks
    } = this.state;

    const totalMaxValue = calculateTotalByKey(_budget.spending_areas, 'max');
    const totalMinValue = calculateTotalByKey(_budget.spending_areas, 'min')

    return (
      <Secondary>
        {
          this.state.isCouncilInfoModalActive &&
          <Modal handleClose={() => this.toggleCouncilModal()}>
            <i className="c-modal__budget-icon fas fa-info" />

            <ul className="c-modal__list">
              <li>1% added to Council Tax would mean a band D property going up £12.41 per year.</li>
              <li>2% added to Council Tax would mean a band D property going up £24.82 per year.</li>
              <li>3% added to Council Tax would mean a band D property going up £37.22 per year.</li>
              <li>4% added to Council Tax would mean a band D property going up £49.63 per year.</li>
              <li>4.79% added to Council Tax would mean a band D property going up £59.43 per year.</li>
            </ul>
          </Modal>
        }
        {
          this.props._budget.shouldShowThankYouModal &&
          <Modal handleClose={() => {
            this.setState({ showExtraShareContent: false });
            this.props.setThankYouModal(false)
          }}>
            {
              this.state.showExtraShareContent &&
              <div>
                <i className="c-modal__budget-icon fas fa-share-alt" />
                <h2>Share Your Budget</h2>
                <p>Share your budget with friends and family on social media.</p>

                <a
                  id="facebook_share"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  target="_blank"
                  style={{ visibility: 'hidden' }}>share</a>
                <a
                  id="twitter_share"
                  href={`https://twitter.com/home?status=I%20took%20Birmingham%20City%20Council's%20Budget%20Challenge!%20-%20${window.location.href}`}
                  target="_blank"
                  style={{ visibility: 'hidden' }}>share</a>

                <div className="c-button-group">
                  <Button onClick={(e) => {
                    this.props.postShareAction();
                    document.getElementById('facebook_share').click();
                  }}>
                    <i className="fab fa-facebook" /> Facebook
                  </Button>
                  <Button onClick={() => {
                    this.props.postShareAction();
                    document.getElementById('twitter_share').click();
                  }}>
                    <i className="fab fa-twitter" /> Twitter
                  </Button>
                  <Button onClick={() => {
                    this.props.postShareAction();
                    window.location = `mailto:?&body=I%20took%20Birmingham%20City%20Council's%20Budget%20Challenge!%20-%20${window.location.href}`;
                  }}>
                    <i className="fas fa-envelope" /> Mail
                  </Button>
                </div>
              </div>
            }
          </Modal>
        }

        {
          this.props._budget.showBudgetSuccessModal &&
          <BudgetSuccessModal
            showActions={this.props._budget.showButton}
            handleSubmit={() => this.stopTimerAndSubmitPayload()}
            handleClose={this.props.hideBudgetSuccessModal} />
        }

        <div className="l-container l-container--padded">
          {
            this.selectedBudget &&
            <div style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
              <Grid>
                <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
                  <h3>Budget <span className="light">Challenge</span></h3>
                  <h1 >
                    {this.selectedBudget.first_name}'s <span className="light">Budget Summary</span>
                  </h1>
                </Cell>
                <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
                  <div className="u-text-right">
                    <Button className="c-button--green" onClick={() => {
                      this.setState({ showExtraShareContent: true });
                      this.props.setThankYouModal();
                    }}>
                      <i className="fas fa-share-alt" /> Share Budget
                    </Button>
                  </div>
                </Cell>
              </Grid>
            </div>
          }
        </div>
        <Divide isLarge />
        <div className="l-container l-container--padded">
          <div className="l-section l-section--main">
            {
              (_budget && _budget.selected_budget) &&
              <Grid>
                <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
                  <BudgetTotalBar
                    isRed={true}
                    widthValue={((812000000 - totalMinValue) / (totalMaxValue - totalMinValue)) * 100}
                    minLabel={`£${smallNumber(totalMinValue)}`}
                    maxLabel={`£${smallNumber(totalMaxValue)}`}
                    // this will be replaced with the actual spent value
                    value={`£${smallNumber(_budget.current_total)}`}
                  >
                    <h2 className="u-text-dark-grey c-total-bar-header__title">
                      <span className="c-total-bar-header__sub-title">Annual Spending</span><br />
                      £812m
                    </h2>
                  </BudgetTotalBar>
                </Cell>
                <Cell size={{ desktop: '6', tablet: '6', phone: '6' }} alignment="bottom">
                  <BudgetTotalBar
                    isTax
                    minLabel='0%'
                    maxLabel='4.79%'
                    toggleInfoModal={this.toggleCouncilModal}
                    value={
                      `${_budget.selected_budget.council_tax === _budget.council.max_tax ? 4.79 : (_budget.selected_budget.council_tax / (_budget.council.max_tax / 5)).toFixed(1)}%`
                    }
                    widthValue={`${(_budget.selected_budget.council_tax / _budget.council.max_tax) * 100}`}
                  >
                    <h2 className="u-text-dark-grey c-total-bar-header__title">
                      <span className="c-total-bar-header__sub-title">Council Tax Increase</span><br />
                      £{smallNumber(Number(_budget.selected_budget.council_tax))}
                    </h2>
                  </BudgetTotalBar>
                </Cell>
              </Grid>
            }
          </div>
        </div>

        <section className="l-section l-section--savings" id="service-areas-section">
          <div className="l-section__header">
            <Grid>
              <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
                <h2 className="l-section__title">Service Areas</h2>
                <p className="l-section__sub-title">
                  Help meet your target by creating savings in key areas
                </p>
              </Cell>
            </Grid>
          </div>

          <div style={{ position: 'relative' }}>

            {
              (_budget && _budget.selected_budget) &&
              <Grid>
                {
                  _budget.spending_areas.map((area, key) => {
                    const potentialImpacts = this.getPotentialImpacts(area.triggers, _budget.selected_budget[`${area.slug}`], area.max);

                    return (
                      <Cell size={{ desktop: '4', tablet: '6', phone: '6' }} key={key}>
                        <SpendingAreaCard
                          className="c-card--summary"
                          key={key}
                          cons={potentialImpacts}
                          color={area.color}
                          name={area.name}
                          value={this.props._budget.selected_budget[area.slug]}
                          max={area.max}
                          locked={area.locked}
                          info={area.info}
                          icon={area.icon}
                          isSummary={true}
                          slug={area.slug}
                          step={area.step}
                          isProtected={this.props._budget.selected_budget[area.slug] >= Number(data.spending_areas[key].max)}
                          toggleInfo={this.toggleInfoCard}
                          risk={calculateRisk(area, this.props._budget.selected_budget[area.slug])}
                        />
                        {
                          (spendingAreaInfoBlocks[key] && spendingAreaInfoBlocks[key].isInfoCardActive) &&
                          <div className="c-info-block">
                            <div className="c-info-block__header">
                              <i className="c-info-block__close fa fa-times" onClick={() => this.toggleInfoCard(area.slug, 'info')} />
                              <h2 className="c-info-block__title">{area.name} - <i className='fa fa-info-circle' /> Information</h2>
                            </div>

                            <div className="c-info-block__content">
                              <p>{area.info}</p>
                            </div>
                          </div>
                        }
                        {
                          (spendingAreaInfoBlocks[key] && spendingAreaInfoBlocks[key].isImpactsCardActive) &&
                          <div className="c-info-block">
                            <div className="c-info-block__header">
                              <i className="c-info-block__close fa fa-times" onClick={() => this.toggleInfoCard(area.slug, 'impacts')} />
                              <h2 className="c-info-block__title">{area.name} - <i className='fas fa-exclamation-triangle' /> Consequences</h2>
                            </div>

                            <div className="c-info-block__content">
                              {
                                potentialImpacts.length > 0 && potentialImpacts.map((item, k1) => {
                                  return (
                                    <div key={k1} className='cons-list-item'>
                                      {getConsequences(item, area)}
                                      <ul>
                                        {item.consequences.map((neCons, k) => {
                                          return <li key={k}>{neCons}</li>
                                        })}
                                      </ul>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        }
                      </Cell>
                    )
                  })
                }
              </Grid>
            }
          </div>
        </section>


        {
          (_budget && _budget.selected_budget) &&
          <div>
            <IncomeAreaSectionSubmitted
              budget={_budget.selected_budget}
              councilBudget={_budget.council}
              selectedBudget={_budget.selected_budget}
              isSummary={true}
              isIndividual={true}
            />
          </div>
        }

        {
          _budget.selected_budget &&
          <div className="l-section u-margin-top@2">
            <Grid>
              <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
                <div className="c-reason-block">
                  <h2 className="c-reason-block__title">Why I set this budget</h2>
                  {
                    _budget.selected_budget.budget_reason ? <p className="c-reason-block__content" dangerouslySetInnerHTML={{ __html: _budget.selected_budget.budget_reason }} /> : <p className="c-reason-block__content">No Reason Set</p>
                  }
                </div>
              </Cell>
              <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
                <h2>Related Links</h2>
                <div className="c-button-group">
                  <Button href="/peoples-budget">View People's Budget</Button>
                </div>
              </Cell>
            </Grid>
          </div>
        }
        <Divide isLight={true} isLarge={true} />
      </Secondary>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    _budget: state.budget,
    router: state.router
  }
}, (dispatch, ownProps) => {
  return {
    navBack: () => dispatch(push('/peoples-budget')),
    loadIndividualAction: (id) => dispatch(budgetActions.loadIndividualAction(id)),
    clearIndividualAction: () => dispatch(budgetActions.clearIndividualAction()),
    startAgain: () => dispatch(budgetActions.startAgainAction()),
    postShareAction: (cb) => dispatch(budgetActions.postShareAction(cb)),
    triggerSubmitModal: () => dispatch(budgetActions.triggerSubmitModal()),
    setThankYouModal: (isActive = true) => dispatch(budgetActions.setThankYouModal(isActive))
  }
})(PeoplesBudgetIndividual)
