import news from "./dictionary/news";

const data = {
  council: {
    initial_tax: 0,
    value_tax: 7800000.0,
    max_tax: 19400000,
    council_1: false,
    council_1_val: 100000,
    council_1_name: "Garden Waste",
    council_1_info: "Income has already been budgeted at £2.9m. Any budgetary change will reflect price increases and the additional demand.",
    council_2: false,
    council_2_val: 100000,
    council_2_name: "Bulky Waste",
    council_2_info: "Income has already been budgeted at £0.5m. Any budgetary change will reflect price increases and the additional demand.",
    council_3: false,
    council_3_val: 600000,
    council_3_name: "Car Parking",
    council_3_info: "Income has already been budgeted at £22.8m. Any budgetary change will reflect price increases and the additional demand.",
    council_4: false,
    council_4_val: 100000,
    council_4_name: "Parks income (rental)",
    council_4_info: "Income has already been budgeted at £3.4m. Any budgetary change will reflect price increases and the additional demand.",
    council_5: false,
    council_5_val: 5800000,
    council_5_name: "Other Existing Fees and Charges",
    council_5_info: "Income has already been budgeted at £230.6m. Any budgetary change will reflect price increases and the additional demand.",
    value_total: 0
  },
  spending_areas: [{
      sno: 1,
      name: "Adult Social Care",
      min: 317000000,
      max: 343000000,
      defaultValue: 337400000,
      value: 337400000,
      step: 100000,
      slug: "health_social_care_partnership",
      risk: {
        low: {
          from: 337400001, // min
          to: 343000000 // max
        },
        medium: {
          from: 327400001, // min
          to: 337400000 // max
        },
        high: {
          from: 317000000, // min
          to: 327400000 // max
        }
      },
      info: [
        "Adult Social Care services are designed to help citizens to live their lives well.  Services includes the provision of information and advice, assessment, short and long term support and enablement and safeguarding.  Our goal is to support citizens to be resilient, living independently whenever possible and exercising choice and control so that they can live good quality lives and enjoy good health and wellbeing.",
      ],

      triggers: [{
          from: 342400000, // min when increase in recommended value
          to: 343000000, // max when increase in recommended value
          consequences: [
            "An increase of £5m to the budget would help to mitigate the additional investment of approx £8.5m per year needed to cope with the increase in demand for services and the increase in costs due to the greater complexity of support required.  An increase in the budget would mean that we can keep service reductions to a minimum and invest more in community and prevention services which benefit residents and reduce higher cost support packages in the future."
          ],
          news: {
            id: 1,
            message: news["1"].text
          }
        },
        {
          from: 332400000, // max when decrease in recommended value
          to: 327400000, // min when decrease in recommended value
          consequences: [
            "A reduction of £5m to the budget would have a significant impact on the services that we would be able to provide.  There would be a negative impact on people waiting to be discharged from hospital as they would have to wait longer for social care services to be put in place.  Investments into community and prevention services would be affected with a negative impact on vulnerable citizens and longer term crisis costs.",
          ],
          news: {
            id: 2,
            message: news["2"].text
          }
        },
        {
          from: 327400000, // max when decrease in recommended value
          to: 317400000, // min when decrease in recommended value
          consequences: [
            "A reduction of £10m to the budget would have a significant impact on service delivery.  There would be a need to cut/end preventative services in order to maintain those required by law.  This would lead to issues including increased waiting times, delays in people being discharged from hospital and an increase in demand on the voluntary and community sector who would receive less funding."
          ],
          news: {
            id: 3,
            message: news["3"].text
          }
        },
        {
          from: 317400000, // max when decrease in recommended value
          to: 317000000, // min when decrease in recommended value
          consequences: [
            "A reduction of £20m to the budget would reduce our ability to meet levels of service provision required by law with a significant risk of harm to vulnerable people and groups across Birmingham.  There would also be a significant negative impact on people waiting for care services leading to an increase in the number of people waiting to be discharged from hospital adding to pressures on the NHS.  Funding to voluntary and community organisations would stop.",
          ],
          news: {
            id: 4,
            message: news["4"].text
          }
        }
      ]
    },
    {
      sno: 2,
      name: "Children & Young People",
      min: 199000000,
      max: 205000000,
      defaultValue: 202500000,
      value: 202500000,
      step: 100000,
      slug: "secondary_school_education",
      risk: {
        low: {
          from: 202500001,
          to: 205000000
        },
        medium: {
          from: 200500001,
          to: 202500000
        },
        high: {
          from: 199000000,
          to: 200500000
        }
      },
      info: [
        "The Birmingham Childrens Trust works with the most disadvantaged children and young people of Birmingham to keep them safe, happy and healthy. Its services include Children’s Advice and Support Service, Emergency duty teams, Early help, Family support, Youth Offending, Children in care, Care leavers, Adoption and fostering."
      ],

      triggers: [{
          from: 204500000, // min when increase in recommended value
          to: 205000000, // max when increase in recommended value
          consequences: [
            "Demand pressure due to demographic growth have put pressure on our service delivery over recent years, for example an estimated an additional £1.5m per year to the cost of Home to School Transport.  An extra £5m budget would allow the council to meet this additional cost as well as providing more money for our other services including education and skills provision."
          ],
          news: {
            id: 5,
            message: news["5"].text
          }
        },
        {
          from: 201500000, // max when decrease in recommended value
          to: 200500000, // min when decrease in recommended value
          consequences: [
            "Reduced capacity to deliver key services including  limited capacity for employment service support to vulnerable children and young people, limited services for children excluded from education,  fewer children supported to overcome learning difficulties, social/emotional problems affecting their progress in schools.",
          ],
          news: {
            id: 6,
            message: news["6"].text
          }
        },
        {
          from: 200500000, // max when decrease in recommended value
          to: 199500000, // min when decrease in recommended value
          consequences: [
            "Significant reduction in service delivery.  Will see reduced Education Psychology Service to schools meaning fewer children supported to overcome learning difficulties, social/emotional problems, reduced occupational therapy support for disabled children, fewer people helped into work, reduced staffing hours to voluntary sector organisations resulting in reduced opening hours and less access to youth services.",
          ],
          news: {
            id: 7,
            message: news["7"].text
          }
        },
        {
          from: 199500000, // max when decrease in recommended value
          to: 199000000, // min when decrease in recommended value
          consequences: [
            "Further reduction in service delivery with even the most critical services, like Home to School Transport to SEND Children, starting to be impacted.  Outcomes for the most vulnerable children and young people could start to deteriorate.",
          ],
          news: {
            id: 8,
            message: news["8"].text
          }
        }
      ]
    },
    {
      sno: 3,
      name: "Education & Skills",
      min: 63000000,
      max: 68300000,
      defaultValue: 66200000,
      value: 66200000,
      step: 100000,
      slug: "primary_school_education",
      risk: {
        low: {
          from: 66200001,
          to: 68300000
        },
        medium: {
          from: 64200001,
          to: 66200000
        },
        high: {
          from: 63000000,
          to: 64200000
        }
      },
      info: [
        "Our Education and Skills service is responsible for standards within 400+ schools in Birmingham. This includes provision of places, school admissions and ensuring that children and young people with Special Educational Needs and Disability have access to appropriate provision.  We also support local residents to gain new skills and get a job, move onto a better job or gain new qualifications by providing access to relevant education and training."
      ],

      triggers: [{
          from: 68200000, // min when increase in recommended value
          to: 68300000, // max when increase in recommended value
          consequences: [
            "Demand pressure due to demographic growth have put pressure on our service delivery over recent years, for example an estimated an additional £1.5m per year to the cost of Home to School Transport.  An extra £5m budget would allow the council to meet this additional cost as well as providing more money for our other services including education and skills provision."
          ],
          news: {
            id: 45,
            message: news["45"].text
          }
        },
        {
          from: 65200000, // max when decrease in recommended value
          to: 64200000, // min when decrease in recommended value
          consequences: [
            "Reduced capacity to deliver key services including limited capacity for employment service support to vulnerable children and young people, limited services for children excluded from education, fewer children supported to overcome learning difficulties, social/emotional problems affecting their progress in schools.",
          ],
          news: {
            id: 46,
            message: news["46"].text
          }
        },
        {
          from: 64200000, // max when decrease in recommended value
          to: 63200000, // min when decrease in recommended value
          consequences: [
            "Significant reduction in service delivery.  Will see reduced Education Psychology Service to schools meaning fewer children supported to overcome learning difficulties, social/emotional problems, reduced occupational therapy support for disabled children, fewer people helped into work, reduced staffing hours to voluntary sector organisations resulting in reduced opening hours and less access to youth services.",
          ],
          news: {
            id: 47,
            message: news["47"].text
          }
        },
        {
          from: 63200000, // max when decrease in recommended value
          to: 63000000, // min when decrease in recommended value
          consequences: [
            "Further reduction in service delivery with even the most critical services, like Home to School Transport to SEND Children, starting to be impacted.  Outcomes for the most vulnerable children and young people could start to deteriorate.",
          ],
          news: {
            id: 48,
            message: news["48"].text
          }
        }
      ]
    },
    {
      sno: 4,
      name: "Waste Collection & Cleaner Street",
      min: 61000000,
      max: 82000000,
      defaultValue: 77700000,
      value: 77700000,
      step: 100000,
      slug: "nursery_early_years",
      risk: {
        low: {
          from: 77700001,
          to: 82000000
        },
        medium: {
          from: 69700001,
          to: 77700000
        },
        high: {
          from: 61000000,
          to: 69700000
        }
      },
      info: [
        "The Waste Management Service provides a refuse collection and disposal service,  street cleansing and a commercial trade waste service."
      ],
      triggers: [{
          from: 81700000, // min when increase in recommended value
          to: 82000000, // max when increase in recommended value
          consequences: [
            "Birmingham is a growing city with increases year on year in the number of households. A budget increase will mitigate some of this growth and ensure continued investment in both waste collection and waste disposal. We will be able to continue our investment in new initiatives such as mobile household recycling centres, extra bulky waste collections and deep cleansing crews to tackle the worst areas of the city, and allow for further investment in waste enforcement activities."
          ],
          news: {
            id: 9,
            message: news["9"].text
          }
        },
        {
          from: 73700000, // max when decrease in recommended value
          to: 69700000, // min when decrease in recommended value
          consequences: [
            "Reduction in waste enforcement activity to trace and prosecute fly tippers and in general street cleansing and graffiti removal activity.  New initiatives such as mobile household recycling centres would end. Charges for services such as bulky waste and garden waste collections would have to increase. Would see more rubbish on the streets and a deterioration of the local environment."
          ],
          news: {
            id: 10,
            message: news["10"].text
          }
        },
        {
          from: 69700000,
          to: 61700000,
          consequences: [
            "Significant cuts to services including general street cleansing activity and an end to specialist street cleansing such as for the autumn leaf fall which may lead to clogged drains and flooding. Charges would have to increase further for services such as bulky waste and garden waste collections and new charges may have to be introduced for attendance at a household recycling centres. Would see a significant deterioration of the local environment"
          ],
          news: {
            id: 11,
            message: news["11"].text
          }
        },
        {
          from: 61700000,
          to: 61000000,
          consequences: [
            "More significant cuts to services - street cleaning, waste enforcement activity, specialist cleaning - and may have to reduce frequency of household waste collections.  Charges would have to rise significantly for services such as bulky waste and garden waste collections and new charges introduced for attendance at a household recycling centres. Would see a significant deterioration of the local environment."
          ],
          news: {
            id: 12,
            message: news["12"].text
          }
        }
      ]
    },
    {
      sno: 6,
      name: "Housing & Homelessness",
      min: 10200000,
      max: 13700000,
      defaultValue: 12900000,
      value: 12900000,
      step: 100000,
      slug: "additional_support_needs",
      risk: {
        low: {
          from: 12900001,
          to: 13700000
        },
        medium: {
          from: 11600001,
          to: 12900000
        },
        high: {
          from: 10200000,
          to: 11600000
        }
      },
      info: [
        "Service is responsible for repairs and maintenance on council owned housing, ensuring housing applications are assessed accurately and critical support to households who are at risk of homelessness or already homeless."
      ],
      triggers: [{
          from: 13600000,
          to: 13700000,
          consequences: [
            "More spend on housing improvements, including those that support our carbon zero commitment. Investment in additional prevention tools to reduce and eradicate risks of homelessness."
          ],
          news: {
            id: 17,
            message: news["17"].text
          }
        },
        {
          from: 12200000,
          to: 11600000,
          consequences: [
            "Ambitions for housing improvements scaled back. Increased complaints and higher overall cost for the Council to support households in crisis."
          ],
          news: {
            id: 18,
            message: news["18"].text
          }
        },
        {
          from: 11600000,
          to: 10300000,
          consequences: [
            "Housing improvements limited to most urgent need. Seeing a more significant impact on those at risk of homelessness with an increasing reliance on emergency accommodation. Unable to meet legally required services effectively, which will result in higher service delivery and legal costs for the Council."
          ],
          news: {
            id: 19,
            message: news["19"].text
          }
        },
        {
          from: 10300000,
          to: 10200000,
          consequences: [
            "Unable to meet all of our legal responsibilities.  Will  result in significantly higher service delivery and legal costs for the Council."
          ],
          news: {
            id: 20,
            message: news["20"].text
          }
        }
      ]
    },
    {
      sno: 7,
      name: "Transport, Highways & Parking",
      min: 80000000,
      max: 106000000,
      defaultValue: 100100000,
      value: 100100000,
      step: 100000,
      slug: "children_social_work",
      risk: {
        low: {
          from: 100100001,
          to: 106000000
        },
        medium: {
          from: 90100001,
          to: 100100000
        },
        high: {
          from: 80000000,
          to: 90100000
        }
      },
      info: [
        "Service is responsible for projects and initiatives to improve connectivity and road safety for the city across all modes of travel. Also for maintenance of roads and streets, traffic management and car parks and enforcing rights of way."
      ],
      triggers: [{
          from: 105100000,
          to: 106000000,
          consequences: [
            "Will be able to invest more in all transport options including sustainable transport alternatives which support our carbon zero commitment.  This will have a positive impact on air quality and the health and wellbeing of residents."
          ],
          news: {
            id: 21,
            message: news["21"].text
          }
        },
        {
          from: 95100000,
          to: 90100000,
          consequences: [
            "Investment options more limited so initiatives for improving transport will have to progress more slowly.  May have to increase fees and charges for on-street and off-street parking."
          ],
          news: {
            id: 22,
            message: news["22"].text
          }
        },
        {
          from: 90100000,
          to: 80100000,
          consequences: [
            "Investment in more sustainable transport and improving connectivity significantly impacted with some projects put on hold. Fees and charges for parking and Clean Air Zone increased to try and offset budget reduction."
          ],
          news: {
            id: 23,
            message: news["23"].text
          }
        },
        {
          from: 80100000,
          to: 80000000,
          consequences: [
            "Minimum investment in transport improvements with knock on effect on congestion, carbon emissions and road safety."
          ],
          news: {
            id: 24,
            message: news["24"].text
          }
        }
      ]
    },
    {
      sno: 8,
      name: "Planning & Development",
      min: 4800000,
      max: 18300000,
      defaultValue: 13000000,
      value: 13000000,
      step: 100000,
      slug: "councillor_pay_expenses",
      risk: {
        low: {
          from: 13000001,
          to: 18300000
        },
        medium: {
          from: 8000001,
          to: 13000000
        },
        high: {
          from: 4800000,
          to: 8000000
        }
      },
      info: [
        "Services to deliver regeneration, economic growth and housing across the city to ensure Birmingham continues to be a prosperous and  thriving City from which all of our residents benefit.  Also covers services which manage Council properties."
      ],
      triggers: [{
          from: 18000000,
          to: 18300000,
          consequences: [
            "More regeneration in local areas, creation of more jobs and training opportunities. Increased speed in processing of planning applications, additional support provided to applicants."
          ],
          news: {
            id: 25,
            message: news["25"].text
          }
        },
        {
          from: 10000000,
          to: 8000000,
          consequences: [
            "Less development across the cities slowing down regeneration and economic growth. Increase in time taken to process planning applications."
          ],
          news: {
            id: 26,
            message: news["26"].text
          }
        },
        {
          from: 8000000,
          to: 5000000,
          consequences: [
            "Pace of economic growth across the City more significantly impacted with fewer jobs and training opportunities created. Less investment in public realm.  Substantial delays in processing planning applications."
          ],
          news: {
            id: 27,
            message: news["27"].text
          }
        },
        {
          from: 5000000,
          to: 4800000,
          consequences: [
            "Economic growth across the city slows down considerably.  Significant negative impact on inward investment from private sector. Fewer jobs and training opportunities created."
          ],
          news: {
            id: 28,
            message: news["28"].text
          }
        }
      ]
    },
    {
      sno: 9,
      name: "Maintaining Neighbourhoods",
      min: 15500000,
      max: 21800000,
      defaultValue: 19600000,
      value: 19600000,
      step: 100000,
      slug: "landscape_services",
      risk: {
        low: {
          from: 19600001,
          to: 21800000
        },
        medium: {
          from: 17600001,
          to: 19600000
        },
        high: {
          from: 15500000,
          to: 17600000
        }
      },
      info: [
        "Services including the provision of community centres, advice services relating to welfare and benefits issues, and providing support to local communities and councillors within their neighbourhoods."
      ],
      triggers: [{
          from: 21600000,
          to: 21800000,
          consequences: [
            "Community Centres able to make more rooms available for free to community groups.  Increase in other support to communities and neighbourhoods."
          ],
          news: {
            id: 29,
            message: news["29"].text
          }
        },
        {
          from: 18600000,
          to: 17600000,
          consequences: [
            "Increase in room hire charges to community groups by 20% or reduced opening hours.  Reduction in welfare and benefits advice service provision."
          ],
          news: {
            id: 30,
            message: news["30"].text
          }
        },
        {
          from: 17600000,
          to: 15600000,
          consequences: [
            "More significant reduction in advice services.  More community centres with reduced opening hours and some closed."
          ],
          news: {
            id: 31,
            message: news["31"].text
          }
        },
        {
          from: 15600000,
          to: 15500000,
          consequences: [
            "More community centres closed and much less support to and engagement with communities overall.  May start to impact on wellbeing and cohesion.  Substantial reduction in welfare and benefits advice."
          ],
          news: {
            id: 32,
            message: news["32"].text
          }
        }
      ]
    },
    {
      sno: 10,
      name: "Enforcement & Regulation",
      min: 1700000,
      max: 3100000,
      defaultValue: 2400000,
      value: 2400000,
      step: 100000,
      slug: "office_accommodation",
      risk: {
        low: {
          from: 2400001,
          to: 3100000
        },
        medium: {
          from: 2200001,
          to: 2400000
        },
        high: {
          from: 1700000,
          to: 2200000
        }
      },
      info: [
        "Wide range of services covering Bereavement (Burials and Cremation), provision of markets, pest control, Trading Standards and Environmental Health Enforcement.  Other services aimed at tackling criminality for example  Illegal Money Laundering and those under the Proceeds of Crime Act."
      ],
      triggers: [{
          from: 2900000,
          to: 3100000,
          consequences: [
            "Increase our abilities to meet our legal responsibilities across the regulation and enforcement service areas including food hygiene, infection control, registration services, and coroners."
          ],
          news: {
            id: 33,
            message: news["33"].text
          }
        },
        {
          from: 2300000,
          to: 2200000,
          consequences: [
            "This will result in delays in registration of births, deaths and marriages, food hygiene inspections and in carrying out cremations and burials.  Some reduction in services including criminal investigations into fraud, financial abuse of the elderly and scam targeting. Maintenance of cemeteries will need to reduce leading to long unkempt grass and a poor environment for visitors."
          ],
          news: {
            id: 34,
            message: news["34"].text
          }
        },
        {
          from: 2200000,
          to: 1900000,
          consequences: [
            "Deterioration in services with possible negative impact on residents safety and well being.  Could lead to government intervention to oversee the delivery of statutory requirements such as food safety and health and safety matters.   Charges would have to be made for services such as pest control and increased charges would need to be levied for cremations and burials."
          ],
          news: {
            id: 35,
            message: news["35"].text
          }
        },
        {
          from: 1900000,
          to: 1700000,
          consequences: [
            "Some services would have to stop and those services required by law would have to be rationed and prioritised.  This would lead to legal challenges which would further divert resources."
          ],
          news: {
            id: 36,
            message: news["36"].text
          }
        }
      ]
    },
    {
      sno: 11,
      name: "Culture, Leisure, Parks & Libraries",
      min: 43000000,
      max: 58000000,
      defaultValue: 54800000,
      value: 54800000,
      step: 100000,
      slug: "capital_repayments_and_costs",
      risk: {
        low: {
          from: 54800001,
          to: 58000000,
        },
        medium: {
          from: 48800001,
          to: 54800000
        },
        high: {
          from: 43000000,
          to: 48800000
        }
      },
      info: [
        "Services include the provision and maintenance of parks and open spaces, libraries as well as services related to the City’s leisure and culture offer including museums and major events."
      ],
      triggers: [{
          from: 57800000,
          to: 58000000,
          consequences: [
            "Budget increase used to fund service improvements including an increase in warden patrols in parks, an increase grass cutting frequency and an increase in the allotments team. Will also fund investment in tree planting across the City in support of our carbon zero commitment, and in play areas and will be able to reduce the level of fees and charges to enable greater use of the parks, pitches and other green spaces. Improved and modernised sporting and cultural facilities and equipment, more services available free of charge and extended range of services"
          ],
          news: {
            id: 13,
            message: news["13"].text
          }
        },
        {
          from: 51800000,
          to: 48800000,
          consequences: [
            "Reduced maintenance at parks, open spaces, play areas and pitches with possible closure of some. Increase in fees and charges at leisure centres and wellbeing sites and reduced opening hours including at libraries. Negative impact on health and wellbeing or residents and on the Commonwealth Games Legacy."
          ],
          news: {
            id: 14,
            message: news["14"].text
          }
        },
        {
          from: 48800000,
          to: 43800000,
          consequences: [
            "Significant reduction in maintenance at parks, open spaces, play areas and pitches. All parks activities stopped other than health and safety inspections. Closure of some leisure centres, wellbeing sites and libraries. Reduced funding to cultural and major sporting events. Negative impact on health and wellbeing of residents and on the Commonwealth Games Legacy."
          ],
          news: {
            id: 15,
            message: news["15"].text
          }
        },
        {
          from: 43800000,
          to: 43000000,
          consequences: [
            "Substantial reduction in maintenance at parks, open spaces, play areas and pitches. All parks activities stopped other than Health and safety inspections and would have to mothball some facilities. Closure of significant number of leisure centres, wellbeing sites and libraries. Funding of major sporting and cultural events/organisations stopped. Devastating impact on health and wellbeing of residents and on the Commonwealth Games Legacy."
          ],
          news: {
            id: 16,
            message: news["16"].text
          }
        }
      ]
    },
    {
      sno: 12,
      name: "Community Safety & Resilience",
      min: 2300000,
      max: 3500000,
      defaultValue: 3100000,
      value: 3100000,
      step: 100000,
      slug: "community_safety",
      risk: {
        low: {
          from: 3100001,
          to: 3500000
        },
        medium: {
          from: 2800001,
          to: 3100000
        },
        high: {
          from: 2300000,
          to: 2800000
        }
      },
      info: [
        "Service brings together Emergency Planning and Resilience, Community Safety and the Prevent/Counter Extremism functions to ensure individuals and communities are protected from, equipped to cope with, and have increased capacity to resist crime, anti-social behaviour and extremism.",
      ],
      triggers: [{
          from: 3300000,
          to: 3500000,
          consequences: [
            "Investment in the community safety team will better allow them to meet increasing demand to deal with crime and safety issues under our legal responsibilities for reducing crime and improving community safety."
          ],
          news: {
            id: 37,
            message: news["37"].text
          }
        },
        {
          from: 2900000,
          to: 2800000,
          consequences: [
            "This will lead to some reductions in resources, for example in the Community Safety Team, limiting our capability to meet our legal responsibilities for reducing crime and improving community safety."
          ],
          news: {
            id: 38,
            message: news["38"].text
          }
        },
        {
          from: 2800000,
          to: 2500000,
          consequences: [
            "This will lead to further reductions in resources across all of the teams reducing our capability to meet our legal responsibilities and taking preventative action."
          ],
          news: {
            id: 39,
            message: news["39"].text
          }
        },
        {
          from: 2500000,
          to: 2300000,
          consequences: [
            "Could result in an increase in community safety issues including anti-social behaviour."
          ],
          news: {
            id: 40,
            message: news["40"].text
          }
        }
      ]
    },
    {
      sno: 13,
      name: "Core Business Function",
      min: 54500000,
      max: 72500000,
      defaultValue: 68900000,
      value: 68900000,
      step: 100000,
      slug: "roads_bridges_harbours",
      risk: {
        low: {
          from: 68900001,
          to: 72500000
        },
        medium: {
          from: 61900001,
          to: 68900000
        },
        high: {
          from: 54500000,
          to: 61900000
        }
      },
      info: [
        "These services include core business functions critical to the success of any large organisation and include Human Resources, Finance, Procurement and Digital services."
      ],
      triggers: [{
          from: 72400000,
          to: 72500000,
          consequences: [
            "IT provision and connectivity across the Council and City would improve.  Recruitment would mean more job opportunities for the people of Birmingham and improved service delivery across the Council."
          ],
          news: {
            id: 41,
            message: news["41"].text
          }
        },
        {
          from: 65400000,
          to: 61900000,
          consequences: [
            "Some service delivery improvements put on hold due to lack of funding.  Less able to respond to any increase in demand for services."
          ],
          news: {
            id: 42,
            message: news["42"].text
          }
        },
        {
          from: 61900000,
          to: 54900000,
          consequences: [
            "A significant impact on services including harder to contact the Council with a reduction of staff and opening hours for the Contact Centre, reduction in IT equipment in libraries and connectivity across the City."
          ],
          news: {
            id: 43,
            message: news["43"].text
          }
        },
        {
          from: 54900000,
          to: 54500000,
          consequences: [
            "We would no longer be able to meet minimum levels of service provision required by law eg production of the Council's financial accounts would not be completed in time.  More significant impact on service delivery affecting the efficiency of the Council."
          ],
          news: {
            id: 44,
            message: news["44"].text
          }
        }
      ]
    },
    {
      sno: 5,
      name: "Public Health",
      min: 0,
      max: 0,
      locked: true,
      defaultValue: 0,
      value: 0,
      step: 0,
      slug: "public_transport",
      risk: {
        low: {
          from: 0,
          to: 0,
        },
        medium: {
          from: 0,
          to: 0
        },
        high: {
          from: 0,
          to: 0
        }
      },
      info: [
        "The service responsible for improving the health of their local population and for public health services including disease control, sexual health services and services aimed at reducing drug and alcohol misuse. Public Health has a net nil budget. The service expenditure is fully funded from a grant from Central Government. This funding is protected and cannot be diverted elsewhere."
      ],
      triggers: []
    },
    {
      sno: 14,
      name: "Other Corporate Budget",
      min: -129900000,
      max: -129900000,
      locked: true,
      defaultValue: -129900000,
      value: -129900000,
      negativeValueForDisplay: 129900000,
      step: 0,
      slug: "live_life_aberdeenshire",
      risk: {
        low: {
          from: 0,
          to: 0,
        },
        medium: {
          from: 0,
          to: 0
        },
        high: {
          from: 0,
          to: 0
        }
      },
      info: [
        "This budget is mainly grant provided to the Council to offset the business rates reliefs that were provided to the leisure and hospitality industries during the COVID pandemic. The grants offset any loss of business rates income."
      ],
      triggers: []
    }
  ]
};

export default data;