export const smallNumber = (number) => {
  number = Number(number);

  if (isNaN(number)) {
    // throw new Error('Not a Number!');
    // console.error('Not a Number');
    return;
  }

  return number < 1000 ? number : number < 1000000 ? (number / 1000).toFixed(0) + 'k' : number < 1000000000 ? (number / 1000000).toFixed(1) + 'm' : number < 1000000000000 ? (number / 1000000000).toFixed(1) + 'b' : 'Err'
};

export const smallBudgetNumber = (number) => {
  number = Number(number);

  if (isNaN(number)) {
    // throw new Error('Not a Number!');
    // console.error('Not a Number');
    return;
  }

  return number < 1000 ? number : number < 1000000 ? (number / 1000).toFixed(0) + 'k' : number < 1000000000 ? (number / 1000000).toFixed(2) + 'm' : number < 1000000000000 ? (number / 1000000000).toFixed(1) + 'b' : 'Err'
};

export const formattedNumber = number => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const calculateTotalByKey = (areas, key) => areas.reduce((a, b) => a + b[key], 0)