const SHOW_NEWS = 'SHOW_NEWS'
const HIDE_NEWS = 'HIDE_NEWS'
const STORE_NEWS = 'STORE_NEWS'
const SHOW_CONGRATS = 'SHOW_CONGRATS'
const HIDE_CONGRATS = 'HIDE_CONGRATS'
const REMOVE_NEWS = 'REMOVE_NEWS';
const LOAD_INITIAL_NEWS_DATA_SUCCESS = 'LOAD_INITIAL_NEWS_DATA_SUCCESS'

export {
  SHOW_NEWS,
  HIDE_NEWS,
  STORE_NEWS,
  SHOW_CONGRATS,
  HIDE_CONGRATS,
  LOAD_INITIAL_NEWS_DATA_SUCCESS,
  REMOVE_NEWS,
}
