import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Grid = ({ withSpacing = false, children }) => {
  const gridClassnames = classNames('l-grid', {
    'l-grid--no-spacing': !withSpacing,
  });

  return <div className={gridClassnames}>{children}</div>;
};

Grid.propTypes = {
  noSpacing: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Grid.defaultProps = {
  noSpacing: false,
};

export default Grid;
