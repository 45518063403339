import React, { Component } from 'react';
import InputRange from 'react-input-range';
import classnames from 'classnames';

import 'react-input-range/lib/css/index.css';

class RangeInput extends Component {
  get barColor() {
    return this.props.barColor;
  }

  handleInputChange = (value) => {
    const { max, min } = this.props;
    this.setState({
      barColor: (value - min) / (max - min)
    });
  }

  showInfo = () => {
    this.setState({
      infoCard: true,
    });
  }

  handleRangeChange = (value) => {
    this.props.changeHandler(value);
  }

  render() {
    const {
      budget,
      className,
      min,
      max,
      value,
      step,
    } = this.props;


    // Get 5% of max tax
    const maxTaxValue = budget && budget.council.max_tax;
    const refinedPercentage = budget && ((budget.council.value_tax / budget.council.max_tax) * 100) / 20
    console.log(refinedPercentage)
    return (
      budget ? (
        <div className={classnames('c-range', className)}>
          <div className="c-range__min-max-wrap">
            <span className="c-range__min-value">{budget && '0%'}</span>
            <span className="c-range__max-value">{budget && '4.99%'}</span>
          </div>
          <div
            className={classnames("c-range__tooltip c-range__tooltip--desktop", {
              'c-range__tooltip--cap': refinedPercentage > 4.9
            })}
            style={{ left: `calc(${((budget.council.value_tax / budget.council.max_tax) * 100).toFixed(1)}% + 4px)` }}
          >
            <small className="c-range__tooltip-label--mobile">Council Tax<br /></small>
            <b>{refinedPercentage === 5 ? 4.99 : refinedPercentage.toFixed(1)}%</b>
          </div>
          <InputRange
            formatLabel={() => budget && (
              <div>
                <p style={{ marginBottom: 0 }}>Council Tax</p>
                <span>
                  {((budget.council.value_tax / budget.council.max_tax) * 100).toFixed(1)}%
                </span>
              </div>
            )}
            minValue={budget ? budget.council.initial_tax : min}
            maxValue={maxTaxValue}
            value={budget && budget.council.value_tax ? budget.council.value_tax : 0}
            onChange={(value) => this.handleRangeChange(value)}
            // onChangeStart={(value) => this.handleRangeChange(value)}
            step={(this.props.step) ? this.props.step : (maxTaxValue) / 10} // Divide the 8% of max tax by 8 to get 10 increments (0.5% increase)
          />
        </div>
      )
        : (

          <InputRange
            minValue={min}
            maxValue={max}
            value={value}
            onChange={(value) => this.handleRangeChange(value)}
            step={step}
          />
        )
    )
  }
}

export default RangeInput;
