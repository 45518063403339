import React, { Component } from 'react';
import { connect } from 'react-redux';

import Divide from '../../components/common/divide/Divide';
import BudgetTotalBar from '../../components/budget-total-bar/BudgetTotalBar';
import SpendingAreaCard from '../../components/common/cards/spending-area-card/SpendingAreaCard';
import IncomeAreaSectionSubmitted from '../../components/sections/income-areas-section/IncomeAreasSectionSubmitted';
import PeoplesBudgetStats from '../../components/sections/peoples-budget-stats/PeoplesBudgetStats';
import PeoplesBudgetEntries from '../../components/sections/peoples-budget-entries/PeoplesBudgetEntries';
import Button from '../../components/common/button/Button';

import { Cell, Grid } from '../../components/layout';

import Secondary from '../../layouts/Secondary';

import {
  budgetActions,
} from '../../reducks'

import { smallNumber, calculateTotalByKey } from '../../helpers/number';
import { calculateRisk } from '../../helpers/risk';
import { getConsequences } from '../../helpers/impacts';
import Modal from '../../components/common/modal/Modal';

class PeoplesBudget extends Component {
  state = {
    isCouncilInfoModalActive: false,
    spendingAreaInfoBlocks: [],
    showAllBudgets: false,
    currentBudgetEntryPage: 1,
  }

  get peoplesTotalAmountNow() {
    const { _budget } = this.props;

    return _budget.spending_areas.reduce((prev, elem) => {
      return prev + Number(_budget.peoples_averages[elem.slug])
    }, 0) || 0;
  }

  get councilTaxFigure() {
    const { _budget } = this.props;

    return Number(_budget.peoples_averages.council_tax);
  }

  get peopleTotal() {
    const {
      _budget: {
        initial_total,
        council,
        peoples_averages,
      }
    } = this.props;

    const keys = [1, 2, 3, 4];
    const averages = keys.map(key => peoples_averages[`council_${key}`]);
    const values = keys.map(key => Number(council[`council_${key}_val`]));

    let councilTotal = 0;

    averages.forEach((average, index) => {
      const value = average >= 0.5 ? values[index] : 0;
      councilTotal += value;
    });

    const sum = (initial_total - this.peoplesTotalAmountNow) + (this.councilTaxFigure + councilTotal);

    return sum;
  }

  get spendingAreas() {
    return this.props._budget.spending_areas;
  }

  componentDidMount() {
    /**
     * If the user navigates to this page from the homepage via the
     * view all budgets link then we anchor them to the relevant section.
     */
    const { hash } = this.props.location;
    const selector = hash.split('#').join("");

    if (hash === '') {
      window.scroll(0, 0);
    } else if (document.getElementById(selector)) {
      window.scroll(0, document.getElementById(selector).offsetTop);
    }

    let spendingAreasInfoContent = this.spendingAreas.map(item => {
      return {
        ...item,
        isInfoCardActive: false,
        isImpactsCardActive: false,
        active: false,
      }
    });

    this.setState({
      spendingAreaInfoBlocks: spendingAreasInfoContent,
    });
  }

  getPotentialImpacts = (cons, value, max) => {
    if (cons && cons.length > 0) {
      cons = cons.filter((item) => {
        const total = max - value;
        return item.to <= total || total >= item.from;
      });
    }
    return cons;
  }

  getRiskLevel = (cons, value, max, min) => {
    if (cons && cons.length > 0) {
      cons = cons.filter((item) => {
        const total = (max - min) - value;
        return item.to <= total || total >= item.from;
      });
    }

    return cons;
  }

  calculatePeopleTotal = () => {
    const {
      _budget: {
        peoples_averages: {
          council_tax,
        }
      }
    } = this.props;

    return council_tax ? this.peopleTotal : 0
  }

  toggleCouncilModal = () => {
    this.setState({
      isCouncilInfoModalActive: !this.state.isCouncilInfoModalActive,
    });
  }

  toggleInfoCard = (slug, cardType) => {
    const index = this.state.spendingAreaInfoBlocks.findIndex(item => item.slug === slug);
    const blocks = [...this.state.spendingAreaInfoBlocks];

    if (cardType === 'info') {
      blocks[index].isInfoCardActive = !blocks[index].isInfoCardActive;
    } else if (cardType === 'impacts') {
      blocks[index].isImpactsCardActive = !blocks[index].isImpactsCardActive;
    }

    if (blocks[index].isInfoCardActive || blocks[index].isImpactsCardActive) {
      window.scroll(0, document.getElementById('service-areas-section').offsetTop - 50);
    }

    this.setState({
      spendingAreaInfoBlocks: blocks,
    });
  }

  render() {
    const {
      _budget
    } = this.props;

    const {
      spendingAreaInfoBlocks
    } = this.state;

    const totalMaxValue = calculateTotalByKey(_budget.spending_areas, 'max');
    const totalMinValue = calculateTotalByKey(_budget.spending_areas, 'min');
    const currentTargetPercentage = ((812000000 - _budget.initial_total) / (totalMaxValue - totalMinValue)) * 100;
    const spendingTargetWidthValue = currentTargetPercentage < 100 ? currentTargetPercentage : 100;

    return (
      <Secondary>
        {
          this.state.isCouncilInfoModalActive &&
          <Modal handleClose={() => this.toggleCouncilModal()}>
            <i className="c-modal__budget-icon fas fa-info" />

            <ul className="c-modal__list">
              <li>1% added to Council Tax would mean a band D property going up £12.41 per year.</li>
              <li>2% added to Council Tax would mean a band D property going up £24.82 per year.</li>
              <li>3% added to Council Tax would mean a band D property going up £37.22 per year.</li>
              <li>4% added to Council Tax would mean a band D property going up £49.63 per year.</li>
              <li>4.79% added to Council Tax would mean a band D property going up £59.43 per year.</li>
            </ul>
          </Modal>
        }
        <div className="l-container l-container--padded">
          <Grid>
            <Cell size={{ desktop: '9', tablet: '9', phone: '6' }}>
              <h3>Budget <span className="light">Challenge</span></h3>
              <h1>People's <span className="light">Budget</span> </h1>
              <p>
                The following summarises the budgets set for Birmingham by all residents who have taken the challenge.
              </p>
            </Cell>

            <Cell size={{ desktop: '12', tablet: '6', phone: '6' }}>
              <Divide isLight={true} isLarge={true} />
            </Cell>
          </Grid>

          <div className="l-container l-container--padded u-margin-bottom@2">
            <Grid>
              <Cell size={{ desktop: '6', tablet: '6', phone: '6' }}>
                <BudgetTotalBar
                  isRed={true}
                  widthValue={spendingTargetWidthValue}
                  minLabel={`£${smallNumber(totalMinValue)}`}
                  maxLabel={`£${smallNumber(totalMaxValue)}`}
                >
                  <h2 className="u-text-dark-grey c-total-bar-header__title">
                    <span className="c-total-bar-header__sub-title">Annual Spending</span><br />
                    £812m
                  </h2>
                </BudgetTotalBar>
              </Cell>

              <Cell size={{ desktop: '6', tablet: '12', phone: '6' }} alignment="bottom">
                <BudgetTotalBar
                  toggleInfoModal={this.toggleCouncilModal}
                  isTax
                  minLabel='0%'
                  maxLabel='4.79%'
                  value={`${(_budget.peoples_averages.council_tax === _budget.council.max_tax
                    ? 4.79
                    : _budget.peoples_averages.council_tax / (_budget.council.max_tax / 5)
                  ).toFixed(1)}%`}
                  widthValue={`${(_budget.peoples_averages.council_tax / _budget.council.max_tax) * 100}`}
                >

                  <h2 className="u-text-dark-grey c-total-bar-header__title">
                    <span className="c-total-bar-header__sub-title">Council Tax Increase</span><br />
                    £ {smallNumber(Number(_budget.peoples_averages.council_tax))}
                  </h2>
                </BudgetTotalBar>
              </Cell>
            </Grid>
          </div>
        </div>

        <section className="l-section l-section--savings" id="service-areas-section">
          <div className="l-section__header">
            <Grid>
              <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
                <h2 className="l-section__title">Service Areas</h2>
                <p className="l-section__sub-title">
                  Help meet your target by creating savings in key areas
                </p>
              </Cell>
            </Grid>
          </div>

          <div style={{ position: 'relative' }}>
            <Grid>
              {
                (_budget && _budget.peoples_averages) &&
                _budget.spending_areas.map((area, key) => {
                  const potentialImpacts = this.getPotentialImpacts(area.triggers, area.value, area.max);

                  return (
                    <Cell size={{ desktop: '4', tablet: '6', phone: '6' }} key={key}>
                      <SpendingAreaCard
                        className="c-card--summary"
                        key={key}
                        cons={potentialImpacts}
                        color={area.color}
                        name={area.name}
                        locked={area.locked}
                        value={this.props._budget.peoples_averages[area.slug]}
                        max={area.max}
                        info={area.info}
                        icon={area.icon}
                        isSummary={!area.locked && true}
                        step={area.step}
                        slug={area.slug}
                        toggleInfo={this.toggleInfoCard}
                        risk={!area.locked && calculateRisk(area, this.props._budget.peoples_averages[area.slug])}
                      />
                      {
                        (spendingAreaInfoBlocks[key] && spendingAreaInfoBlocks[key].isInfoCardActive) &&
                        <div className="c-info-block">
                          <div className="c-info-block__header">
                            <i className="c-info-block__close fa fa-times" onClick={() => this.toggleInfoCard(area.slug, 'info')} />
                            <h2 className="c-info-block__title">{area.name} - <i className='fa fa-info-circle' /> Information</h2>
                          </div>

                          <div className="c-info-block__content">
                            <p>{area.info}</p>
                          </div>
                        </div>
                      }
                      {
                        (spendingAreaInfoBlocks[key] && spendingAreaInfoBlocks[key].isImpactsCardActive) &&
                        <div className="c-info-block" id="impacts-table">
                          <div className="c-info-block__header">
                            <i className="c-info-block__close fa fa-times" onClick={() => this.toggleInfoCard(area.slug, 'impacts')} />
                            <h2 className="c-info-block__title">{area.name} - Budget Impacts</h2>
                          </div>

                          <div className="c-info-block__content">
                            {
                              potentialImpacts.length > 0 && potentialImpacts.map((item, k1) => {
                                return (
                                  <div key={k1} className='cons-list-item'>
                                    {getConsequences(item, area)}
                                    <ul>
                                      {item.consequences.map((neCons, k) => {
                                        return <li key={k}>{neCons}</li>
                                      })}
                                    </ul>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      }
                    </Cell>
                  )
                })
              }
            </Grid>
          </div>

          <Divide isLarge={true} isLight={true} />
        </section>

        <IncomeAreaSectionSubmitted
          budget={_budget.peoples_averages}
          councilBudget={_budget.council}
          selectedBudget={_budget.selected_budget}
          isSummary={true}
        />

        <div className="u-margin-top@2">
          <PeoplesBudgetStats location={this.props.location} />
        </div>

        <Divide isLight={true} isLarge={true} />

        <div className="budget-entries" id="peoples-entries">
          <Grid>
            <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
              <Grid>
                <Cell size={{ desktop: '8', tablet: '8', phone: '6' }}>
                  <h2>Latest Budgets Submitted</h2>
                </Cell>
                <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
                  {
                    !_budget.preventLoadMoreBudgets &&
                    <div>
                      <Button
                        onClick={() => {
                          this.props.getPeople(this.state.currentBudgetEntryPage * 10);
                          this.setState({
                            currentBudgetEntryPage: this.state.currentBudgetEntryPage + 1,
                          })
                        }}
                      >
                        <i className="fas fa-chevron-right u-margin-right" />
                        Load More Budgets
                      </Button>
                    </div>
                  }
                </Cell>
              </Grid>
            </Cell>
          </Grid>

          <PeoplesBudgetEntries
            budgets={_budget.peoples_budgets}
            className="c-budget-entry--inline"
          />
        </div>
      </Secondary>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    _budget: state.budget
  }
}, (dispatch, ownProps) => {
  return {
    getPeople: (after = null) => dispatch(budgetActions.loadPeopleAction(after)),
    changeCouncilCategoryAction: (category, value) => dispatch(budgetActions.changeCouncilCategoryAction(category, value))
  }
})(PeoplesBudget)
