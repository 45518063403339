import React from "react";

import ServiceAreaCard from "../../common/cards/service-area-card/ServiceAreaCard";
import { Cell, Grid } from "../../layout";

const IncomeAreasSection = ({ budget, changeCouncilCategoryAction, status, isSummary = false }) => {
  const cardStatus = status ? status : budget.council;
  const incomeKeys = [1, 2, 3, 4, 5];
  const investmentKeys = [6, 7, 8];

  return (
    <div>
      <section
        className="l-section
       l-section--income"
      >
        <Grid>
          <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
            <div className="l-section__header">
              <h2 className="l-section__title">Income</h2>
              <p className="l-section__sub-title">
                You can add income to your budget, helping to reduce the savings you need to find.
              </p>
            </div>
          </Cell>
        </Grid>

        <Grid>
          {incomeKeys.map(key => (
            <Cell size={{ desktop: "4", tablet: "6", phone: "6" }} key={key}>
              <ServiceAreaCard
                status={cardStatus[`council_${key}`]}
                k={1}
                name={budget.council[`council_${key}_name`]}
                value={budget.council[`council_${key}_val`]}
                info={budget.council[`council_${key}_info`]}
                step={budget.council[`council_${key}_val`] / 10}
                isSummary={isSummary}
                onChange={e => changeCouncilCategoryAction(`council_${key}`, e)}
              />
            </Cell>
          ))}
        </Grid>
      </section>

      {/*<section className="l-section l-section--investment">
        <Grid>
          <Cell size={{ desktop: "12", tablet: "12", phone: "6" }}>
            <div className="l-section__header">
              <h2 className="l-section__title">Investment</h2>
              <p className="l-section__sub-title">
                You can choose to invest additional budget in some key areas. Remember this will
                impact your savings target!
              </p>
            </div>
          </Cell>
        </Grid>

         <Grid>
          {investmentKeys.map(key => (
            <Cell size={{ desktop: "4", tablet: "6", phone: "6" }} key={key}>
              <ServiceAreaCard
                status={cardStatus[`council_${key}`]}
                k={1}
                name={budget.council[`council_${key}_name`]}
                value={budget.council[`council_${key}_val`]}
                info={budget.council[`council_${key}_info`]}
                step={budget.council[`council_${key}_val`] / 10}
                isSummary={isSummary}
                isInvestment={true}
                label="Investment"
                onChange={e => changeCouncilCategoryAction(`council_${key}`, e, true)}
              />
            </Cell>
          ))}
        </Grid> 
      </section>*/}
    </div>
  );
};

export default IncomeAreasSection;
