import React from 'react';
import classnames from 'classnames';

export default ({ isLarge, isXLarge, isLight, className }) => (
  <div className={classnames('c-divide', className, {
    'c-divide--x-large': isXLarge,
    'c-divide--large': isLarge,
    'c-divide--light': isLight
  })} />
);
