import React from 'react';
import { smallNumber } from './number';

export const getConsequences = (item, area) => {
  return (
    <div>
      {
        item.consequences.length > 0 &&
        <b>
          {
            area.defaultValue !== item.from && item.to > item.from ? `Increases of more than £${smallNumber(item.from - area.defaultValue)}`
              : (area.defaultValue !== item.from && item.to < item.from) &&
              `Reductions of more than £${smallNumber(area.defaultValue - item.from)}`
          }
        </b>
      }
      {
        <b>
          {
            area.defaultValue === item.from && item.to > item.from && area.value < item.to && `Increases of up to £${smallNumber(item.to - item.from)}`
          }
        </b>
      }

      {
        item.consequences.length > 0 &&
        <b>
          {
            area.defaultValue === item.from && item.to > item.from && area.value > item.to ? `Increases of £${smallNumber(item.to - area.defaultValue)}`
              : (area.defaultValue === item.from && item.to < item.from || area.defaultValue !== item.from && item.to === item.from) &&
              `Reductions of £${smallNumber(area.defaultValue - item.to)}`
          }
        </b>
      }
    </div>
  )
}