import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container } from '../../layout';

const Header = ({
  children,
  className
}) => (
  <header className={classnames('c-header', className)}>
    <Container>
      {children}
    </Container>
  </header>
);

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default Header;
