import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  pageActions
} from '../../reducks';

export const Sidebar = ({
  page,
  toggleMenuAction
}) => {
  return (
    <div className={classnames('l-sidebar', { 'is-active': page.menuActive })}>
      <ul className="l-sidebar__links">
        <li>
          <span className="l-sidebar__faux-link" onClick={() => toggleMenuAction('/')}>
            Home
          </span>
        </li>
        <li>
          <span className="l-sidebar__faux-link" onClick={() => toggleMenuAction('/peoples-budget')}>
            The People's Budget
          </span>
        </li>
        {
          !moment().isAfter('2021-12-31') &&
          <li>
            <span className="l-sidebar__faux-link" onClick={() => toggleMenuAction('/set-your-budget')}>
              Take the Budget Challenge
            </span>
          </li>
        }
        <li>
          <a className="l-sidebar__faux-link" onClick={() => toggleMenuAction('/budget-dilemma')} >
            Budget Dilemma
          </a>
        </li>
      </ul>
    </div >
  )
}

export default connect((state, ownProps) => {
  return {
    page: state.page
  }
}, (dispatch, ownProps) => {
  return {
    toggleMenuAction: (path) => dispatch(pageActions.toggleMenuAction(path))
  }
})(Sidebar)

