import * as types from "./types";
import pc from "../../pc";

let ls = JSON.parse(localStorage.getItem("logged"));
let d2 = new Date();
if (ls == null || (d2.getTime() - ls.auth_time * 1000) / 86400000 > 1) {
  ls = null;
  localStorage.removeItem("logged");
}

const getInitialPostalFilters = d => {
  const districts = d.map(item => item.district);
  return districts
    .filter((v, i) => districts.indexOf(v) === i)
    .map(i => {
      return {
        name: i,
        selected: false
      };
    });
};

const slugify = text => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "_") // Replace spaces with -
    .replace(/(?!&)[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "_") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

const getInitialLocality = d => {
  const localities = d.map(item => item.locality_name);
  return localities
    .filter((v, i) => localities.indexOf(v) === i)
    .map(l => {
      return {
        key: slugify(l),
        name: l,
        selected: false
      };
    });
};

let initialState = {
  filter: {
    spending_areas: [{
        sno: 1,
        key: "health_social_care_partnership",
        name: "Adult Social Care",
        selected: true
      },
      {
        sno: 2,
        key: "secondary_school_education",
        name: "Children & Young People",
        selected: true
      },
      {
        sno: 3,
        key: "primary_school_education",
        name: "Education & Skills",
        selected: true
      },
      {
        sno: 4,
        key: "nursery_early_years",
        name: "Waste Collection & Cleaner Street",
        selected: true
      },
      {
        sno: 5,
        key: "public_transport",
        name: "Public Health",
        selected: true
      },
      {
        sno: 6,
        key: "additional_support_needs",
        name: "Housing & Homelessness",
        selected: true
      },
      {
        sno: 7,
        key: "children_social_work",
        name: "Transport, Highways & Parking",
        selected: true
      },
      {
        sno: 8,
        key: "councillor_pay_expenses",
        name: "Planning & Development",
        selected: true
      },
      {
        sno: 9,
        key: "landscape_services",
        name: "Maintaining Neighbourhoods",
        selected: true
      },
      {
        sno: 10,
        key: "office_accommodation",
        name: "Enforcement & Regulation",
        selected: true
      },
      {
        sno: 11,
        key: "capital_repayments_and_costs",
        name: "Culture, Leisure, Parks & Libraries",
        selected: true
      },
      {
        sno: 12,
        key: "community_safety",
        name: "Community Safety & Resilience",
        selected: true
      },
      {
        sno: 13,
        key: "roads_bridges_harbours",
        name: "Core Business Function",
        selected: true
      },
      {
        sno: 14,
        key: "live_life_aberdeenshire",
        name: "Other Corporate Budget",
        selected: true
      }
    ],
    types: [{
        key: "low_risk",
        name: "Low",
        selected: false
      },
      {
        key: "med_risk",
        name: "Med",
        selected: false
      },
      {
        key: "high_risk",
        name: "High",
        selected: false
      }
    ],
    gender: [{
        key: "Male",
        name: "Male",
        selected: false
      },
      {
        key: "Female",
        name: "Female",
        selected: false
      },
      {
        key: "Other",
        name: "Other",
        selected: false
      },
      {
        key: "Intersex",
        name: "Intersex",
        selected: false
      },
      {
        key: "Third Gender (Gender Queer, Gender Fluid and/or Non-Binary)",
        name: "Third Gender (Gender Queer, Gender Fluid and/or Non-Binary)",
        selected: false
      },
      {
        key: "Transgender Female",
        name: "Transgender Female",
        selected: false
      },
      {
        key: "Transgender Male",
        name: "Transgender Male",
        selected: false
      },
      {
        key: "Agender",
        name: "Agender",
        selected: false
      },
      {
        key: "Prefer Not to answer",
        name: "Prefer Not to answer",
        selected: false
      }
    ],
    age: [{
        name: "Under 16",
        selected: false
      },
      {
        name: "16-25",
        selected: false
      },
      {
        name: "26-40",
        selected: false
      },
      {
        name: "41-65",
        selected: false
      },
      {
        name: "Over 65",
        selected: false
      }
    ],
    locality: getInitialLocality(pc),
    postal: getInitialPostalFilters(pc)
  },

  fetched: [],
  displayed: {
    total_spend: 0,
    council_tax: 0,
    council_tax_percent: 0,
    budgets_submitted: 0,
    low_risk_budgets: 0,
    high_risk_budgets: 0,
    spending_areas: {
      health_social_care_partnership: 0,
      secondary_school_education: 0,
      primary_school_education: 0,
      nursery_early_years: 0,
      additional_support_needs: 0,
      children_social_work: 0,
      councillor_pay_expenses: 0,
      community_safety: 0,
      landscape_services: 0,
      live_life_aberdeenshire: 0,
      office_accommodation: 0,
      public_transport: 0,
      roads_bridges_harbours: 0,
      capital_repayments_and_costs: 0
    },
    council_1: 0,
    council_2: 0,
    council_3: 0,
    council_4: 0,
    council_5: 0
  },
  auth: {
    auth_time: ls != null ? ls.auth_time : null,
    auth_status: ls != null ? true : false,
    auth_error: false,
    auth_email: ls != null ? ls.auth_email : null,
    auth_token: ls != null ? ls.auth_token : null
  }
};

let clearState = {
  filter: {
    spending_areas: [{
        sno: 1,
        key: "health_social_care_partnership",
        name: "Adult Social Care",
        selected: true
      },
      {
        sno: 2,
        key: "secondary_school_education",
        name: "Children & Young People",
        selected: true
      },
      {
        sno: 3,
        key: "primary_school_education",
        name: "Education & Skills",
        selected: true
      },
      {
        sno: 4,
        key: "nursery_early_years",
        name: "Waste Collection & Cleaner Street",
        selected: true
      },
      {
        sno: 5,
        key: "public_transport",
        name: "Public Health",
        selected: true
      },
      {
        sno: 6,
        key: "additional_support_needs",
        name: "Housing & Homelessness",
        selected: true
      },
      {
        sno: 7,
        key: "children_social_work",
        name: "Transport, Highways & Parking",
        selected: true
      },
      {
        sno: 8,
        key: "councillor_pay_expenses",
        name: "Planning & Development",
        selected: true
      },
      {
        sno: 9,
        key: "landscape_services",
        name: "Maintaining Neighbourhoods",
        selected: true
      },
      {
        sno: 10,
        key: "office_accommodation",
        name: "Enforcement & Regulation",
        selected: true
      },
      {
        sno: 11,
        key: "capital_repayments_and_costs",
        name: "Culture, Leisure, Parks & Libraries",
        selected: true
      },
      {
        sno: 12,
        key: "community_safety",
        name: "Community Safety & Resilience",
        selected: true
      },
      {
        sno: 13,
        key: "roads_bridges_harbours",
        name: "Core Business Function",
        selected: true
      },
      {
        sno: 14,
        key: "live_life_aberdeenshire",
        name: "Other Corporate Budget",
        selected: true
      }
    ],
    types: [{
        key: "low_risk",
        name: "Low",
        selected: false
      },
      {
        key: "med_risk",
        name: "Med",
        selected: false
      },
      {
        key: "high_risk",
        name: "High",
        selected: false
      }
    ],
    gender: [{
        key: "Male",
        name: "Male",
        selected: false
      },
      {
        key: "Female",
        name: "Female",
        selected: false
      },
      {
        key: "Other",
        name: "Other",
        selected: false
      },
      {
        key: "Intersex",
        name: "Intersex",
        selected: false
      },
      {
        key: "Third Gender (Gender Queer, Gender Fluid and/or Non-Binary)",
        name: "Third Gender (Gender Queer, Gender Fluid and/or Non-Binary)",
        selected: false
      },
      {
        key: "Transgender Female",
        name: "Transgender Female",
        selected: false
      },
      {
        key: "Transgender Male",
        name: "Transgender Male",
        selected: false
      },
      {
        key: "Agender",
        name: "Agender",
        selected: false
      },
      {
        key: "Prefer Not to answer",
        name: "Prefer Not to answer",
        selected: false
      }
    ],
    age: [{
        name: "Under 16",
        selected: false
      },
      {
        name: "16-25",
        selected: false
      },
      {
        name: "26-40",
        selected: false
      },
      {
        name: "41-65",
        selected: false
      },
      {
        name: "Over 65",
        selected: false
      }
    ],
    locality: getInitialLocality(pc),
    postal: getInitialPostalFilters(pc)
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_FILTER:
      return action.payload;
    case types.LOGIN_SUCCESS:
      let s = JSON.parse(JSON.stringify(state));
      return Object.assign({}, s, {
        auth: {
          auth_email: action.payload.email,
          auth_time: action.payload.last_login,
          auth_token: action.payload.access_token,
          auth_status: true,
          auth_error: false
        }
      });
    case types.LOGIN_FAILURE:
      let s2 = JSON.parse(JSON.stringify(state));
      return Object.assign({}, s2, {
        auth: {
          auth_error: true
        }
      });
    case types.LOGOUT:
      let s3 = JSON.parse(JSON.stringify(state));
      return Object.assign({}, s3, {
        auth: {
          auth_email: null,
          auth_time: null,
          auth_token: null,
          auth_status: false,
          auth_error: false
        }
      });
    case types.CLEAR:
      return Object.assign({}, state, clearState);
    case types.QUERY:
      return Object.assign({}, state, {
        fetched: action.payload
      });
    default:
      return state;
  }
};

export default reducer;