import React from 'react';

import Generic from '../../layouts/Generic';
import { Grid, Cell } from '../../components/layout';
import bdimg1 from '../../assets/images/chart.png';

const BudgetDilemma = () => {
  return (
    <Generic>
      <div className="l-section l-section--pt-0">
        <Grid>
          <Cell size={{ desktop: '9', tablet: '9', phone: '6' }}>
            <h1>Budget Dilemma</h1>
            <p>
              The Council’s net Budget for 2021/22 is £828.7million, which is used to deliver all the services that we currently provide across Birmingham. The Council faces financial uncertainties due to increased demand for services and increasing pressures on local income generation.
            </p>
            <p>
              This shows how we allocate our money, approximately, across the main service areas.
            </p>
            <h2>
              How we allocate money, approximately across service areas:
            </h2>

            <p></p>
            <table>
              <tr>
                <td></td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;£'m</td>
              </tr>
              <tr>
                <td>Adult Social Care</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;337.41</td>
              </tr>
              <tr>
                <td>Children & Young People</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;202.48</td>
              </tr>
              <tr>
                <td>Education & Skills</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;66.20</td>
              </tr>
              <tr>
                <td>Waste Collection & Cleaner Street</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;77.69</td>
              </tr>
              <tr>
                <td>Culture, Leisure, Parks & Libraries</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;54.79</td>
              </tr>
              <tr>
                <td>Housing & Homelessness</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;12.89</td>
              </tr>
              <tr>
                <td>Transport, Highways & Parking</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;100.11</td>
              </tr>
              <tr>
                <td>Planning & Development</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;13.03</td>
              </tr>
              <tr>
                <td>Maintaining Neighbourhoods</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;19.58</td>
              </tr>
              <tr>
                <td>Enforcement & Regulation</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;2.37</td>
              </tr>
              <tr>
                <td>Community Safety & Resilience</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;3.10</td>
              </tr>
              <tr>
                <td>Core Business Function</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;68.88</td>
              </tr>
              <tr>
                <td>Other Corporate Budget</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;-129.84</td>
              </tr>
              <tr>
                <td><b>Total</b></td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;<b>828.67</b></td>
              </tr>
            </table>
            <p></p>
            <p></p>
            <p>
              We also have a number of services and areas of expenditure for which the budgets are controlled by legislation and so cannot be diverted elsewhere. These services are mainly funded by grants, and the expenditure exactly matches the funding available. The net budgets for these areas are therefore shown as nil.
            </p>
            <p></p>
            <table>
              <tr>
                <td></td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;£'m</td>
              </tr>
              <tr>
                <td>Public Health</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;£92m</td>
              </tr>
              <tr>
                <td>Benefits</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;£480m</td>
              </tr>
              <tr>
                <td>Schools</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;£788m</td>
              </tr>
              <tr>
                <td>Council Housing expenditure</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;£282m</td>
              </tr>
            </table>
            <p></p>
            <p></p>

            <p></p>
            <h2>How we  spend our money</h2>
            <p></p>
            <table>
              <tr>
                <td></td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;£'m</td>
              </tr>
              <tr>
                <td>Employees</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;927.79</td>
              </tr>
              <tr>
                <td>Premises</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;190.90</td>
              </tr>
              <tr>
                <td>Transport and moveables</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;39.26</td>
              </tr>
              <tr>
                <td>Supplies and Services</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;543.42</td>
              </tr>
              <tr>
                <td>Contractual payments to service providers</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;864.28</td>
              </tr>
              <tr>
                <td>Grants to Voluntary Organisations and other support payments</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;493.88</td>
              </tr>
              <tr>
                <td>Capital Financing</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;103.90</td>
              </tr>
              <tr>
                <td>Income</td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;-2,334.77</td>
              </tr>
              <tr>
                <td><b>Total Net Budget</b></td>
                <td className="col-right">&nbsp;&nbsp;&nbsp;<b>828.67</b></td>
              </tr>
            </table>
            <p></p>
            <p></p>
            <p></p>
            <h2>Where the money comes from</h2>
            <img className="static-article-img" src={bdimg1} alt="Budget Dilemma" />
          </Cell>
        </Grid>
      </div>
    </Generic>
  )
}

export default BudgetDilemma