import React, { Component } from 'react';
import classnames from 'classnames';

import { Cell, Grid } from '../../../layout';

import { smallNumber, formattedNumber } from '../../../../helpers/number';
import RangeInput from '../../range/RangeInput';

class SpendingAreaCard extends Component {
  state = {
    barColor: (this.props.value - this.props.min) / (this.props.max - this.props.min),
    infoCard: false,
  }

  handleInputChange = (value) => {
    const { max, min, onChange } = this.props;
    this.setState({
      barColor: (value - min) / (max - min)
    });


    onChange(value);
  }

  showInfo = () => {
    this.setState({
      infoCard: true,
    });
  }

  render() {
    const {
      budgetReports,
      className,
      cons,
      defaultValue,
      id,
      isSummary,
      isProtected,
      locked,
      max,
      min,
      name,
      risk,
      slug,
      value,
      negativeValueForDisplay,
      step,
    } = this.props;
    const { scaled, scaling } = this.state;
    const riskLabels = ['Low', 'Medium', 'High'];

    const widthMap = {
      'high': {
        width: '90%',
        color: '#f40000',
      },
      'medium': {
        width: '55%',
        color: '#f47900',
      },
      'low': {
        width: '20%',
        color: '#cad401',
      }
    };

    const currentPercentage = ((value - min) / (max - min)) * 100;
    const spendingWidthValue = currentPercentage < 100 ? currentPercentage : 100;
    const widthTrigger = ((defaultValue - min) / (max - min)) * 100;

    return (
      <div
        id={id}
        className={classnames('c-card c-card--spending', className, {
          'c-card--locked': locked,
        })}
        style={{ transformOrigin: scaling ? 'top-left' : '' }}
      >
        <div className="c-card__front">
          <Grid>
            <Cell size={{ desktop: '11', tablet: '10', phone: '5' }}>
              <p className="c-card__heading">{name}</p>
            </Cell>

            {
              !budgetReports &&
              <span>
                <div className="c-card__info-wrap" style={{ paddingLeft: 0 }}>
                  <span className="c-card__action" onClick={() => this.props.toggleInfo(slug, 'info')}>
                    {scaled ? <i className='fa fa-times' /> : <i className='fa fa-info-circle' />}
                  </span>
                </div>
              </span>
            }
          </Grid>

          {
            cons &&
            <div>
              {
                cons.length > 0 &&
                (!isProtected && !locked && !budgetReports) &&
                <span className="c-card__impact-link">
                  <span className="u-cursor-pointer" onClick={() => this.props.toggleInfo(slug, 'impacts')}>Budget Impacts</span>
                </span>
              }

              {/* {
                ((name === 'Public Health' && locked) && !isSummary) &&
                <span>
                  This budget can only be spent on Public Health. It cannot be
                  reduced as it is a statutory requirement.
                </span>
              } */}
            </div>
          }
          {
            !locked && !isSummary &&
            <div className="c-card__spend-amount">
              <p><strong>Your Spending</strong></p>
              <p>Min £{smallNumber(Number(min))}</p>
            </div>
          }
          {
            !isSummary &&
            <div className={classnames({ 'c-card__input-wrap': !isSummary })}>
              {
                !locked && !negativeValueForDisplay &&
                <p className="c-card__input-value">
                  £{formattedNumber(Number(value))} <br /><small style={{ fontSize: 14 }} >Council Spending</small>
                </p>
              }
              {
                !locked && negativeValueForDisplay &&
                <p className="c-card__input-value">
                  £ -{formattedNumber(Number(negativeValueForDisplay))} <br /><small style={{ fontSize: 14 }} >Council Spending</small>
                </p>
              }
              {
                !locked &&
                (
                  <div style={{ position: 'relative' }}>
                    <div className={`
                    'spending-tooltip'
                    ${classnames({
                      'spending-tooltip--visible': spendingWidthValue >= widthTrigger,
                    })
                      }
                    `
                    }
                      style={{
                        left: `calc(${spendingWidthValue}%)`,
                        display: 'none'
                      }}
                    >
                      <p>Council Spending</p>
                      <small>Value Reached/Exceeded</small>
                    </div>
                    <RangeInput
                      min={min}
                      max={max}
                      value={value}
                      step={step}
                      changeHandler={(value) => this.handleInputChange(value)}
                    />
                  </div>
                )
              }
              {/* {(() => {
                console.log("negativeValueForDisplay" + negativeValueForDisplay);
              })()} */}
              {
                (locked && !negativeValueForDisplay) &&
                (
                  <div>
                    <p className="c-card__protected-label">
                      <i className="fas fa-lock" style={{ marginRight: 8 }}></i>
                      <b>Budget Locked</b>
                    </p>
                    <p className="c-card__input-value">
                      <span>£{smallNumber(value)}</span>
                    </p>
                  </div>
                )
              }

              {
                (locked && negativeValueForDisplay) &&
                (
                  <div>
                    <p className="c-card__protected-label">
                      <i className="fas fa-lock" style={{ marginRight: 8 }}></i>
                      <b>Budget Locked</b>
                    </p>
                    <p className="c-card__input-value">
                      <span>£ -{formattedNumber(Number(negativeValueForDisplay))}</span>
                    </p>
                  </div>
                )
              }

            </div>
          }

          {
            isSummary &&
            <div className="c-card__summary-wrap">
              {
                !isProtected &&
                <Grid>
                  <Cell size={{ desktop: '7', tablet: '7', phone: '6' }}>
                    <Grid>
                      {
                        risk &&
                        <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
                          <p className="c-card__risk-title">Risk Level</p>
                        </Cell>
                      }
                      {
                        risk && riskLabels.map((label, index) => {
                          const classes = classnames('c-card__risk-label', {
                            'c-card__risk-label--active': label === risk,
                            'u-text-center': index === 1,
                            'u-text-right': index === 2,
                          });

                          return (
                            <Cell size={{ desktop: '4', tablet: '4', phone: '2' }} key={index}>
                              <p className={classes}>{label}</p>
                            </Cell>
                          )
                        })
                      }
                      {
                        risk &&
                        <Cell size={{ desktop: '12', tablet: '12', phone: '6' }}>
                          <div className="c-card__risk-bar">
                            <span className="c-card__risk-bar-fill" style={{
                              backgroundColor: widthMap[risk].color,
                              width: widthMap[risk].width,
                            }} />
                          </div>
                        </Cell>
                      }
                    </Grid>
                  </Cell>
                  <Cell size={{ desktop: '5', tablet: '5', phone: '6' }}>
                    <p className="c-card__input-value">
                      <small>Total Spend</small>
                      <span className="c-card__total-spend-amount">£{smallNumber(value)}</span>
                    </p>
                  </Cell>
                </Grid>
              }

              {
                (isProtected && !budgetReports) &&
                <Grid>
                  <Cell size={{ desktop: '8', tablet: '8', phone: '6' }} alignment="bottom">
                    <p className="c-card__protected-label">
                      <span className="c-card__protected-icon" />
                      <b>Budget Protected</b>
                      <i className="fas fa-check u-text-green"></i>
                    </p>
                  </Cell>
                  <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
                    <p className="c-card__input-value">
                      <span className="c-card__total-spend-amount">£{smallNumber(value)}</span>
                    </p>
                  </Cell>
                </Grid>
              }
            </div>
          }


          <div className="card-front-overlay" />
        </div>
      </div>
    )
  }
}

export default SpendingAreaCard;
