const LOAD_INITIAL_DATA = 'LOAD_INITIAL_DATA'
const CHANGE_SPENDING_AREA = 'CHANGE_SPENDING_AREA'
const CHANGE_COUNCIL_CATEGORY = 'CHANGE_COUNCIL_CATEGORY'
const CHANGE_COUNCIL_BUDGET = 'CHANGE_COUNCIL_BUDGET'
const SAVE = 'SAVE'
const START_AGAIN = 'START_AGAIN'
const LOAD_PEOPLE = 'LOAD_PEOPLE'
const LOAD_INITIAL_DATA_SUCCESS = 'LOAD_INITIAL_DATA_SUCCESS'
const CHANGE_SPENDING_AREA_SUCCESS = 'CHANGE_SPENDING_AREA_SUCCESS'
const SAVE_SUCCESS = 'SAVE_SUCCESS'
const CHANGE_COUNCIL_CATEGORY_SUCCESS = 'CHANGE_COUNCIL_CATEGORY_SUCCESS'
const CHANGE_COUNCIL_BUDGET_SUCCESS = 'CHANGE_COUNCIL_BUDGET_SUCCESS'
const START_AGAIN_SUCCESS = 'START_AGAIN_SUCCESS'
const LOAD_PEOPLE_SUCCESS = 'LOAD_PEOPLE_SUCCESS'
const LOAD_INITIAL_DATA_FAILURE = 'LOAD_INITIAL_DATA_FAILURE'
const CHANGE_SPENDING_AREA_FAILURE = 'CHANGE_SPENDING_AREA_FAILURE'
const SAVE_FAILURE = 'SAVE_FAILURE'
const CHANGE_COUNCIL_CATEGORY_FAILURE = 'CHANGE_COUNCIL_CATEGORY_FAILURE'
const CHANGE_COUNCIL_BUDGET_FAILURE = 'CHANGE_COUNCIL_BUDGET_FAILURE'
const START_AGAIN_FAILURE = 'START_AGAIN_FAILURE'
const LOAD_PEOPLE_FAILURE = 'LOAD_PEOPLE_FAILURE'
const CLEAR_INDIVIDUAL = 'CLEAR_INDIVIDUAL'
const LOAD_INDIVIDUAL = 'LOAD_INDIVIDUAL'
const LOAD_INDIVIDUAL_SUCCESS = 'LOAD_INDIVIDUAL_SUCCESS'
const LOAD_INDIVIDUAL_FAILURE = 'LOAD_INDIVIDUAL_FAILURE'

const TOGGLE_REFERENDUM_MODAL = 'TOGGLE_REFERENDUM_MODAL'
const SHOW_BUDGET_SUCCESS_MODAL = 'SHOW_BUDGET_SUCCESS_MODAL'
const HIDE_BUDGET_SUCCESS_MODAL = 'HIDE_BUDGET_SUCCESS_MODAL'
const SHOW_BUDGET_FAILURE_MODAL = 'SHOW_BUDGET_FAILURE_MODAL'
const HIDE_BUDGET_FAILURE_MODAL = 'HIDE_BUDGET_FAILURE_MODAL'

const SET_THANK_YOU_MODAL = 'SET_THANK_YOU_MODAL';

const TOGGLE_RANGE_TOOLTIP = 'TOGGLE_RANGE_TOOLTIP';
const LOG_USER_STARTING_ACTION = 'LOG_USER_STARTING_ACTION';
const SUBMIT_GIVE_UP_REASON = 'SUBMIT_GIVE_UP_REASON';
const SUBMIT_GIVE_UP_REASON_SUCCESS = 'SUBMIT_GIVE_UP_REASON_SUCCESS';
const SET_GIVEUP_STATUS = 'SET_GIVEUP_STATUS';
const PREVENT_LOAD_MORE_BUDGETS = 'PREVENT_LOAD_MORE_BUDGETS';

export {
  LOAD_INITIAL_DATA,
  CHANGE_SPENDING_AREA,
  SAVE,
  CHANGE_COUNCIL_CATEGORY,
  CHANGE_COUNCIL_BUDGET,
  START_AGAIN,
  LOAD_PEOPLE,
  LOAD_INITIAL_DATA_SUCCESS,
  CHANGE_SPENDING_AREA_SUCCESS,
  CHANGE_COUNCIL_CATEGORY_SUCCESS,
  CHANGE_COUNCIL_BUDGET_SUCCESS,
  SAVE_SUCCESS,
  START_AGAIN_SUCCESS,
  LOAD_PEOPLE_SUCCESS,
  LOAD_INITIAL_DATA_FAILURE,
  CHANGE_SPENDING_AREA_FAILURE,
  CHANGE_COUNCIL_CATEGORY_FAILURE,
  CHANGE_COUNCIL_BUDGET_FAILURE,
  SAVE_FAILURE,
  START_AGAIN_FAILURE,
  LOAD_PEOPLE_FAILURE,
  LOAD_INDIVIDUAL,
  LOAD_INDIVIDUAL_SUCCESS,
  CLEAR_INDIVIDUAL,
  LOAD_INDIVIDUAL_FAILURE,
  SHOW_BUDGET_SUCCESS_MODAL,
  HIDE_BUDGET_SUCCESS_MODAL,
  SHOW_BUDGET_FAILURE_MODAL,
  HIDE_BUDGET_FAILURE_MODAL,
  TOGGLE_REFERENDUM_MODAL,
  TOGGLE_RANGE_TOOLTIP,
  LOG_USER_STARTING_ACTION,
  SUBMIT_GIVE_UP_REASON,
  SUBMIT_GIVE_UP_REASON_SUCCESS,
  SET_GIVEUP_STATUS,
  PREVENT_LOAD_MORE_BUDGETS,
  SET_THANK_YOU_MODAL
}
