import React, { Component } from 'react'
import { Timeline } from 'react-twitter-widgets'

class TwitterFeed extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="twitter-feed c-social-wrapper">
        <Timeline
          {...this.props}
          dataSource={{
            sourceType: 'profile',
            screenName: 'BhamCityCouncil'
          }}
          options={{
            height: '500',
            chrome: 'transparent'
          }}
        />
      </div>
    )
  }
}

export default TwitterFeed;
