import reducer from './reducers'
import * as duckActions from './actions'
import * as duckType from './types'

export {
  duckActions,
  duckType
}

export default reducer
