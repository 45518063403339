import React from 'react';

import Button from '../button/Button';
import Modal from './Modal';
import budget_logo from '../../../assets/images/birmingham_mobile_logo.jpg';

const BudgetFailureModal = ({ handleClose }) => (
  <Modal handleClose={() => handleClose()}>
    <img src={budget_logo} alt="Budget Challenge Icon" width="75" className="u-margin-bottom@1" />

    <h2 className="c-modal__title">Budget Not Balanced</h2>
    <p className="c-modal__sub-title">Your budget is not yet balanced please try again.</p>

    <Button onClick={() => handleClose()}>Try Again</Button>
  </Modal>
);

export default BudgetFailureModal;
