import React from 'react';
import classnames from 'classnames';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * Generic Layout
 * This layout is used for generic content templates.
 */

import Header from '../components/common/header/Header';
import Footer from '../components/common/footer/Footer';
import TopBar from '../components/common/top-bar/TopBar';

import Sidebar from '../components/sidebar/Sidebar';
import { Cell, Container, Grid } from '../components/layout';

import {
  pageActions,
} from '../reducks';

import logo from '../assets/images/logo.png';

export const Secondary = ({
  children,
  toggleMenuAction,
  page,
}) => {
  window.scroll(0, 0);

  return (
    <div className={classnames('l-layout l-layout--primary', { 'l-layout--menu-active': page.menuActive })}>
      <TopBar className="c-top-bar--empty" />

      <Header>
        <Grid>
          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <Link to="/" className="c-header__logo-wrap">
              <img className="c-header__logo" src={logo} alt="Birmingham City Council Logo" />
            </Link>
          </Cell>

          <Cell size={{ desktop: '4', tablet: '4', phone: '6' }}>
            <h2 className="c-header__title">
              <Link to="/">
                Budget <span className="light">Challenge</span>
              </Link>
            </h2>
          </Cell>
        </Grid>

        <div className="c-header__menu-trigger" onClick={() => toggleMenuAction()}>
          <div className={classnames('c-header__hamburger', { 'is-active': page.menuActive })}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </Header>

      <Container isOverflow={true}>
        {children}
      </Container>

      <Sidebar />

      <Footer />
    </div>
  );
}

export default connect((state, ownProps) => {
  return {
    page: state.page
  }
}, (dispatch, ownProps) => {
  return {
    toggleMenuAction: () => dispatch(pageActions.toggleMenuAction())
  }
})(Secondary)
